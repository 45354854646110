import { useMemo } from "react";

import { AuthApi } from "@/apis/connect/auth-api";
import { EventApi } from "@/apis/connect/event-api";
import { QuestionnaireAnswerApi } from "@/apis/connect/questionnaire-answer-api";
import { QuestionnaireApi } from "@/apis/connect/questionnaire-api";
import { ReservationApi } from "@/apis/connect/reservation-api";
import { ReservationFrameApi } from "@/apis/connect/reservation-frame-api";
import { SpotApi } from "@/apis/connect/spot-api";
import { TicketApi } from "@/apis/connect/ticket-api";
import { UserApi } from "@/apis/connect/user-api";

import { authTokenInfoAtom } from "@/global-states/common-atoms";
import { useAtom } from "jotai";

export const useApiConnector = () => {
  const [authTokenInfo] = useAtom(authTokenInfoAtom);

  const appAuthApi = useMemo(() => new AuthApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appUserApi = useMemo(() => new UserApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appTicketApi = useMemo(() => new TicketApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appReservationApi = useMemo(() => new ReservationApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appEventApi = useMemo(() => new EventApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appSpotApi = useMemo(() => new SpotApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appQuestionnaireApi = useMemo(() => new QuestionnaireApi(authTokenInfo?.accessToken), [authTokenInfo]);
  const appQuestionnaireAnswerApi = useMemo(
    () => new QuestionnaireAnswerApi(authTokenInfo?.accessToken),
    [authTokenInfo],
  );
  const appReservationFrameApi = useMemo(() => new ReservationFrameApi(authTokenInfo?.accessToken), [authTokenInfo]);

  return {
    appAuthApi,
    appUserApi,
    appTicketApi,
    appReservationApi,
    appEventApi,
    appSpotApi,
    appQuestionnaireApi,
    appQuestionnaireAnswerApi,
    appReservationFrameApi,
  };
};
