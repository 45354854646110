export default {
  // お問い合わせ
  PAGE_TITLE: "お問い合わせ",
  CONFIRM_PAGE_TITLE: "お問い合わせ内容の確認",
  COMPLETE_PAGE_TITLE: "お問い合わせありがとうございました",
  CONTACT: "お問い合わせ",
  THANKS_MESSAGE_FOR_CONTACT:
    "ご意見やお困りの点などをお伝えいただき、誠にありがとうございます。個別に返答することはできかねますが、サービスの改善に取り組んでいるチームにお伝えいたします。",
  YAMADA_TAROU: "山田太郎",
  CONTENT_OF_CONTACT: "お問い合わせ内容",
  THIS_IS_CONTENT_OF_CONTACT: "お問い合わせ内容です。",
  CONTACT_CONFIRM: "お問い合わせ内容の確認",
  THANKS_FOR_CONTACT: "お問い合わせありがとうございました",
  RESPONSE_THREE_BUSINESS_DAYS:
    "お問い合わせありがとうございました。３営業日以内を目処にご返信させていただきますので、今しばらくお待ち下さい。",
  CONTACT_CANCEL_INFO1: "キャンセルのお問い合わせは申込番号、予約日時が必要となりますので、",
  CONTACT_CANCEL_INFO2: "の各チケット詳細画面にてご確認ください。",
};
