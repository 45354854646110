export default {
  // パスワード再設定
  PAGE_TITLE: "更改密碼",
  COMPLETE_PAGE_TITLE: "密碼更改完成",
  CHANGE_PASSWORD_TITLE: "更改密碼",
  CHANGE_PASSWORD_ACTION: "更改您的密碼",
  CHANGE_PASSWORD_SUBMIT_ERROR: "在更改密碼過程中出現未知錯誤。請稍後再試。",
  CHANGE_PASSWORD_COMPLETE_TITLE: "密碼更改完成",
  CHANGE_PASSWORD_COMPLETE_DESCRIPTION: "您的密碼已經更改。\n請繼續享受GoATAMI。",
};
