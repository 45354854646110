import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class TicketApi extends BaseApi {
    async getTicketById(
        parameters: APIPaths["/app/ticket/{id}"]["get"]["parameters"]["path"],
    ): Promise<APIPaths["/app/ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
        return (await this.get(`/app/ticket/${parameters.id}`)).data;
    }
    async getTicketList(
        parameters: APIPaths["/app/ticket/list"]["get"]["parameters"]["query"],
    ): Promise<APIPaths["/app/ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
        return (
            await this.get("/app/ticket/list", {
                params: parameters,
            })
        ).data;
    }
}
