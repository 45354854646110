export default {
  // パスワードリセット: 認証メール送信
  SEND_VERIFICATION_MAIL_PAGE_TITLE: "Password Reset",
  SENT_VERIFICATION_MAIL_PAGE_TITLE: "Sent Verification Email",
  RESET_PASSWORD_TITLE: "Password Reset",
  RESET_PASSWORD_DESCRIPTION: "Please enter your email address to complete the email verification.",
  SEND_VERIFICATION_MAIL: "Send Verification Email",
  RESET_PASSWORD_SUBMIT_ERROR:
    "An unknown error occurred during the password reset process. Please try again after some time.",
  RESET_PASSWORD_SENT_MAIL_DESCRIPTION:
    "We have sent a verification email.\nPlease click the verification link in the email to complete the password reset.",

  // パスワードリセット: 新規パスワード入力
  EDIT_PASSWORD_PAGE_TITLE: "Set Password",
  COMPLETE_RESET_PASSWORD_PAGE_TITLE: "Password Reset Completed",
  NEW_PASSWORD_DESCRIPTION: "Please enter a new password to complete the password reset.",
  RESET_PASSWORD_ACTION: "Reset Password",
  COMPLETE_RESET_PASSWORD_TITLE: "Password Reset Completed",
  COMPLETE_RESET_PASSWORD_DESCRIPTION: "Your password has been reset. \nPlease log in again.",
  RESET_PASSWORD_ERROR_FIREBASE_USER_NOT_FOUND:
    "No user was found. Please try again starting from entering your email address.",
  RESET_PASSWORD_ERROR_DESCRIPTION:
    "Please verify the email address you entered and try entering your email address again.",
  RESET_PASSWORD_ERROR_AUTH_LINK_EXPIRED:
    "The validity period has expired. Please try again starting from entering your email address.",
};
