export default {
  // パスワード再設定
  PAGE_TITLE: "パスワード再設定",
  COMPLETE_PAGE_TITLE: "パスワード再設定完了",
  CHANGE_PASSWORD_TITLE: "パスワード再設定",
  CHANGE_PASSWORD_ACTION: "パスワードを再設定する",
  CHANGE_PASSWORD_SUBMIT_ERROR:
    "パスワード再設定処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  CHANGE_PASSWORD_COMPLETE_TITLE: "パスワード再設定完了",
  CHANGE_PASSWORD_COMPLETE_DESCRIPTION: "パスワード再設定が完了しました。\n引き続きGoATAMIをお楽しみください。",
};
