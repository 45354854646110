export default {
  PAGE_TITLE: "熱海門票網站",
  SUB_TITLE1: "欣賞日本最早盛開的櫻花",
  SUB_TITLE2: "與昭和復古街景",
  SUB_TITLE3: undefined,
  SPACIAL_FEATURE_TITLE1: "從東京前往交通便利！",
  SPACIAL_FEATURE_TITLE2: "在受歡迎的溫泉地熱海盡享昭和復古世界。",
  SPACIAL_FEATURE_TITLE3: "讓我們一起欣賞日本最早盛開的櫻花吧！",
  SPACIAL_FEATURE_TEXT:
    "作為日本數一數二的溫泉地，著名的熱海不僅以昭和復古的街景聞名，還因為是日本最早在冬季盛開櫻花的地方而聞名。將舉辦‘熱海櫻花夜會’活動，遊覽冬季盛開的‘熱海櫻花’、‘昭和復古街景’、‘起雲閣’以及‘熱海藝妓見番’等旅遊景點。\n\n特別是糸川沿岸排列的櫻花景致非常壯觀。在活動期間，不僅會照亮糸川沿岸的櫻花，還會透過提燈裝置在冬季夜空中幻想般地呈現‘日本最早盛開的熱海櫻花’。請務必來訪熱海，欣賞‘只有在熱海冬季才能看到的櫻花景色’和‘林立的攤販’，體驗日本最早的賞花時節。\n配合燈光秀，著名的文豪曾逗留的‘起雲閣’和可以體驗古老日本文化的‘熱海藝妓見番’也將舉辦特別活動！",
  EVENT_LIST: "活動一覽",
  TITLE_EVENT_LIST1: "熱海藝妓見番",
  TITLE_EVENT_LIST2: "糸川遊步道",
  TITLE_EVENT_LIST3: "起雲閣",
  SUBTITLE_EVENT_LIST1:
    "在見番，您可以從特別的二樓桟敷席欣賞藝妓的表演。我們還準備了由職人製作的壽司供應計劃。請務必享受在熱海市內度過的最佳時光。",
  SUBTITLE_EVENT_LIST2:
    "在糸川的夜櫻觀賞中，不僅有普通櫻花的照明，還有提燈裝置的設置、以及從見番一直延伸的路燈，您可以享受這個活動特有的幻想般的風景。",
  SUBTITLE_EVENT_LIST3:
    "在起雲閣，我們提供平時不提供的‘浴衣’或‘昭和復古服裝’的租賃服務，在融合了和風與現代風格的環境中，您可以拍攝彷彿時光倒流的照片。",
  EVENT_ON_CLICK: "活動詳情請參閱此處",
};
