export default {
  PAGE_TITLE: `依據特定商業交易法的顯示`,
  MAILORDERLAW: `依據特定商業交易法的顯示`,
  MAILORDERLAW_SALES_COMPANY: `銷售業者`,
  MAILORDERLAW_COMPANY: `一般社團法人熱海市觀光協會`,
  MAILORDERLAW_SALES_COMPANY_REPRESENTATIVE: `銷售業者代表/銷售業者負責人`,
  MAILORDERLAW_NAME: `中島幹雄`,
  MAILORDERLAW_LOCATION: `地址`,
  MAILORDERLAW_LOCATION_NAME: `靜岡縣熱海市渚町2018-8 水濱公園內`,
  MAILORDERLAW_CONTACT_INFORMATION: `聯繫我們`,
  MAILORDERLAW_INFORMATION: `0557-85-2222`,
  MAILORDERLAW_SELING_PRICE: `銷售價格`,
  MAILORDERLAW_PRICE: `請參閱商品頁上的價格欄。不同商品的銷售價格可能不同。`,
  MAILORDERLAW_SHIPING: `運費 `,
  MAILORDERLAW_SHIPING_NONE: `免運費。`,
  MAILORDERLAW_SALES_PRICE: `除銷售價格和運費外所需的其他費用`,
  MAILORDERLAW_CONNECTION: `包括互聯網連接相關的通信線路等費用。`,
  MAILORDERLAW_PAYMENT: `付款方式及付款時間`,
  MAILORDERLAW_PRICE_PAYMENT: `僅接受信用卡支付。\n購買時將進行結算。帳單日期可能因信用卡公司而異。`,
  MAILORDERLAW_PRODUCT: `商品交付時間`,
  MAILORDERLAW_PAYMENT_COMPLETE: `除非購買頁面有特殊規定，否則在信用卡支付完成時交付。`,
  MAILORDERLAW_SALES_QUANTITIES: `銷售期間及銷售數量限制`,
  MAILORDERLAW_MAILORDERLAW_SALES_QUANTITIES: `請參考商品頁面的銷售期間、庫存數量及門票數量限制。`,
  MAILORDERLAW_SALES_REGARDING: `退貨相關特約 `,
  MAILORDERLAW_REGARDING: `我們將遵守各個活動的取消政策。\n但如果因主辦方原因或其他情況導致活動取消，將全額退款。`,
};
