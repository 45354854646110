export default {
  // パスワード再設定
  PAGE_TITLE: "Change Password",
  COMPLETE_PAGE_TITLE: "Password Change Completed",
  CHANGE_PASSWORD_TITLE: "Change Password",
  CHANGE_PASSWORD_ACTION: "Change Password",
  CHANGE_PASSWORD_SUBMIT_ERROR:
    "An unknown error occurred during the password change process. Please wait for some time and try again.",
  CHANGE_PASSWORD_COMPLETE_TITLE: "Password Change Completed",
  CHANGE_PASSWORD_COMPLETE_DESCRIPTION:
    "Your password has been successfully changed. \nPlease continue to enjoy GoATAMI.",
};
