export const routePath = "/";

// Signup
export const signUpPath = "/auth/signup";
export const signUpEmailAuthPath = (id: string) => `${signUpPath}/${id}`;
export const signUpCompletePath = "/auth/signup/complete";

// Service Policy
export const servicePolicyPath = "/service-policy";

// Contact
export const contactPath = "/contact";
export const contactConfirmPath = `/contact/confirm`;
export const contactCompletePath = `/contact/complete`;

// Company info
export const companyInfo = "/company-info";

// Privacy Policy
export const privacyPolicy = "/privacy-policy";

// Mailorderlaw
export const mailorderLawPath = "/mailorderlaw";

// Change Password
export const changePasswordPath = `/change-password`;
export const changePasswordCompletePath = `/change-password/complete`;

// Change Mail Address
export const changeMailAddressPath = "/change-mail-address";
export const changeMailAddressVerificationPath = `/change-mail-address/verification`;
export const changeMailAddressCompletePath = (id: string, hashKey: string) =>
  `${changeMailAddressPath}/complete/${id}?key=${hashKey}`;
// Reset Password
export const resetPasswordPath = "/reset-password";
export const editPasswordPath = (id: string) => `edit-password/${id}`;
export const resetPasswordCompletePath = "/reset-password/complete";
export const editPasswordCompletePath = "/edit-password/complete";

// Auth login
export const authPath = "/auth";

// Purchase List
export const purchaseListPath = "/purchase-list";
export const purchaseListDetailPath = (id: string) => `/purchase-list/detail/${id}`;
export const qrcordPath = (id: string) => `${process.env.OPERATOR_DASHBORD_URL}/checkin/${id}`;

// Ticket Purchace
export const ticketPurchaceSelectPath = (id: string) => `/purchase-ticket/select/${id}`;
export const ticketDetailPath = (eventId: string, ticketId: string) => `/ticket-detail/${eventId}/${ticketId}`;
export const ticketPurchaceQuestionnairePath = "/purchase-ticket/questionnaire";
export const purchaseTicketDetailPath = (eventId: string, ticketId: string) =>
  `/purchase-ticket/detail/${eventId}/${ticketId}`;
export const ticketPurchaceConfirmationPath = (eventId: string, ticketId: string) =>
  `/purchase-ticket/confirmation/${eventId}/${ticketId}`;
export const purchaseTicketPaymentPath = (eventId: string, ticketId: string) =>
  `/purchase-ticket/payment/${eventId}/${ticketId}`;
export const reservationProcessingPath = (rid: string) => `/purchase-ticket/processing/${rid}`;
export const ticketPurchaceThankYouMessagePath = (rid: string) => `/purchase-ticket/thank-you-message/${rid}`;
export const thankYouQuestionnaire = (ids: string) => `/purchase-ticket/thank-you-questionnaire?ids=${ids}`;

export const unexpectedPagePath = `/500`;

// Questionnaire (after)
export const thankYouAfterQuestionnaire = (ids: string) => `/questionnaire/after/thank-you-questionnaire?ids=${ids}`;

// Event（特集ページ用）
export const eventPath = (id: string) => `/event/${id}`;
