export default {
  PAGE_TITLE: `Go ATAMI使用條款`,
  TERMS_OF_SERVICE: `Go ATAMI使用條款`,
  TERMS_OF_SERVICE_CONTENT: `「Go ATAMI（Web服務名稱）」在提供服務（以下「本服務」）時，依據以下的利用條款（以下「本條款」）進行。本條款是適用於使用者及註冊用戶在使用本服務時的規則。為了能夠舒適地使用本服務，請仔細閱讀本條款的內容。
  一旦您使用了本服務，即表示您已經理解並同意本條款的所有條款。
  另外，我們會根據需要更改本條款的內容，所以在使用本服務時，請確認最新的利用條款。
  
  （目的）
  第1條
  本服務旨在「搜尋和預訂熱海的活動、觀光選項之旅和體驗計劃」。一般社團法人熱海市觀光協會（以下「本觀光協會」）將持續努力提供更好的服務。
  
  （定義）
  第2條
  在本條款中使用的術語定義如下：
  1. 本服務是指本觀光協會經營的投稿網站及相關服務。
  2. 本網站是指發布本服務內容的網站。
  3. 內容是指本觀光協會在本網站上提供的，或包括投稿信息的信息、圖片等的總稱。
  4. 使用者是指瀏覽本網站的所有人。
  5. 註冊用戶是指完成本網站用戶註冊的人。
  6. 投稿信息是指註冊用戶通過投稿等發布的文章、文字、圖片、評論等信息的總稱。
  7. 註冊信息是指註冊用戶在本網站上註冊的除投稿信息外的信息的總稱。
  
  （本條款的範圍和更改）
  第3條
  1. 本條款旨在確定提供本服務的條件以及本服務的使用與本觀光協會和使用者之間的權利和義務，並適用於使用者和本觀光協會之間與本服務的使用有關的所有關係。
  2. 本觀光協會可以在不獲得使用者同意的情況下，以本觀光協會認為適當的方式通知使用者，更改本條款。
      (1). 在更改本使用條款時，本觀光協會將在更改後的使用條款生效日期之前，提前適當的時間，在本觀光協會的網站上公告更改的內容及更改後的使用條款的內容和生效日期，或通過電子郵件通知客戶。
      (2). 如果本觀光協會已通知客戶更改後的使用條款的內容，並且客戶在更改後的使用條款生效日期之後使用了本服務，則客戶將被視為同意更改的使用條款。
  3. 本觀光協會在本服務網站上發布的與本服務使用有關的規則，不論其名稱如何，都構成本條款的一部分。
  4. 如果本條款的內容與前項的規則或其他非本條款的本服務的說明等有矛盾或衝突，除非有特別規定優先適用該說明等的規定，否則應優先適用本條款的規定。
  
  （合同期間）
  第4條
  本服務在註冊用戶完成退會手續時終止合同。註冊用戶可以隨時通過指定的註冊取消手續退出本服務。但是，如果使用者或註冊用戶進行了終止合同的行為，本觀光協會可以立即終止合同。
  
  （服務的使用）
  第5條
  使用者和註冊用戶在使用本服務時，表示有效同意使用條款，並同意與本觀光協會之間建立以本服務使用條款為內容的使用合同。
  
  （使用費用）
  第6條
  1. 使用者和註冊用戶在使用本服務時，不需要支付使用費用。
  2. 由於經濟環境的變動等原因，本觀光協會可以隨時更改費用規定。
  
  （外部委託）
  第7條
  本觀光協會可以根據需要，對業務的全部或部分進行外部委託，例如信用卡支付代理等。
  
  （註冊）
  第8條
  1. 希望使用本服務中本觀光協會指定的功能（以下稱「指定功能」）的人（以下稱「註冊希望者」）應同意遵守本條款，並向本觀光協會提供指定的信息（以下稱「註冊事項」）按照本觀光協會的方法，並按照本觀光協會的程序，向本觀光協會申請使用指定功能。註冊必須由註冊希望者本人進行，且註冊希望者必須向本觀光協會提供真實且正確的信息。
  2. 如果申請加入，本觀光協會將根據其標準判斷註冊希望者的註冊是否可行，如果本觀光協會批准註冊，將通知註冊希望者，此通知表示註冊希望者已完成註冊用戶的註冊。
  3. 註冊用戶同意，對於註冊的電話號碼等，本觀光協會可以發送有關本服務或其他本觀光協會服務的信息，或其他市場營銷目的的SMS或其他電子郵件等。
  4. 如果註冊希望者符合以下各項的任何一項，本觀光協會可以決定不接受其加入申請。在這種情況下，本觀光協會不承擔任何解釋義務或賠償義務。
      (1). 註冊希望者的申請有瑕疵（包括但不限於虛假、錯誤、遺漏）。
      (2). 註冊希望者在過去由於違反本條款、與本觀光協會的合同等原因，已經採取了取消使用者註冊、暫停註冊用戶資格等措施。
      (3). 本觀光協會認為註冊希望者使用不正當手段進行註冊。
      (4). 註冊希望者與暴力團伙等反社會勢力有關，或自行進行違反公序良俗的業務。
      (5). 是未成年人、成年被監護人、被保護人或被輔助人，且未獲得法定代理人、監護人、保護人或輔助人的同意。
      (6). 已經成為本服務的註冊用戶。
      (7). 試圖以不適當的目的使用本服務，該目的可能符合第14條的各項。
      (8). 本觀光協會認為不適當的其他情況。
  
  （登録情報的變更）
  第9條
  1. 若登録情報有所變更，應迅速在本服務上進行指定的變更手續。因未進行登録內容的變更而導致的損害，應由該註冊用戶承擔，本觀光協會不承擔任何責任。
  2. 本觀光協會對註冊用戶的通知，將視為在通知時向本服務上註冊的註冊用戶的電子郵件地址發送電子郵件為已通知。
  3. 若因註冊用戶未能收到本觀光協會的通知而導致的損害，本觀光協會不承擔任何責任。
  4. 本觀光協會可以隨時確認註冊用戶的身份以及登録情報的真實性。註冊用戶應根據本觀光協會的要求，以書面等方式提供必要的確認信息。
  
  （退會）
  第10條
  1. 若註冊用戶希望退會，請按照本觀光協會指定的方式進行退會手續。
  2. 若註冊用戶符合以下任何一項，本觀光協會可決定強制其退會：
      (1) 申請中存在明顯的不備（包括但不限於虛偽、錯誤、遺漏）。
      (2) 違反本條款或本觀光協會制定的其他使用條款。
      (3) 本觀光協會認為不適當的其他情況。
  3. 退會時，若註冊用戶對本觀光協會有未完成的債務或義務，註冊用戶應繼續履行這些義務。
  
  （通過本服務締結或管理合同等）
  第11條
  1. 註冊用戶可以在本服務上，按照本觀光協會的程序，申請締結銷售合同等。
  2. 註冊用戶在進行前項的申請時，同意將其姓名、聯繫方式及其他相關信息發送給本觀光協會的交易對象。
  3. 若因註冊用戶未進行指定操作或其他原因，導致銷售合同等未能確定或與客觀事實不符，即使因此導致註冊用戶受到損害或問題，本觀光協會亦不承擔責任。
  4. 註冊用戶希望並同意，關於銷售合同的事項、投訴處理等的明確說明，可以通過電子郵件等電子方式進行。
  
  （遵守事項）
  第12條
  1. 註冊用戶不得將ID轉讓、質押或出借給第三方。
  2. 註冊用戶應對其在使用本服務時的所有行為承擔全部責任。
  3. 註冊用戶發布的信息的來源等的責任由註冊用戶自己承擔。本觀光協會無義務保存發布的信息。註冊用戶應自行備份所需的發布信息。
  4. 註冊用戶不得虛偽登録信息，也不得未經許可擅自登録他人的信息。
  
  （禁止行為）
  第13條
  1. 在使用本服務時，本觀光協會禁止註冊用戶進行以下行為。若違反，本觀光協會可以採取強制退會、使用停止、刪除發布信息等必要措施：
      (1) 停止支付或無法支付，或有破產手續開始、民事再生手續開始或類似手續的申請。
      (2) 毀損本觀光協會或第三者的名譽、信用，或不當地差別或誹謗。
      (3) 侵犯本觀光協會或第三者的知識產權。
      (4) 侵犯本觀光協會或第三者的隱私權。
      (5) 未經許可公開本觀光協會或第三者的個人信息。
      (6) 侵害或可能侵害本觀光協會或第三者的財產。
      (7) 對本觀光協會或第三者造成經濟損害。
      (8) 對本觀光協會或第三者進行威脅。
      (9) 代替被停止使用ID的人取得ID。
      (10) 干擾本服務的運營和系統。
      (11) 發布淫穢、兒童色情、缺乏品性的內容或本觀光協會認為不適當的內容。
      (12) 發布虛偽信息。
      (13) 發布與本網站目的無關的圖片、文章等。
      (14) 進行相同或類似的發布。
      (15) 違反法律的發布。
      (16) 進行可能導致種族或民族差別的發布。
      (17) 進行使使用者感到不悅的發布。
      (18) 對本服務的網絡或系統施加過大的負擔。
      (19) 可能干擾本服務運營的行為。
      (20) 不正確地訪問或嘗試訪問本觀光協會的網絡或系統。
      (21) 冒充第三者。
      (22) 使用其他用戶的登錄信息。
      (23) 未經本觀光協會許可在本服務上進行廣告、宣傳、勸誘或商業行為。
      (24) 收集本服務其他用戶的信息。
      (25) 不正確地設定、使用、操作本服務提供的功能。
      (26) 洩露基於雇佣合同獲得的秘密信息。
      (27) 對本觀光協會、本服務的其他用戶或其他第三者造成不利、損害或不快。
      (28) 為反社會勢力等提供利益。
      (29) 為了與不熟悉的異性見面的目的。
      (30) 直接或間接引起前述行為。
      (31) 除上述之外，本觀光協會認為不適當的行為。
  2. 若本觀光協會認為註冊用戶的行為違反或可能違反上述任何一項，本觀光協會可以在不事先通知註冊用戶的情況下採取以下措施，且不對因此對註冊用戶造成的損害承擔任何責任：
      (1) 要求停止違反行為。
      (2) 刪除違反行為的內容或停止違反行為。
      (3) 停止使用本服務。
      (4) 採取本觀光協會認為必要的其他措施。
      (5) 公開宣布採取上述措施的事實。
  
  （登錄抹消等）
  第14條
  1. 若註冊用戶符合以下任何一項，本觀光協會可以在不事先通知或催告的情況下，刪除投稿數據，或暫時停止該註冊用戶使用本服務，或取消其作為註冊用戶的登錄：
      (1) 違反本條款的任何條款。
      (2) 取消次數超過本觀光協會規定的次數。
      (3) 發現登錄事項中有虛偽事實。
      (4) 停止支付或無法支付，或有破產手續開始、民事再生手續開始或類似手續的申請。
      (5) 超過6個月未使用本服務。
      (6) 對本觀光協會的回覆要求沒有任何回應。
      (7) 符合第8條第4項的任何一項。
      (8) 本觀光協會認為不適當繼續使用本服務或作為註冊用戶。
  2. 若符合前項的任何一項，註冊用戶對本觀光協會的債務將立即到期，並必須立即支付所有債務。
  3. 本觀光協會不對因本條所採取的行動對註冊用戶造成的損害承擔任何責任。
  
  （使用環境整備）
  第15條
  1. 註冊用戶應自行承擔費用和責任，為使用本平台和本服務準備必要的通信設備、軟件和其他必要設備。註冊用戶應自行承擔費用和責任，選擇通過電信服務或電信線路連接到互聯網。註冊用戶應根據自己的使用環境，確保計算機病毒的防護、防止不正當訪問和信息洩露等安全措施。
  2. 本觀光協會不參與也不對註冊用戶的使用環境承擔任何責任。
  
  （本服務的停止等）
  第16條
  1. 當觀光協會在以下情況之一時，有權在不事先通知用戶的情況下，停止或中斷本服務的全部或部分提供：
      (1) 緊急進行本服務相關的電腦系統的檢查或維護。
      (2) 由於事故導致電腦、通訊線路等停止。
      (3) 由於地震、雷擊、火災、風水災害、停電、天災等不可抗力原因導致無法運營本服務。
      (4) 其他當觀光協會認為需要停止或中斷的情況。
  2. 當觀光協會基於前項所採取的措施導致用戶受到損害時，不承擔任何責任。
  
  （知識產權）
  第17條
  1. 本服務中的內容、各項信息的權利屬於當觀光協會及提供內容等的合作夥伴企業。
  2. 本服務或廣告中的內容受到著作權法、商標法、設計法等各種法律的保護。
  3. 除非得到當觀光協會、合作夥伴企業、廣告主企業等的事先同意，註冊用戶不得複製、公開、轉讓、出借、翻譯、轉售、轉移、授權、再利用等本服務或軟體及其內容。若用戶因此受到損害，當觀光協會不提供任何保證，且可能要求損害賠償。
  4. 註冊用戶提供給當觀光協會的所有信息（以下稱為「提供信息」）的著作權及其他權利（包括著作權法第27條和第28條的權利）在發送該信息至本服務時，均轉移給當觀光協會。此外，用戶同意不行使提供信息的著作者人格權（公開權、姓名表示權、同一性保持權）。
  5. 用戶不得對提供信息的權利歸屬向當觀光協會提出或行使任何權利主張。
  
  （使用限制）
  第18條
  1. 若註冊用戶或使用者符合以下任何一項，當觀光協會有權限制其使用本服務：
      (1) 若註冊用戶或使用者使用本網站可能導致電腦病毒或大量郵件的擴散，或可能對當觀光協會或第三方造成損害。
      (2) 若當觀光協會無法通過電子郵件或其他方式與註冊用戶聯繫。
      (3) 其他當觀光協會認為需要限制使用的情況。
  2. 關於使用限制，當觀光協會不對註冊用戶或使用者或其他人承擔任何責任。
  
  （刪除及變更權限）
  第19條
  1. 若註冊用戶符合以下任何一項，當觀光協會有權在不事先通知的情況下，立即刪除投稿信息等，並刪除ID，並採取未來的使用禁止措施：
      (1) 註冊用戶違反本條款或其他規定。
      (2) 註冊的ID被反社會勢力或其成員或關係人取得或使用，或當觀光協會認為有可能被使用。
      (3) 發現所發布的投稿信息不屬於註冊用戶。
  2. 關於刪除或使用禁止，當觀光協會不對相關人士或第三方承擔任何責任。
  3. 當觀光協會認為有必要時，可以在不通知註冊用戶或使用者的情況下，隨時更改、停止或終止服務。
  4. 當觀光協會可以根據自己的判斷，在不事先通知註冊用戶或使用者的情況下，更改本條款或更新、添加、更改、刪除本網站的內容。
  5. 關於服務內容的更改、停止、終止，當觀光協會不對註冊用戶或使用者或其他人承擔任何責任。
  
  （通信的秘密）
  第20條
  1. 當觀光協會根據電信業務法，保護用戶的通信秘密。
  2. 當觀光協會在以下情況之一時，不承擔前項的保密義務：
      ・根據刑事訴訟法或犯罪調查中的通信監聽法的規定進行強制措施或法院命令的情況...在該措施或法院命令的範圍內。
      ・根據法律進行行政措施的情況...在該措施或命令的範圍內。
      ・當觀光協會認為已滿足特定電信服務提供者的損害賠償責任限制和發信者信息披露法第4條的要求...在該披露要求的範圍內。
      ・當觀光協會認為為保護第三方的生命、身體或財產是必要的...在為保護第三方的生命、身體或財產所必需的範圍內。
  3. 當觀光協會根據特定電信服務提供者的損害賠償責任限制和發信者信息披露法第4條第2項的規定，決定是否披露，並應徵詢該投稿信息的發信者的意見。但是，如果無法與該披露要求相關的侵權信息的發信者聯繫，或存在其他特殊情況，則不適用此限制。
  
  （個人信息管理）
  第21條
  1. 當觀光協會將註冊用戶註冊的個人信息、通過當觀光協會的服務提供的個人信息，以及其他用戶提供給當觀光協會的所有信息（以下統稱為「提供的個人信息」）根據本服務的「隱私政策」進行適當處理。
  2. 當觀光協會可以通過電子郵件或其他方式向註冊用戶提供包括廣告在內的信息，且註冊用戶同意此事。但是，如果註冊用戶不希望提供信息，則不提供信息。
  3. 當觀光協會可以使用通過本服務獲得的信息或由用戶提供或輸入的信息進行信息分析和評估，並可能向第三方提供信息。
  4. 為了實現適當的匹配，註冊用戶同意其業務次數、時間、遲到和早退次數以及其他有關註冊用戶的信息將被發送給當觀光協會的交易對象。但是，原則上，不包括姓氏等被認為不是為了適當匹配而必要的信息。
  5. 註冊用戶同意，對於投稿數據中不向其他用戶公開的消息等，為了預防和解決問題、防止不正當使用、確保安全性以及其他運營本服務所必需的情況，當觀光協會可以查看。
  6. 當觀光協會可以將用戶提供給當觀光協會的信息、數據、用戶信息等作為統計信息在不能識別個人的形式下使用和公開，且用戶不得對此提出異議。
  7. 如果當觀光協會將本服務的運營業務轉讓給其他公司，則可以隨著該業務轉讓將本條款的地位、基於本條款的權利和義務以及用戶的註冊信息、提供的個人信息和其他信息轉讓給該業務轉讓的接收者，且用戶事先同意此事。此外，本條所指的業務轉讓包括但不限於公司分割和其他所有業務轉移情況。
  8. 除了前三項和前四項外，當觀光協會不得在未獲得註冊用戶或使用者的事先同意的情況下向第三方提供個人信息。但以下情況除外：
      (1) 根據法律的規定。
      (2) 當觀光協會認為為了保護第三方的生命、身體或財產是必要的。
      (3) 特別需要為了提高公共衛生或促進兒童的健康成長。
      (4) 當需要協助公共機構或其受託人執行法律規定的業務，且獲得本人的同意可能妨礙該業務的執行時。
  
  （非保証）
  第22條
  當觀光協會及其參與本服務提供的企業，不對以下各項進行任何保證：
  (1) 內容的正確性和商品的完整性
  (2) 內容不侵犯第三方的權利
  (3) 本服務將持續提供
  (4) 保證廣告發布企業和商品相關事項的可靠性或效能
  
  （免責）
  第23條
  1. 當觀光協會不對註冊用戶或使用者使用本服務所投稿的信息內容承擔任何責任。
  2. 當觀光協會不參與註冊用戶間的通信或活動。如有爭議，由相關註冊用戶間解決，當觀光協會不承擔該責任。
  3. 當觀光協會不對本服務的中斷、停止、終止、無法使用或更改，用戶投稿的數據的刪除或丟失，註冊用戶的註冊取消，由於使用本服務而導致的數據丟失或設備故障或損壞，以及其他與本服務相關的用戶損害承擔賠償責任。但是，如果當觀光協會存在故意或重大過失，則除外。
  4. 如果根據消費者契約法或其他原因，當觀光協會需要承擔責任（除故意或重大過失外），其賠償責任僅限於用戶實際且直接遭受的損害，且在輕微過失的情況下，其上限為10,000日元。
  5. 如果由於不可預見的因素導致本網站的瀏覽出現障礙，當觀光協會不承擔任何責任。
  6. 當觀光協會不保證本服務適合用戶的特定目的，具有期望的功能、商品價值、正確性、有用性，用戶使用本服務是否符合適用的法律或行業組織的內部規則，以及不會出現故障。
  7. 當觀光協會不對廣告發布企業和支付代理公司相關事項承擔任何責任。
  
  （賠償額的限制）
  第24條
  1. 當觀光協會不對由於使用本服務而產生的任何損害承擔賠償義務。但是，如果當觀光協會由於故意或重大過失對註冊用戶、使用者或其他第三方造成損害，則可能需要賠償該損害的實際費用。
  2. 當觀光協會不對註冊用戶、使用者或其他第三方因機會損失、業務中斷或其他任何損害（包括間接損害和損失利益）承擔責任，即使當觀光協會事先被通知了這些損害的可能性。
  
  （其他服務的關係）
  第25條
  如果當觀光協會提供的本服務或其他服務有與本條款不同的規定，則該規定也適用。如果有與本條款相矛盾的描述，則優先適用該服務的規定。
  
  （通知方法）
  第26條
  1. 如果當觀光協會認為需要聯繫註冊用戶，將使用已提交給當觀光協會的電子郵件地址進行通知。
  2. 如果使用者或註冊用戶認為需要通知當觀光協會，則原則上通過電子郵件進行聯繫。
  3. 關於廣告商品的查詢，請直接聯繫該商品的提供商。
  
  （不可抗力）
  第27條
  對於由於天災、洪水、海嘯、颱風、地震、疾病或其他傳染病、戰爭、恐怖襲擊、火災或政府機關的行政指導等不可抗力因素，直接或間接導致的本條款義務的不履行或延遲履行，當觀光協會不對註冊用戶、使用者或其他第三方承擔任何責任。
  
  （適用法律）
  第28條
  本條款的適用法律為日本法。
  
  （裁判管轄）
  第29條
  關於本條款，如果當觀光協會和註冊用戶或使用者之間發生爭議，則當觀光協會的所在地的法院將作為第一審的合意管轄法院。
  
  
  
  一般社團法人熱海市觀光協會
  地址：〒413-0014 靜岡縣熱海市渚町2018-8
  電話：0557-85-2222
  `,
};
