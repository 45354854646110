import { useRouter } from "next/router";

import en from "../../locales/en";
import ja from "../../locales/ja";
import zhCn from "../../locales/zh-cn";
import zhTw from "../../locales/zh-tw";

import { LocaleObjKeys, Locales } from "@/types/common-types";
import "dayjs/locale/en";
import "dayjs/locale/ja";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";

export const isLocales = (arg?: string): arg is Locales => {
  if (!arg) return false;
  return ["ja", "en", "zh-cn", "zh-tw"].includes(arg);
};

export const localeObjKeyMap = (l: Locales): LocaleObjKeys => {
  switch (l) {
    case "ja":
      return "ja";
    case "en":
      return "en";
    case "zh-cn":
      return "zhch";
    case "zh-tw":
      return "zhtw";
    default:
      return "ja";
  }
};

export const useLocale = () => {
  const { locale } = useRouter();

  if (!isLocales(locale)) return { locale: "ja" as Locales, localeObjKey: localeObjKeyMap("ja"), translated: ja };

  return {
    locale,
    localeObjKey: localeObjKeyMap(locale),
    translated: { ja, en, "zh-cn": zhCn, "zh-tw": zhTw }[locale],
  };
};
