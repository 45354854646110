import { SVGProps } from "react";

export const SvgIconShare = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none" viewBox="0 0 15 14" {...props}>
    <path
      fill="#000"
      d="M12.138 8.398v2.8A2.804 2.804 0 019.338 14H2.8A2.804 2.804 0 010 11.199V4.663a2.804 2.804 0 012.801-2.801h3.735a.467.467 0 010 .933H2.801A1.87 1.87 0 00.934 4.663v6.536A1.87 1.87 0 002.8 13.066h6.536a1.87 1.87 0 001.867-1.867V8.398a.467.467 0 01.934 0zm1.73-5.466l-2.8-2.8a.467.467 0 00-.66.66l2.004 2.003h-1.208a7.478 7.478 0 00-7.47 7.47.467.467 0 00.934 0 6.544 6.544 0 016.536-6.536h1.208l-2.004 2.004a.467.467 0 10.66.66l2.8-2.8a.467.467 0 000-.661z"
    ></path>
  </svg>
);
