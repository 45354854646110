export default {
  // パスワード再設定
  PAGE_TITLE: "修改密码",
  COMPLETE_PAGE_TITLE: "密码修改完成",
  CHANGE_PASSWORD_TITLE: "修改密码",
  CHANGE_PASSWORD_ACTION: "更改密码",
  CHANGE_PASSWORD_SUBMIT_ERROR: "在更改密码过程中发生未知错误。请稍后再试。",
  CHANGE_PASSWORD_COMPLETE_TITLE: "密码修改完成",
  CHANGE_PASSWORD_COMPLETE_DESCRIPTION: "您的密码已修改。\n请继续享受GoATAMI。",
};
