export default {
  PAGE_TITLE: `隐私政策`,
  PRIVACY_POLICY: `个人资料保护方针`,
  PRIVACY_POLICY_CONTENT: `一般社团法人热海市观光协会（以下“本观光协会”）基于认识到应当明确个人资料适当处理的原则，确认个人资料的适当处理的重要性，并制定个人资料保护方针，由役员及员工共同遵守，致力于全面保护个人资料及个人的权益。

  1. 在取得个人资料时，我们将最大程度地明确使用目的，并仅在达到该目的所需的范围内收集资料。
  2. 直接从本人以书面形式取得个人资料时，会先通知本观光协会名称、个人资料保护管理员姓名及联系方式、使用目的等，然后在必要的范围内收集资料。
  3. 使用个人资料只限于获得本人同意的使用目的范围。并确立必要手续以确保不会超出此范围进行使用，且将进行此类手续。
  4. 我们将适当地管理所持有的个人资料，不会在未经本人同意的情况下向第三方披露或提供。
  5. 我们将依据使用目的，在必要的范围内，管理所持有的个人资料，确保其准确且为最新状态，并对可能出现的资料泄露、遗失或毁损等情况采取合理的安全措施，致力于预防及纠正。
  6. 若将个人资料处理外包给外部，我们将通过合同义务化不泄露、不向第三方提供等事项，并对委托方进行适当的管理。
  7. 关于所持有的个人资料的投诉或咨询，可通过联系本观光协会的询问窗口，我们将给予回应。
  8. 我们将遵守与个人资料处理相关的法律、国家规定的指导方针和其他规范。
  9. 我们已制定个人资料保护管理系统，并将定期进行审查，持续进行改善。
  
  制定日：2023年10月23日
  一般社团法人热海市观光协会
  代表理事 中岛 干雄
  
  <关于个人资料保护方针的询问处>
  
  一般社团法人热海市观光协会  
  〒413-0014 静冈县热海市渚町2018-8  
  电话：0557-85-2222
  `,
  POLICY_FOR_HANDLING: `关于个人信息的处理政策`,
  POLICY_FOR_HANDLING_CONTENT: `一般社团法人热海市观光协会（以下“当观光协会”）是，在本网站上提供的服务（以下称为“本服务”）中，对于用户的个人信息的处理，按照以下隐私政策（以下称为“本政策”）规定。

  第1条（个人信息）
  “个人信息”是，个人信息保护法中所说的“个人信息”，关于仍然活着的个人的信息，该信息包括的姓氏、生日、地址、电话号码、联系方式以及其他描述等可以识别特定的个人的信息和外貌、指纹、声纹的资料，和健康保险的保险者编号等的该信息单独从中可以识别特定的个人的信息（个人识别信息）。
  
  第2条（个人信息的收集方法）
  当观光协会在用户注册使用时会询问姓氏、生日、地址、电话号码、电子邮件地址、信用卡号码等的个人信息。此外，用户和合作伙伴之间进行包含用户的个人信息的交易记录和付款信息，当观光协会的合作伙伴（包括信息提供源、广告主、广告发布处等，以下称为“合作伙伴”）从中收集。
  
  第3条（收集及使用个人信息的目的）
  当观光协会收集及使用个人信息的目的如下：
  
      - 为了提供和经营当观光协会的服务
      - 为了回答来自用户的问题（包括身份验证）
      - 为了寄送给用户使用中的服务的新功能、更新消息、宣传活动等以及当观光协会提供的其他服务的信息
      - 根据需要联系维护或重要的消息
      - 为了识别违反使用条款的用户或试图以不正当的目的使用服务的用户，并拒绝他们使用
      - 为了让用户查看或更改自己的注册信息和使用状态
      - 为了在付费服务中向用户收取使用费用
      - 关于上述使用目的的其他目的
  
  第4条（使用目的的变更）
  1. 当观光协会只在使用目的与之前相关且可以合理接受的情况下，才会更改个人信息的使用目的。
  2. 如果更改了使用目的，将通过当观光协会指定的方式通知用户，或在本网站上公开。
  
  第5条（个人信息的第三方提供）
  1. 当观光协会除了以下情况外，不会未经用户的事先同意，向第三方提供个人信息。但是，除非个人信息保护法或其他法律允许。
      - 当需要保护人的生命、身体或财产，并且难以获得本人的同意时。
      - 为了提高公众卫生或推动儿童健康成长，特别需要并且难以获得本人的同意时。
      - 国家机关或地方公共团体或其委托人执行法令所规定的事务，需要合作且因获得本人同意可能妨碍事务的进行时。
      - 提前通知或公告以下事项，并且当观光协会已向个人信息保护委员会提交：
      - 利用目的包括向第三方提供
      - 提供给第三方的数据项
      - 向第三方提供的方法或方式
      - 应本人请求停止向第三方提供个人信息
      - 接受本人请求的方法
  2. 尽管有前项规定，以下情况不视为提供给第三方：
      - 当观光协会为达到利用目的，在必要范围内将个人信息的处理全部或部分委托时。
      - 由于合并或其他原因导致业务继续时提供个人信息。
      - 与特定人共同使用个人信息，并已事先通知或使本人容易得知信息共用、共用的个人信息项目、共用者的范围、使用者的使用目的，以及负责该个人信息管理的人的姓名或名称。
  
  第6条（个人信息的公开）
  1. 当观光协会收到本人要求公开其个人信息时，应立即予以公开。但如果公开可能导致以下情况，可以不全部或部分公开，并应立即通知该决定。此外，每次公开个人信息将收取1,000円的手续费。
      - 可能损害本人或第三者的生命、身体、财产或其他权益。
      - 可能严重妨碍当观光协会业务的正常执行。
      - 违反其他法律的情况。
  2. 尽管有前项规定，原则上不公开个人信息以外的履历资料和特性资料等信息。
  
  第7条（个人信息的修正及删除）
  1. 如果用户认为当观光协会持有的其个人信息是错误的，可以按照当观光协会的规定程序要求修正、增加或删除（以下称为“修正等”）。
  2. 当观光协会接受用户的前项请求并认为需要响应该请求时，应立即进行相应的修正等。
  3. 根据前项规定，当观光协会进行了修正等或决定不进行修正等时，应立即通知用户。
  
  第8条（个人信息的利用停止等）
  1. 如果本人认为其个人信息超出利用目的的范围或因不正手段获得，并要求停止使用或删除（以下称为“利用停止等”），当观光协会应立即进行必要调查。
  2. 基于前项的调查结果，如果认为需要响应该请求，则应立即进行相应的利用停止等。
  3. 根据前项规定，当观光协会进行了利用停止等或决定不进行利用停止等时，应立即通知用户。
  4. 尽管有前两项的规定，如果利用停止等需要大量费用或其他原因导致难以执行，且可以采取其他替代措施保护用户的权益，则应采取该替代措施。
  
  第9条（隐私政策的变更）
  1. 除非法律或本政策有其他规定，否则可以在不通知用户的情况下更改本政策的内容。
  2. 除非当观光协会有其他规定，否则更改后的隐私政策从本政策公告的日期生效。
  
  第10条（联系方式）
  有关本政策的任何问题或意见，请通过以下方式联系我们：
  
  一般社团法人热海市观光协会
  〒413-0014 静冈县热海市渚町2018ｰ8
  电话：0557-85-2222
  `,
};
