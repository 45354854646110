export default {
  // パスワードリセット: 認証メール送信
  SEND_VERIFICATION_MAIL_PAGE_TITLE: "重設密碼",
  SENT_VERIFICATION_MAIL_PAGE_TITLE: "發送驗證郵件完成",
  RESET_PASSWORD_TITLE: "重設密碼",
  RESET_PASSWORD_DESCRIPTION: "請輸入電子郵件地址以完成郵件驗證。",
  SEND_VERIFICATION_MAIL: "發送驗證郵件",
  RESET_PASSWORD_SUBMIT_ERROR: "在重設密碼過程中發生了未知錯誤。請稍後再試。",
  RESET_PASSWORD_SENT_MAIL_DESCRIPTION: "我們已發送驗證郵件。\n請點擊郵件內的驗證連結以完成密碼重設。",

  // パスワードリセット: 新規パスワード入力
  EDIT_PASSWORD_PAGE_TITLE: "設定密碼",
  COMPLETE_RESET_PASSWORD_PAGE_TITLE: "重設密碼完成",
  NEW_PASSWORD_DESCRIPTION: "請輸入新密碼以完成密碼重設。",
  RESET_PASSWORD_ACTION: "重設密碼",
  COMPLETE_RESET_PASSWORD_TITLE: "密碼重設完成",
  COMPLETE_RESET_PASSWORD_DESCRIPTION: "密碼已成功重設。\n請重新登錄。",
  RESET_PASSWORD_ERROR_FIREBASE_USER_NOT_FOUND: "未找到用戶。請重新從輸入您的電子郵件地址開始嘗試。",
  RESET_PASSWORD_ERROR_DESCRIPTION: "請確認您輸入的電子郵件地址，然後再次嘗試輸入您的電子郵件地址。",
  RESET_PASSWORD_ERROR_AUTH_LINK_EXPIRED: "有效期限已過期。請重新從輸入您的電子郵件地址開始嘗試。",
};
