export default {
  // 購入チケット一覧
  TICKET_MONTH: "MMMM",
  TICKET_DAY: "D",
  TICKET_DATE_TIME_START: "dddd HH:mm",
  TICKET_DATE_TIME_END: "HH:mm",
  TICKET_TOTAL: "总计 : ",
  NO_TICKET_TITLE: "没有已购买的票。",
  TICKET_CANCEL_POLICY: "提前5天以上取消，收取50%费用；4天以内取消，收取100%费用",
  TICKET_NUMBER: "车票号码",
  TICKET_CANCEL_HISTORY: "取消记录",
  TICKET_REFUND_AMOUNT: "退款金额",
  TICKET_CANCEL_COMPLETE: "完成",
  TICKET_CANCEL_COMPLETED_NOTIFICATION: "已完成取消",
  TICKET_CANCEL_CONFIRM: "确认",
  TICKET_CANCEL_OK1: "可以",
  TICKET_CANCEL_OK2: "取消",
  TICKET_CANCEL_OK3: "这张车票吗？",
  TICKET_CANCEL_Fee50: "目前取消费用为50%。",
  TICKET_CANCEL_Fee100: "目前取消费用为100%",
  TICKET_CANCEL_COMPLETE_CLOSE: "关闭",
  TICKET: "车票",
  TICKET_CANCEL_WARNING: "請注意，此操作無法取消。",
  TICKET_CANCEL_EXECUTE: "执行取消",
  TICKET_CANCEL_FAILED: "取消失败。",
};
