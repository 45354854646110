export default {
  DETAIL_PAGE_TITLE: "詳細輸入",
  PAYMENT_PAGE_TITLE: "輸入支付資訊",
  CONFIRMATION_PAGE_TITLE: "購買確認",
  COMPLETE_PAGE_TITLE: "購買完成",
  PURCHASE_LIST_PAGE_TITLE: "購買票券列表",
  RESERVED_DETAIL_PAGE_TITLE: "預訂詳情",
  EVENT: "活動",
  PLAN: "計劃",
  PLAN_RESERVE: "預訂計劃",
  TICKET_NUMBER: "票數",
  PLAN_SELECTON: "選擇計劃",
  RESERVATION_LIST: "預訂列表",
  RESERVATION_DETAILS: "預訂詳情",
  THANK_YOU_RESERVATION: "感謝您的預訂。",
  PLAN_INCLUDE_COMPLETE: "包括已完成的計劃",
  PLAN_VIEW_MORE: "查看更多計劃",
  BACK_TO_RESERVATION_LIST: "返回預訂列表",

  // チケット購入
  SOLD_OUT_BUTTON: "票已售完",
  TO_DETAIL: "查看詳情",
  PURCHASE_TICKET_DETAIL_TITLE: "詳細輸入",
  PURCHASE_TICKET_QUESTIONNAIRE_TITLE: "問卷",
  PURCHASE_TICKET_QUESTIONNAIRE_DESCRIPTION: "這是來自營運公司的問卷。\n請回答並點擊“下一步”。",
  PURCHASE_TICKET_PAYMENT_TITLE: "輸入支付資訊",
  PURCHASE_TICKET_CONFIRMATION_TITLE: "確認",
  PURCHASE_TICKET_ITEM: "主題",
  PURCHASE_TICKET_PAYMENT_SUBTITLE: "請輸入支付資訊，然後點擊“下一步”。\n如果總金額為 ¥0，則可以跳過此步驟。",
  CHECK_EMAIL_FOR_DETAILS: "我們已將票券詳情發送到您註冊的電子郵件地址，請查看。",
  SURVEY_FROM_OPERATING_COMPANY: "這是來自運營公司的問卷。請回答後點擊下一步。",
  CONFIRM_CONTENT: "請確認內容無誤後完成購買。",
  NEW_CONTENT_MESSAGE_1: "完成報名申請即視為同意GO ATAMI的「",
  NEW_CONTENT_MESSAGE_2: "」和「GO ATAMI",
  NEW_CONTENT_MESSAGE_3: "」。關於取消和退款等事項，請參閱",
  NEW_CONTENT_MESSAGE_4: "。",

  // チケット購入: 支払い
  PURCHASE_TICKET_CARD_NAME: "卡片持有人",
  PURCHASE_TICKET_CARD_NUMBER: "卡號",
  PURCHASE_TICKET_EXPIRY_DATE: "有效期限",
  PURCHASE_TICKET_CVC: "CVC",
  PLACEHOLDER_NAME: "TARO TANAKA",
  PLACEHOLDER_NUMBER: "4242 4242 4242 4242",
  PLACEHOLDER_DATE: "MM / YY",
  PLACEHOLDER_CVC: "3位數或4位數",
  PURCHASE_TICKET_TICKETTOTAL: "總計（含稅）",
  PURCHASE_TICKET_INCLUSIVE_TAX: "含消費稅",
  CREDIT_CARD_SWITCH: "信用卡切換",
  CREDIT_CARD_SWITCH_EXECUTE: "切換信用卡",
  CREDIT_CARD_SELECT: "請選擇一種付款方式。",
  CREDIT_CARD_REGISTERER: "註冊",
  CREDIT_CARD_CHANGE_FAILED: "無法更改信用卡",
  CREDIT_CARD_RECOMMEND_OTHER_PAYMENT_METHOD: "如果您希望使用其他付款方式，請從上面註冊信用卡。",
  CREDIT_CARD_CURRENT_PAYMENT_METHOD: "目前的支付方式",
  CREDIT_CARD_DELETE_SUCCESS: "卡片資訊已刪除",

  // チケット購入: チケット
  PURCHASE_TICKETADDITIONAL: "其他票券",

  // チケット購入: チケット選択
  PURCHASE_TICKET_TIME: "時間",
  PURCHASE_TICKET_NOT_SELECTED: "未選擇",
  PURCHASE_TICKET_FEE: "費用",
  PURCHASE_TICKET_AVAILABLE_PEOPLE: "可利用人數",
  PURCHASE_TICKET_AVAILABLE_TIME: "可预订时间",
  PURCHASE_TICKET_STAY_TIME: "可停留時間",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS: "H小時",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS_AND_MINUTS: "H小時m分鐘",
  PURCHASE_TICKET_DETAILS: "內容",
  PURCHASE_TICKET_TAX_INCLUDED: "（含稅）",
  PURCHASE_TICKET_TO: "〜",
  PURCHASE_TICKET_TO_PEOPLE: "名",
  PURCHASE_SCHEDULE: "日程",
  PURCHASE_PAYMENT_INFO: "結算資訊",
  CHOOSE_DATE: "請選擇日期",
  SELECT_NUMBER: "請選擇張數",
  SELECT_TIME: "請選擇時間",

  // チケット購入: チケット詳細
  PURCHASE_INFO: "購買資訊",
  PURCHASE_TOTAL_AMOUNT: "購買金額",
  PURCHASE_DATE: "購買日期和時間",
  PURCHASE_RETURN_TO_TICKETS: "返回門票列表",
  CONFIRM_TICKET: "入場紀錄",
  CANCELED: "已取消",
  PURCHASED: "已購買",
  RESERVED: "已預約",
  COMPLETED: "已結束",
  TICKET_DATE_FORMAT: "YYYY年MM月DD日",
  TICKET_DATETIME_FORMAT: "YYYY年MM月DD日 HH:mm",
  TICKET_YEAR_MONTH_FORMAT: "YYYY年M月",
  ENTRY_HISTORIES: "入場紀錄",
  ENTRY_NOT_EXISTS: "尚無入場紀錄。",

  // チケット購入: 確認
  PURCHASE_ERROR: "對不起，購買處理失敗了。請檢查支付信息和網絡連接，然後再次嘗試。",
  PURCHASE_CARD_REJECTED_ERROR: "無法註冊信用卡。可能是卡被拒絕了。請嘗試另一張卡。",
  PURCHASE_CARD_NETWORK_ERROR: "信用卡註冊失敗。可能存在網路錯誤。請檢查網路連接並重新嘗試。",
  PURCHASE_NO_SELECT_RESERVATIONFRAME_ERROR: "請選擇一個預約時段。",
  PURCHASE_SEND_MAIL_ERROR: "購票已完成，但無法發送電子郵件。",
  TICKET_CANCEL_INFO1: "如需取消詢問，",
  TICKET_CANCEL_INFO2: "請透過詢問表格。\n",
  TICKET_CANCEL_INFO3: "屆時，您將需要預約號碼和預約日期時間，請在購買票務列表的每張票的詳細頁面上進行確認。",

  // チケット購入: アンケート
  QUESTIONNAIRE_REQUEST_TITLE: "問卷請求",
  QUESTIONNAIRE_REQUEST_DESCRIPTION: "為了提升服務品質，我們決定進行問卷調查。若您能協助我們，我們將不勝感激。",
  QUESTIONNAIRE_SUBTITLE1_LIST6: "其他",
  QUESTIONNAIRE_TEXT_AREA_PLACEHOLDER: "如果您選擇了“其他”，請在此處填寫詳細信息。",
  SEND_QUESTIONNAIRE: "提交調查問卷",

  // 予約処理中の画面
  RESERVATION_FAIL: "預訂處理失敗。",
  PAYMENT_FAIL: "支付失敗。",
  RESERVATION_COMPLETE: "預訂完成",
  AUTH_UNKNOWN_ERROR: "認證時發生未知錯誤。請重新進行購買流程。",
  RETURN_INPUT_DETAIL: "返回至詳細輸入",
  RESERVATION_ALREADY_PAID: "該預訂已支付。",
  RETURN_EVENT_DETAIL: "返回至事件詳情",
  RESERVATION_PROCESSING: "正在處理預訂",
};
