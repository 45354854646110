import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class ReservationFrameApi extends BaseApi {
  async getReservationFrameList(
    parameters: APIPaths["/app/reservation-frame/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/reservation-frame/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/reservation-frame/list", { params: parameters })).data;
  }
  async getReservationFrameById(
    parameters: APIPaths["/app/reservation-frame/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/reservation-frame/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/reservation-frame/${parameters.id}`)).data;
  }
}
