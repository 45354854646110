import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class ReservationApi extends BaseApi {
  async getReservationList(
    parameters: APIPaths["/app/reservation/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/reservation/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/reservation/list", { params: parameters })).data;
  }
  async getReservationById(
    parameters: APIPaths["/app/reservation/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/reservation/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/reservation/${parameters.id}`)).data;
  }
  async postReservationNew(
    requestBody: APIPaths["/app/reservation/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/reservation/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/app/reservation/new", requestBody)).data;
  }
  async putReservationCompleteById(
    parameters: APIPaths["/app/reservation/{id}/complete"]["parameters"]["path"],
  ): Promise<APIPaths["/app/reservation/{id}/complete"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/reservation/${parameters.id}/complete`)).data;
  }

  async putCancelReservationById(
    parameters: APIPaths["/app/reservation/{id}/cancel"]["parameters"]["path"],
  ): Promise<APIPaths["/app/reservation/{id}/cancel"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/reservation/${parameters.id}/cancel`)).data;
  }

  async deleteReservationById(
    parameters: APIPaths["/app/reservation/{id}"]["parameters"]["path"],
  ): Promise<APIPaths["/app/reservation/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/reservation/${parameters.id}`)).data;
  }

  async postReservationSyncCheckIn(
    parameters: APIPaths["/app/reservation/{id}/sync-checkin"]["post"]["parameters"]["path"],
  ): Promise<
    APIPaths["/app/reservation/{id}/sync-checkin"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/app/reservation/${parameters.id}/sync-checkin`)).data;
  }
}
