export default {
  // メールアドレス変更
  PAGE_TITLE: "メールアドレス変更",
  COMPLETE_PAGE_TITLE: "メールアドレス変更完了",
  SENT_MAIL_PAGE_TITLE: "認証メール送信完了",
  CHANGE_MAIL_ADDRESS_TITLE: "メールアドレス変更",
  CHANGE_MAIL_ADDRESS_DESCRIPTION:
    "認証メールをお送りしました。\nメール内の認証リンクをクリックして、メールアドレス変更を完了させてください。",
  CHANGE_MAIL_ADDRESS_SUBMIT_ERROR:
    "メールアドレス変更処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  CHANGE_MAIL_ADDRESS_COMPLETE_TITLE: "メールアドレス変更完了",
  CHANGE_MAIL_ADDRESS_COMPLETE_DESCRIPTION: "メールアドレス変更が完了しました。\n引き続きGoATAMIをお楽しみください。",
  CHANGE_MAIL_ADDRESS_ERROR_DESCRIPTION:
    "入力したメールアドレスをお確かめの上、もう一度メールアドレスの入力をお試しください。",
  CHANGE_MAIL_ADDRESS_ERROR_FIREBASE_USER_NOT_FOUND:
    "ユーザーが見つかりませんでした。再度メールアドレス変更からお試しください。",
  CHANGE_MAIL_ADDRESS_ERROR_AUTH_LINK_EXPIRED: "有効期限が切れています。再度メールアドレス変更からお試しください。",
};
