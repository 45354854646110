export default {
  PAGE_TITLE: `根据特定商业交易法的显示`,
  MAILORDERLAW: `根据特定商业交易法的显示`,
  MAILORDERLAW_SALES_COMPANY: `销售业者`,
  MAILORDERLAW_COMPANY: `一般社团法人热海市观光协会`,
  MAILORDERLAW_SALES_COMPANY_REPRESENTATIVE: `销售业者代表/销售业务负责人`,
  MAILORDERLAW_NAME: `中岛干雄`,
  MAILORDERLAW_LOCATION: `地址`,
  MAILORDERLAW_LOCATION_NAME: `静冈县热海市渚町2018-8 亲水公园内`,
  MAILORDERLAW_CONTACT_INFORMATION: `联系方式`,
  MAILORDERLAW_INFORMATION: `0557-85-2222`,
  MAILORDERLAW_SELING_PRICE: `销售价格`,
  MAILORDERLAW_PRICE: `请参照商品页面的价格栏。每个商品的销售价格可能不同。`,
  MAILORDERLAW_SHIPING: `运费 `,
  MAILORDERLAW_SHIPING_NONE: `无。`,
  MAILORDERLAW_SALES_PRICE: `除销售价格和运费外所需的其他费用`,
  MAILORDERLAW_CONNECTION: `包括互联网连接相关的通信线路等费用。`,
  MAILORDERLAW_PAYMENT: `付款方式及付款时间`,
  MAILORDERLAW_PRICE_PAYMENT: `仅接受信用卡支付。\n购买时将进行结算。账单日期可能因信用卡公司而异。`,
  MAILORDERLAW_PRODUCT: `商品交付时间`,
  MAILORDERLAW_PAYMENT_COMPLETE: `除非购买页面有特别规定，否则在信用卡支付完成时交付。`,
  MAILORDERLAW_SALES_QUANTITIES: `销售期间及销售数量限制`,
  MAILORDERLAW_MAILORDERLAW_SALES_QUANTITIES: `请参考商品页面的销售期间、库存数量及门票数量限制。`,
  MAILORDERLAW_SALES_REGARDING: `退货相关特约 `,
  MAILORDERLAW_REGARDING: `我们将遵循各个活动的取消政策。\n但如果因主办方原因或其他情况导致活动取消，将全额退款。`,
};
