export default {
  // サインアップ
  SIGN_UP_PAGE_TITLE: "新規登録",
  SIGN_UP_COMPLETE_PAGE_TITLE: "新規登録完了",
  SIGN_UP_WITH_PASSWORD_PAGE_TITLE: "パスワード設定",
  SIGN_UP_TITLE: "はじめまして",
  SIGN_UP_DESCRIPTION:
    "メールアドレスを入力してください。認証メールを送信しますので、メール内の認証リンクをクリックし、サインアップを完了してください。",
  SIGN_UP_SUBMIT_ERROR: "サインアップ処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  SIGN_UP_COMPLETE_TITLE: "サインアップ",
  SIGN_UP_COMPLETE_DESCRIPTION:
    "認証メールをお送りしました。\nメール内の認証リンクをクリックして、サインアップを完了させてください。",
  SIGN_UP_TOPPAGE: "新規登録",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND: "ユーザーが見つかりませんでした。再度サインアップからお試しください。",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED: "有効期限が切れています。再度サインアップからお試しください。",
  SIGNUP_ERROR_USER_ALREADY_EXIST: "既に登録されたユーザーです。",
  SIGNUP_WITH_PASSWORD_TITLE: "ログインパスワードの設定",
  SIGNUP_WITH_PASSWORD_DESCRIPTION: "ログイン時に使用するパスワードを設定します。",
  SIGNUP_WITH_PASSWORD_ACTION: "パスワードを設定する",
  SIGNUP_WITH_PASSWORD_SUBMIT_ERROR:
    "ログインパスワード設定中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  SIGNUP_COMPLETE: "サインアップが完了しました。",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED_TITLE: "認証の有効期限が切れています。",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND_TITLE: "ユーザーが見つかりませんでした。",
  SIGNUP_ERROR_DESCRIPTION: "入力したメールアドレスをお確かめの上、もう一度新規登録をお願いします。",
  SIGNUP_FAIL_DESCRIPTION: "サインアップに失敗しました。時間をおいて再度お試しください。",
  SIGNUP_SENT_MAIL_DESCRIPTION: "認証メールをお送りしました。",
  SIGNUP_VERIFIED_MAIL_DESCRIPTION: "メールアドレスの認証が完了しました。会員情報の入力をお願いします。",
  SIGNUP_NEW_MEMBER_REGISTRATION: "新規会員登録",
  SIGNUP_DISPLAYED_PROMOTE:
    "チケット購入だけでなく様々な観光情報や特別割引などの特典をご利用いただけます。\n登録は無料でいつでも簡単に解約が可能です。",

  // ログイン
  SIGN_IN_PAGE_TITLE: "ログイン",
  SIGN_IN_TITLE: "おかえりなさい",
  SIGN_IN_DESCRIPTION: "メールアドレスとパスワードを入力してログインを完了してください。",
  SIGN_IN_SUBMIT_ERROR: "ログイン処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  SIGN_IN_INPUT_ERROR: "メールアドレスとパスワードが一致しません。もう一度お試しください。",
  SIGN_IN_SUBMIT: "ログインしました。",

  // ログアウト
  SIGN_OUT_SUBMIT_ERROR: "ログアウト処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  SIGN_OUT_SUBMIT: "ログアウトしました。",
};
