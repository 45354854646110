export default {
  // パスワードリセット: 認証メール送信
  SEND_VERIFICATION_MAIL_PAGE_TITLE: "パスワードリセット",
  SENT_VERIFICATION_MAIL_PAGE_TITLE: "認証メール送信完了",
  RESET_PASSWORD_TITLE: "パスワードリセット",
  RESET_PASSWORD_DESCRIPTION: "メールアドレスを入力してメール認証を完了してください。",
  SEND_VERIFICATION_MAIL: "認証メールを送信する",
  RESET_PASSWORD_SUBMIT_ERROR:
    "パスワードリセット処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  RESET_PASSWORD_SENT_MAIL_DESCRIPTION:
    "認証メールをお送りしました。\nメール内の認証リンクをクリックして、パスワードリセットを完了させてください。",

  // パスワードリセット: 新規パスワード入力
  EDIT_PASSWORD_PAGE_TITLE: "パスワード設定",
  COMPLETE_RESET_PASSWORD_PAGE_TITLE: "パスワードリセット完了",
  NEW_PASSWORD_DESCRIPTION: "新しいパスワードを入力してパスワードリセットを完了してください。",
  RESET_PASSWORD_ACTION: "パスワードをリセットする",
  COMPLETE_RESET_PASSWORD_TITLE: "パスワードリセット完了",
  COMPLETE_RESET_PASSWORD_DESCRIPTION: "パスワードリセットが完了しました。\nもう一度ログインしてください。",
  RESET_PASSWORD_ERROR_FIREBASE_USER_NOT_FOUND:
    "ユーザーが見つかりませんでした。再度メールアドレスの入力からお試しください。",
  RESET_PASSWORD_ERROR_DESCRIPTION:
    "入力したメールアドレスをお確かめの上、もう一度メールアドレスの入力をお試しください。",
  RESET_PASSWORD_ERROR_AUTH_LINK_EXPIRED: "有効期限が切れています。再度メールアドレスの入力からお試しください。",
};
