export default {
  // お問い合わせ
  PAGE_TITLE: "Contact",
  CONFIRM_PAGE_TITLE: "Confirmation of contact details",
  COMPLETE_PAGE_TITLE: "Thank you for your inquiry",
  CONTACT: "Contact",
  THANKS_MESSAGE_FOR_CONTACT:
    "Thank you very much for letting us know your opinions and concerns. We are unable to respond to individual inquiries, but will pass them on to our team who are working to improve our service.",
  YAMADA_TAROU: "Yamada Taro",
  CONTENT_OF_CONTACT: "Inquiry Details",
  THIS_IS_CONTENT_OF_CONTACT: "Details of inquiry.",
  CONTACT_CONFIRM: "Confirmation of contact details",
  THANKS_FOR_CONTACT: "Thank you for your inquiry",
  RESPONSE_THREE_BUSINESS_DAYS:
    "Thank you for your inquiry. We will respond within 3 business days, so please wait for a while.",
  CONTACT_CANCEL_INFO1: "For cancellation inquiries, the application number and reservation date and time are required. Please check on each ticket's detail page in the ",
  CONTACT_CANCEL_INFO2: ".",
};
