export default {
  // 共通
  SITE_NOTE1: "Atami City Tourism Association",
  SITE_NOTE2: "Ticket Sales Site",
  SIGN_UP: "Sign Up",
  SIGN_UP_ACTION: "Sign Up",
  SIGN_IN: "Log In",
  SIGN_IN_ACTION: "Log In",
  PASSWORD: "Password",
  NEW_PASSWORD: "New Password",
  NEW_PASSWORD_CONFIRM: "Confirm New Password",
  SHARE: "Share",
  ADDRESS: "Address",
  PHONE_NUMBER: "Phone Number",
  LANGUAGE: "Language",
  JAPANESE: "Japanese",
  ENGLISH: "English",
  TRADITIONAL_CHINESE: "Traditional Chinese",
  SIMPLIFIED_CHINESE: "Simplified Chinese",
  YOUR_MAIL_ADDRESS: "Your email address",
  MAIL_ADDRESS: "Email Address",
  ACCESS: "Access",
  TOKYO: "Tokyo",
  KYOTO: "Kyoto",
  NAGOYA: "Nagoya",
  ATAMI: "Atami",
  NAME: "Name",
  BACK: "Back",
  NEXT: "Next",
  DELETE: "Delete",
  SEND: "Submit",
  BACK_TO_TOP: "Back to top",
  BACK_TO_SIGN_IN: "Back to Login",
  FAX: "FAX",
  FAX_NUMBER: "Fax number",
  OPERATION_COMPANY_NAME: "General Incorporated Association Atami City Tourism Association",
  OPERATION_COMPANY_ADDRESS: `2018-8 Nagisa-cho, Atami City, Shizuoka Prefecture, Shinsui Park Rainbow Deck (near Hikawa)`,
  PURCHASED_TICKETS: "Purchase Ticket List",
  RESERVED_TICKETS: "Reservation List",
  ADDRESS_CHANGE: "Change Email Address",
  PASSWORD_CHANGE: "Change Password",
  LOGOUT: "Log Out",
  ERROR_USER_NOT_FOUND: "No user was found. Please complete the new registration from sign-up.",
  CANCEL: "Cancel",
  FORGOT_PASSWORD: "If you do not have an account,",
  RESET_PASSWORD: "Forgot your password?",
  SIGN_IN_PATH: "To log in,",
  ALREADY_ACCOUNT: "If you already have an account,",
  CLICK_HERE: "Click here",
  ERROR_RESISTED_ALREADY: "This email address is already registered.",
  ERROR_INVALID_LINK: "This link has already been used.",
  ERROR_UNKNOWN: "An unknown error has occurred. Please try again after some time.",
  TICKET_COUNT: "ticket",
  LINK_COPY: "The link has been copied.",
  SEND_MAIL_ERROR: "Failed to send. Please try again later.",
  PASSWORD_CONFIRM: "Confirm Password",
  LANGUAGE_IN_USE: "Language in Use",
  RESERVATION_NUMBER: "Reservation Number",
  NOT_SELECTED: "Not Selected",
  PLAN: "Plan",

  // 404ページ
  DESIGNATION_PAGE_NOT: "The specified page does not exist.",

  // 500ページ
  UNEXPECTED_ERROR: "An unexpected error occurred.",

  // meta description
  DESCRIPTION:
    "The ticket site of the Atami City Tourism Association. Hosting winter events. We are broadcasting information about Kiunkaku, Geisha Kenban, and Itokawa's night cherry blossoms. We introduce tourist information about Atami and sell tickets. Please come visit us.",
  DETAIL_DESCRIPTION:
    "We provide introductions to Atami's tourist information and sell tickets. Please do come and visit.",
};
