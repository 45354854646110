export default {
  PAGE_TITLE: `特定商取引法に関する表示`,
  MAILORDERLAW: `特定商取引法に関する表示`,
  MAILORDERLAW_SALES_COMPANY: `販売事業者`,
  MAILORDERLAW_COMPANY: `一般社団法人熱海市観光協会`,
  MAILORDERLAW_SALES_COMPANY_REPRESENTATIVE: `販売事業者代表/販売事業責任者`,
  MAILORDERLAW_NAME: `中島幹雄`,
  MAILORDERLAW_LOCATION: `所在地`,
  MAILORDERLAW_LOCATION_NAME: `静岡県熱海市渚町2018ｰ8 親水公園内`,
  MAILORDERLAW_CONTACT_INFORMATION: `お問い合わせ先`,
  MAILORDERLAW_INFORMATION: `0557-85-2222`,
  MAILORDERLAW_SELING_PRICE: `販売価格`,
  MAILORDERLAW_PRICE: `商品ページの価格欄をご参照ください。商品毎に販売価格は異なります。`,
  MAILORDERLAW_SHIPING: `送料 `,
  MAILORDERLAW_SHIPING_NONE: `なし。`,
  MAILORDERLAW_SALES_PRICE: `販売価格・送料等以外に必要な料金`,
  MAILORDERLAW_CONNECTION: `インターネット接続に係る通信回線等の諸費用。`,
  MAILORDERLAW_PAYMENT: `代金の支払方法・支払時期`,
  MAILORDERLAW_PRICE_PAYMENT: `クレジットカードのみ。
    ご購入時に決済させていただきます。ご請求日は各カード会社により異なります。`,
  MAILORDERLAW_PRODUCT: `商品の引渡時期`,
  MAILORDERLAW_PAYMENT_COMPLETE: `購入に関するページに特別な定めを置いている場合を除き、クレジットカード決済完了時。`,
  MAILORDERLAW_SALES_QUANTITIES: `販売期間・販売数量の制限`,
  MAILORDERLAW_MAILORDERLAW_SALES_QUANTITIES: `商品ページの販売期間・在庫数・チケット枚数の制限をご参照ください。`,
  MAILORDERLAW_SALES_REGARDING: `返品に関する特約 `,
  MAILORDERLAW_REGARDING: `各イベントのキャンセルポリシーに従います。\nただし、主催者都合やなんらかの事情でイベントが中止になった場合は全額返金いたします。
    `,
};
