export default {
  //バリデーション
  SUBMIT_ERROR_TITLE: "Error",
  VALIDATION_EMPTY_MAIL_ADDRESS: "Please enter your email address.",
  VALIDATION_INVALID_ADDRESS: "The email address format is invalid.",
  VALIDATION_EMPTY_PASSWORD: "Please enter your password.",
  VALIDATION_INVALID_PASSWORD:
    "Please enter a password that is at least 8 characters long and includes a combination of alphanumeric characters.",
  VALIDATION_UNMATCH_PASSWORD: "Passwords do not match. Please re-enter.",
};
