import { SVGProps, useMemo } from "react";

type Direction = "right" | "left";

export const SVGIConChevron = (props: SVGProps<SVGSVGElement>) => {
  const getRotateDeg = (direction: Direction) => {
    switch (direction) {
      case "right":
        return 180;
      case "left":
        return 0;
      default:
        return 0;
    }
  };

  const style = useMemo(
    () => ({ transform: `rotate(${getRotateDeg(props.direction as Direction)}deg)` }),
    [props.direction],
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" fill="none" style={style} viewBox="0 0 14 8">
      <path
        fill="#000"
        d="M6.999 8.002a.998.998 0 01-.708-.293L.287 1.704A1 1 0 011.702.289l5.297 5.297L12.296.289a1 1 0 011.415 1.415L7.707 7.709a.998.998 0 01-.708.293z"
      ></path>
    </svg>
  );
};
