export default {
  DETAIL_PAGE_TITLE: "详细输入",
  PAYMENT_PAGE_TITLE: "支付信息输入",
  CONFIRMATION_PAGE_TITLE: "购买确认",
  COMPLETE_PAGE_TITLE: "购买完成",
  PURCHASE_LIST_PAGE_TITLE: "购买票务列表",
  RESERVED_DETAIL_PAGE_TITLE: "预订详情",
  EVENT: "活动",
  PLAN: "计划",
  PLAN_RESERVE: "预订计划",
  TICKET_NUMBER: "票数",
  PLAN_SELECTON: "选择计划",
  RESERVATION_LIST: "预订列表",
  RESERVATION_DETAILS: "预订详情",
  THANK_YOU_RESERVATION: "感谢您的预订。",
  PLAN_INCLUDE_COMPLETE: "包括已完成的计划",
  PLAN_VIEW_MORE: "查看更多计划",
  BACK_TO_RESERVATION_LIST: "返回预订列表",

  // チケット購入
  SOLD_OUT_BUTTON: "票已售完",
  TO_DETAIL: "查看详情",
  PURCHASE_TICKET_DETAIL_TITLE: "详细输入",
  PURCHASE_TICKET_QUESTIONNAIRE_TITLE: "问卷",
  PURCHASE_TICKET_QUESTIONNAIRE_DESCRIPTION: "这是来自运营公司的问卷。\n请回答并点击“下一步”。",
  PURCHASE_TICKET_PAYMENT_TITLE: "支付信息输入",
  PURCHASE_TICKET_CONFIRMATION_TITLE: "确认",
  PURCHASE_TICKET_ITEM: "主题",
  PURCHASE_TICKET_PAYMENT_SUBTITLE: "请输入支付信息，然后点击“下一步”。\n如果总金额为 ¥0，可以跳过此步骤。",
  CHECK_EMAIL_FOR_DETAILS: "我们已将票务内容发送至您注册的电子邮箱，请查收。",
  SURVEY_FROM_OPERATING_COMPANY: "这是来自运营公司的问卷。请回答后点击“下一步”。",
  CONFIRM_CONTENT: "请确认内容无误后完成购买。",
  NEW_CONTENT_MESSAGE_1: "完成申请即视为同意GO ATAMI的「",
  NEW_CONTENT_MESSAGE_2: "」和「GO ATAMI",
  NEW_CONTENT_MESSAGE_3: "」。关于取消和退款等事项，请参阅",
  NEW_CONTENT_MESSAGE_4: "。",

  // チケット購入: 支払い
  PURCHASE_TICKET_CARD_NAME: "卡片持有人",
  PURCHASE_TICKET_CARD_NUMBER: "卡号",
  PURCHASE_TICKET_EXPIRY_DATE: "有效期限",
  PURCHASE_TICKET_CVC: "CVC",
  PLACEHOLDER_NAME: "TARO TANAKA",
  PLACEHOLDER_NUMBER: "4242 4242 4242 4242",
  PLACEHOLDER_DATE: "MM / YY",
  PLACEHOLDER_CVC: "3位数或4位数",
  PURCHASE_TICKET_TICKETTOTAL: "总计（含税）",
  PURCHASE_TICKET_INCLUSIVE_TAX: "含消费税",
  CREDIT_CARD_SWITCH: "信用卡切换",
  CREDIT_CARD_SWITCH_EXECUTE: "切换信用卡",
  CREDIT_CARD_SELECT: "请选择一种付款方式。",
  CREDIT_CARD_REGISTERER: "注册",
  CREDIT_CARD_CHANGE_FAILED: "无法更改信用卡",
  CREDIT_CARD_RECOMMEND_OTHER_PAYMENT_METHOD: "如果您希望使用其他付款方式，请从上面注册信用卡。",
  CREDIT_CARD_CURRENT_PAYMENT_METHOD: "目前的支付方式",
  CREDIT_CARD_DELETE_SUCCESS: "卡信息已删除",

  // チケット購入: チケット
  PURCHASE_TICKETADDITIONAL: "其他票务",

  // チケット購入: チケット選択
  PURCHASE_TICKET_TIME: "时间",
  PURCHASE_TICKET_NOT_SELECTED: "未选择",
  PURCHASE_TICKET_FEE: "费用",
  PURCHASE_TICKET_AVAILABLE_PEOPLE: "可使用人数",
  PURCHASE_TICKET_AVAILABLE_TIME: "可预约时间",
  PURCHASE_TICKET_STAY_TIME: "可停留时间",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS: "H小时",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS_AND_MINUTS: "H小时m分钟",
  PURCHASE_TICKET_DETAILS: "内容",
  PURCHASE_TICKET_TAX_INCLUDED: "（含税）",
  PURCHASE_TICKET_TO: "〜",
  PURCHASE_TICKET_TO_PEOPLE: "名",
  PURCHASE_SCHEDULE: "日程",
  PURCHASE_PAYMENT_INFO: "结算信息",
  CHOOSE_DATE: "请选择日期",
  SELECT_NUMBER: "请选择张数",
  SELECT_TIME: "请选择时间",

  // チケット購入: チケット詳細
  PURCHASE_INFO: "购买信息",
  PURCHASE_TOTAL_AMOUNT: "购买金额",
  PURCHASE_DATE: "购买日期和时间",
  PURCHASE_RETURN_TO_TICKETS: "返回门票列表",
  CONFIRM_TICKET: "入场记录",
  CANCELED: "已取消",
  PURCHASED: "已购买",
  RESERVED: "已预约",
  COMPLETED: "已结束",
  TICKET_DATE_FORMAT: "YYYY年MM月DD日",
  TICKET_DATETIME_FORMAT: "YYYY年MM月DD日 HH:mm",
  TICKET_YEAR_MONTH_FORMAT: "YYYY年M月",
  ENTRY_HISTORIES: "入場紀錄",
  ENTRY_NOT_EXISTS: "尚无入场记录。",

  // チケット購入: 確認
  PURCHASE_ERROR: "对不起，购买处理失败了。请检查支付信息和网络连接，然后再次尝试。",
  PURCHASE_CARD_REJECTED_ERROR: "无法注册信用卡。可能是卡被拒绝了。请尝试另一张卡。",
  PURCHASE_CARD_NETWORK_ERROR: "信用卡注册失败。可能存在网络错误。请检查互联网连接并重新尝试。",
  PURCHASE_NO_SELECT_RESERVATIONFRAME_ERROR: "请选择一个预约时段。",
  PURCHASE_SEND_MAIL_ERROR: "购票已完成，但无法发送电子邮件。",
  TICKET_CANCEL_INFO1: "如需取消咨询，",
  TICKET_CANCEL_INFO2: "请通过询问表格。\n",
  TICKET_CANCEL_INFO3: "届时，您将需要预约号码和预约日期时间，请在购买票务列表的每张票的详细页面上进行确认。",

  // チケット購入: アンケート
  QUESTIONNAIRE_REQUEST_TITLE: "问卷请求",
  QUESTIONNAIRE_REQUEST_DESCRIPTION: "为了提升服务质量，我们决定进行问卷调查。若您能协助我们，我们将不胜感激。",
  QUESTIONNAIRE_SUBTITLE1_LIST6: "其他",
  QUESTIONNAIRE_TEXT_AREA_PLACEHOLDER: "如果您选择了“其他”，请在此处填写详细信息。",
  SEND_QUESTIONNAIRE: "提交调查问卷",

  // 予約処理中の画面
  RESERVATION_FAIL: "预订处理失败。",
  PAYMENT_FAIL: "支付失败。",
  RESERVATION_COMPLETE: "预订完成",
  AUTH_UNKNOWN_ERROR: "认证时发生未知错误。请重新进行购买流程。",
  RETURN_INPUT_DETAIL: "返回至详细输入",
  RESERVATION_ALREADY_PAID: "该预订已支付。",
  RETURN_EVENT_DETAIL: "返回至事件详情",
  RESERVATION_PROCESSING: "正在处理预订",
};
