export default {
  DETAIL_PAGE_TITLE: "詳細入力",
  PAYMENT_PAGE_TITLE: "決済情報入力",
  CONFIRMATION_PAGE_TITLE: "購入確認",
  COMPLETE_PAGE_TITLE: "購入完了",
  PURCHASE_LIST_PAGE_TITLE: "購入チケット一覧",
  RESERVED_DETAIL_PAGE_TITLE: "予約詳細",
  EVENT: "イベント",
  PLAN: "プラン",
  PLAN_RESERVE: "プランを予約する",
  TICKET_NUMBER: "チケット枚数",
  PLAN_SELECTON: "プラン選択",
  RESERVATION_LIST: "予約一覧",
  RESERVATION_DETAILS: "予約詳細",
  THANK_YOU_RESERVATION: "ご予約ありがとうございます。",
  PLAN_INCLUDE_COMPLETE: "終了したプランも含める",
  PLAN_VIEW_MORE: "プランをもっとみる",
  BACK_TO_RESERVATION_LIST: "予約一覧へ戻る",

  // チケット購入
  SOLD_OUT_BUTTON: "予定枚数終了",
  TO_DETAIL: "詳細を見る",
  PURCHASE_TICKET_DETAIL_TITLE: "詳細入力",
  PURCHASE_TICKET_QUESTIONNAIRE_TITLE: "アンケート",
  PURCHASE_TICKET_QUESTIONNAIRE_DESCRIPTION:
    "運営会社からのアンケートになります。\n回答いただき、「次へ」をタップしてください。",
  PURCHASE_TICKET_PAYMENT_TITLE: "決済情報入力",
  PURCHASE_TICKET_CONFIRMATION_TITLE: "確認",
  PURCHASE_TICKET_ITEM: "件",
  PURCHASE_TICKET_PAYMENT_SUBTITLE:
    "決済情報を入力して、「次へ」をタップしてください。\n合計金額が ¥0 の場合は、スキップすることができます。",
  CHECK_EMAIL_FOR_DETAILS:
    "ご登録いただいているメールアドレスにチケットの内容をお送りしておりますので、ご確認ください。",
  SURVEY_FROM_OPERATING_COMPANY: "運営会社からのアンケートになります。回答いただき、「次へ」をタップしてください。",
  CONFIRM_CONTENT: "内容に誤りがないかご確認の上、購入を完了してください。",
  NEW_CONTENT_MESSAGE_1: "お申し込みを完了することにより、GO ATAMIの「",
  NEW_CONTENT_MESSAGE_2: "」 および「GO ATAMI ",
  NEW_CONTENT_MESSAGE_3: "」に同意したこととみなされます。キャンセル・返金等に関しては、",
  NEW_CONTENT_MESSAGE_4: "をご確認ください。",

  // チケット購入: 支払い
  PURCHASE_TICKET_CARD_NAME: "カード名義",
  PURCHASE_TICKET_CARD_NUMBER: "カード番号",
  PURCHASE_TICKET_EXPIRY_DATE: "有効期限",
  PURCHASE_TICKET_CVC: "CVC",
  PLACEHOLDER_NAME: "TARO TANAKA",
  PLACEHOLDER_NUMBER: "4242 4242 4242 4242",
  PLACEHOLDER_DATE: "MM / YY",
  PLACEHOLDER_CVC: "3桁もしくは4桁",
  PURCHASE_TICKET_TICKETTOTAL: "合計（税込）",
  PURCHASE_TICKET_INCLUSIVE_TAX: "内消費税",
  CREDIT_CARD_SWITCH: "クレジットカード切り替え",
  CREDIT_CARD_SWITCH_EXECUTE: "クレジットカードを切り替える",
  CREDIT_CARD_SELECT: "支払い方法を選択してください。",
  CREDIT_CARD_REGISTERER: "登録",
  CREDIT_CARD_CHANGE_FAILED: "クレジットカードを変更できませんでした。",
  CREDIT_CARD_RECOMMEND_OTHER_PAYMENT_METHOD: "別の支払い方法をご利用される方は上記から登録してください。",
  CREDIT_CARD_CURRENT_PAYMENT_METHOD: "現在の支払い方法",
  CREDIT_CARD_DELETE_SUCCESS: "カード情報を削除しました",

  // チケット購入: チケット
  PURCHASE_TICKETADDITIONAL: "その他のチケット",

  // チケット購入: チケット選択
  PURCHASE_TICKET_TIME: "時間",
  PURCHASE_TICKET_NOT_SELECTED: "未選択",
  PURCHASE_TICKET_FEE: "料金",
  PURCHASE_TICKET_AVAILABLE_PEOPLE: "利用可能人数",
  PURCHASE_TICKET_AVAILABLE_TIME: "ご予約可能時間",
  PURCHASE_TICKET_STAY_TIME: "滞在可能時間",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS: "H時間",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS_AND_MINUTS: "H時間m分",
  PURCHASE_TICKET_DETAILS: "内容",
  PURCHASE_TICKET_TAX_INCLUDED: "（税込）",
  PURCHASE_TICKET_TO: "〜",
  PURCHASE_TICKET_TO_PEOPLE: "名",
  PURCHASE_SCHEDULE: "日程",
  PURCHASE_PAYMENT_INFO: "決済情報",
  CHOOSE_DATE: "日付を選択してください",
  SELECT_NUMBER: "枚数を選択してください",
  SELECT_TIME: "時間を選択してください",

  // チケット購入: チケット詳細
  PURCHASE_INFO: "購入情報",
  PURCHASE_TOTAL_AMOUNT: "購入金額",
  PURCHASE_DATE: "購入日時",
  PURCHASE_RETURN_TO_TICKETS: "チケット一覧へ戻る",
  CONFIRM_TICKET: "チケットを確認",
  CANCELED: "キャンセル済み",
  PURCHASED: "購入済み",
  RESERVED: "予約済み",
  COMPLETED: "終了済み",
  TICKET_DATE_FORMAT: "YYYY年MM月DD日",
  TICKET_DATETIME_FORMAT: "YYYY年MM月DD日 HH:mm",
  TICKET_YEAR_MONTH_FORMAT: "YYYY年M月",
  ENTRY_HISTORIES: "入場履歴",
  ENTRY_NOT_EXISTS: "入場履歴はまだありません",

  // チケット購入: 確認
  PURCHASE_ERROR:
    "申し訳ありませんが、購入処理に失敗しました。決済情報やネットワークの接続を確認して再度お試しください。",
  PURCHASE_CARD_REJECTED_ERROR:
    "クレジットカードを登録できませんでした。カードが拒否された可能性があります。別のカードを試してみてください。",
  PURCHASE_CARD_NETWORK_ERROR:
    "クレジットカードを登録できませんでした。ネットワークエラーが発生している可能性があります。インターネット接続を確認して再度お試しください。",
  PURCHASE_NO_SELECT_RESERVATIONFRAME_ERROR: "予約枠を選択してください。",
  PURCHASE_SEND_MAIL_ERROR: "チケットの購入が完了しましたが、メールの送信ができませんでした。",
  TICKET_CANCEL_INFO1: "キャンセルのお問い合わせは",
  TICKET_CANCEL_INFO2: "お問い合わせフォーム",
  TICKET_CANCEL_INFO3:
    "よりお願い致します。\nその際、予約番号、予約日時が必要となりますので、購入チケット一覧の各チケット詳細画面にてご確認ください。",

  // チケット購入: アンケート
  QUESTIONNAIRE_REQUEST_TITLE: "アンケートのお願い",
  QUESTIONNAIRE_REQUEST_DESCRIPTION:
    "サービスの品質改善を目的としてアンケート調査を実施することにいたしました。 今後のサービス改善のため、ご協力いただけますと幸いです。",
  QUESTIONNAIRE_SUBTITLE1_LIST6: "その他",
  QUESTIONNAIRE_TEXT_AREA_PLACEHOLDER: "その他を選択された方はこちらに詳細を記入ください。",
  SEND_QUESTIONNAIRE: "アンケートを送信",

  // 予約処理中の画面
  RESERVATION_FAIL: "予約処理に失敗しました。",
  PAYMENT_FAIL: "決済に失敗しました。",
  RESERVATION_COMPLETE: "予約完了",
  AUTH_UNKNOWN_ERROR: "認証の際に不明なエラーが発生しました。恐れ入りますが、ご購入の手続きを再度行ってください。",
  RETURN_INPUT_DETAIL: "詳細入力へ戻る",
  RESERVATION_ALREADY_PAID: "決済済みのご予約です。",
  RETURN_EVENT_DETAIL: "イベント詳細へ戻る",
  RESERVATION_PROCESSING: "予約処理中です",
};
