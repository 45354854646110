export default {
	PAGE_TITLE: `Go ATAMI利用規約`,
  TERMS_OF_SERVICE: `Go ATAMI利用規約`,
  TERMS_OF_SERVICE_CONTENT: `「Go ATAMI（Webサービス名）」は、サービス（以下「本サービス」）を提供するにあたり、次の通り利用規約（以下「本規約」）を定めます。本規約は、利用者及び登録ユーザーの方が本サービスを利用するにあたって適用されるルールとなります。本サービスを快適にご利用になるために、本規約の内容をよくお読みください。
	本サービスをご利用頂いた場合、本規約の内容を理解しており、かつ、本規約の全ての条項について承諾したものとみなします。
	なお、本規約の内容は、必要に応じて変更しますので、本サービスをご利用する際には、最新の利用規約を確認して下さい。
	
	（目的）
	第1条
	本サービスは、「熱海のイベント、観光オプショナルツアー、体験プログラムの検索・予約」を目的としたものです。一般社団法人熱海市観光協会（以下「当観光協会」）は、よりよいサービスを提供できるよう、継続的に努力いたします。
	
	（定義）
	第2条
	本規約上で使用する用語の定義は、次の通りとします。
	
	1. 本サービスとは当観光協会が運営する投稿サイト及び関連するサービスです。
	2. 本サイトとは本サービスのコンテンツが掲載されたウェブサイトをいいます。
	3. コンテンツとは当観光協会が本サイトにて提供したもの、または投稿情報を含む情報・画像等の総称です
	4. 利用者とは本サイトを閲覧する全ての方をいいます。
	5. 登録ユーザーとは本サイトのユーザー登録が完了した方をいいます。
	6. 投稿情報とは登録ユーザーが投稿等により掲載した文章、文字列、画像、コメント等の情報の総称です。
	7. 登録情報とは登録ユーザーが本サイトにて登録した投稿情報以外の情報の総称です。
	
	（本規約の範囲と変更）
	第3条
	1. 本規約は、本サービスの提供条件及び本サービスの利用に関する当観光協会と本利用者との間の権利義務関係を定めることを目的とし、本利用者と当観光協会との間の本サービスの利用に関わる一切の関係に適用されます。
	2. 当観光協会は、本利用者の承諾を得ることなく、当観光協会が適当と判断する方法で本利用者に通知することにより、本規約を変更できるものとします。
	(1). 当観光協会は本利用規約の変更にあたり、変更後の本利用規約の効力発生日の前に相当な期間をもって、本利用規約を変更する旨及び変更後の本利用規約の内容とその効力発生日を当観光協会のウェブサイトに掲示し、またはお客さまに電子メールで通知します。
	(2). 当観光協会がお客さまに変更後の本利用規約の内容を通知し、変更後の本利用規約の効力発生日以降にお客さまが本サービスを利用した場合、お客さまは本利用規約の変更に同意したものとみなします。
	3. 当観光協会が本サービス用サイト上で掲載する本サービスの利用に関するルールは、そのルールの名称を問わず、本規約の一部を構成するものとします。
	4. 本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが矛盾・抵触する場合は、当該説明等の規定を優先させる旨の特段の定めがない限り、本規約の規定が優先して適用されるものとします。
	
	（契約期間）
	第4条
	本サービスは、登録ユーザーが退会手続を完了した場合に契約を終了します。登録ユーザーは所定の登録抹消手続を行うことにより、いつでも本サービスの退会することができます。但し、利用者及び登録ユーザーが契約解除の事由となる行為を行ったときは、当観光協会は即時に契約解除できるものとします。
	
	（サービスの利用）
	第5条
	利用者及び登録ユーザーは、本サービスを利用した時点で、利用規約に有効に同意し、当観光協会との間に本サービス利用規約を内容とする利用契約が成立することを承諾するものとします。
	
	（利用料金）
	第6条
	1. 利用者及び登録ユーザは、本サービスの利用に関して、利用料金の負担はありません。
	2. 当観光協会は、経済情勢の変動等により、随時料金規定を変更できるものとします。
	
	（外部委託）
	第7条
	当観光協会は、クレジット決済代行など、必要に応じて業務の全部または一部を外部委託することができるものとします。
	
	（登録）
	第8条
	1. 本サービスのうち当観光協会所定の機能（以下「所定機能」といいます。）の利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当観光協会の定める一定の情報（以下「登録事項」といいます。）を当観光協会の定める方法で当観光協会に提供し、当観光協会の定める手続に従って、当観光協会に対し、所定機能の利用を申請するものとします。なお、登録は必ず登録希望者本人が行い、かつ、登録希望者は、登録後を含め、自身に関する真実で正確な情報を当観光協会に提供しなければなりません。
	2. 入会の申込をした場合、当観光協会は、当観光協会の基準に従って、登録希望者の登録の可否を判断し、当観光協会が登録を認める場合にはその旨を登録希望者に通知し、この通知により登録希望者の登録ユーザーとしての登録は完了したものとします。
	3. 登録ユーザーは、登録した電話番号等に対して、当観光協会から、本サービスその他当観光協会のサービスに関するご案内、その他マーケティング等の目的でSMSその他電子メール等が配信されることをあらかじめ承諾するものとします。
	4. 登録希望者が次の各号のいずれかに該当する場合、当観光協会の判断により入会申込を承諾しないことがあります。この場合、当観光協会は拒絶の理由に関し一切の説明義務及び損害賠償義務を負いません。
	(1). 登録希望者が、申込みに不備（虚偽、誤記、記入漏れを含むが、これらに限りません。）がある場合
	(2). 登録希望者が過去に本規約違反、当観光協会との契約違反等をしたことにより、利用者登録の抹消、登録ユーザー資格の利用停止などの措置が行われたことがある場合
	(3). 登録希望者が、不正な手段をもって登録を行っていると当観光協会が判断した場合
	(4). 登録希望者が暴力団等反社会的勢力と関係を有し、又は公序良俗に反する事業を自ら行っている場合
	(5). 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
	(6). 既に本サービスの登録ユーザーとなっている場合
	(7). 第14条各号に該当しうる不適当な目的で本サービスを利用しようとする場合
	(8). その他当観光協会が不適切と判断した場合
	
	（登録情報の変更）
	第9条
	1. 登録情報に変更が発生した場合は、速やかに本サービス上で所定の変更手続を行う方法により、登録内容の変更届出を行うものとします。登録内容の変更がなされなかったことにより生じた損害については、全て当該登録ユーザーが負うものとし、当観光協会は一切の責任を負いません。
	2. 登録ユーザーに対する当観光協会からの通知は、通知時において、本サービスに登録されている登録ユーザーのメールアドレスにメールを送信することをもって通知したものとみなします。
	3. 前項の場合に、登録ユーザーが当観光協会からの通知を受領できなかったことで、登録ユーザーに損害が生じたとしても、当観光協会は一切の責任を負いません。
	4. 当観光協会は、登録ユーザーの本人確認及び登録情報の内容が真実であるかの確認をいつでもすることができるものとし、登録ユーザーは、当観光協会の求めに応じて、当該確認に必要な情報を書面等により当観光協会に提供するものとします。
	
	（退会）
	第10条
	1. 登録ユーザーが退会を希望する場合、登録ユーザーは当観光協会所定の方法により退会の手続を行ってください。
	2. 当観光協会は、登録ユーザーが次の各号のいずれか一つに該当する場合、当観光協会の判断により強制的に退会いただきます。
	(1). 申込みに不備（虚偽、誤記、記入漏れを含むが、これらに限りません。）があることが明らかとなった場合
	(2). 本規約または当観光協会の定めるその他の利用規約等に違反した場合
	(3). その他当観光協会が不適切と判断した場合
	3. 退会時に登録ユーザーが当観光協会に対する負債及び義務があるときは、登録ユーザーはこの支払義務を退会後も引き続き負うものとします。
	
	(本サービスを通じた契約の締結又は管理等)
	第11条
	1. 登録ユーザーは、本サービス上で、当観光協会の定める手続に従って、売買契約等の締結の申込みをすることができます。
	2. 登録ユーザーは、前項の申込みに際して、登録ユーザーの氏名、連絡先、その他登録ユーザーに関する情報を当観光協会取引先に送信されることに同意するものとします。
	3. 登録ユーザーが所定の操作を行わないことその他の事由により、売買契約等について確定しない場合又は客観的事実と齟齬が生じる場合、当観光協会が確認した事実その他の事情に基づいて、登録ユーザーに損害又はトラブル等が生じた場合であっても、当観光協会は責任を負いません。
	4. 登録ユーザーは、売買契約に関する事項、苦情の処理に関する事項の明示が電子メール等の送信等の電磁的方法により行われることを希望し同意するものとします。
	
	（遵守事項）
	第12条
	1. 登録ユーザーは、IDを第三者に譲渡、質入、貸与することはできません。
	2. 登録ユーザーは、本サービスを利用して行う自らの行為について一切の責任を負います。
	3. 登録ユーザーが投稿した情報の出所等に関する責任は、登録ユーザー自身にあります。
	当観光協会は投稿情報を保存する義務がありません。必要な投稿情報のバックアップは登録ユーザー自身でおこなってください。
	4. 登録ユーザーは、登録情報を偽ってはいけません。また他人の情報を許可なく勝手に登録することも禁止します。
	
	（禁止行為）
	第13条
	1. 本サービスの利用に際し、当観光協会は登録ユーザーに対し、次に掲げる行為を禁止します。違反した場合、強制退会、利用停止、掲載情報の削除等、当観光協会は必要な措置を取ることができます。
	
	(1)支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合
	(2)当観光協会または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為
	(3)当観光協会または第三者の知的財産権を侵害する行為
	(4)当観光協会または第三者のプライバシー権を侵害する行為
	(5)当観光協会または第三者の個人情報を、事前の許諾なく開示する行為
	(6)当観光協会または第三者の財産を侵害する行為、または侵害する恐れのある行為
	(7)当観光協会または第三者に経済的損害を与える行為
	(8)当観光協会または第三者に対する脅迫的な行為
	(9)IDの利用を停止された者に代わってIDを取得する行為
	(10)本サービスの運営及びシステムに支障を与える行為
	(11)わいせつ、児童ポルノ、品性を欠く内容と当観光協会が判断する投稿を行うこと
	(12)虚偽の情報を投稿すること
	(13)本サイトの趣旨と関係のない画像、文章等の投稿を行うこと
	(14)同一または類似の投稿を行うこと
	(15)法令に違反する投稿を行うこと
	(16)民族的・人種的差別につながる投稿を行うこと
	(17)利用者に嫌悪感を与える投稿を行うこと
	(18)本サービスのネットワーク又はシステム等に過度な負荷をかける行為
	(19)本サービスの運営を妨害するおそれのある行為
	(20)当観光協会のネットワーク又はシステム等に不正にアクセスし、又は不正なアクセスを試みる行為
	(21)第三者に成りすます行為
	(22)本サービスの他の利用者のログイン情報を利用する行為
	(23)当観光協会が事前に許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為
	(24)本サービスの他の利用者の情報の収集
	(25)本サービスが提供する機能を不正に設定、利用、操作等する行為
	(26)雇用契約に基づく労働時に得た秘密情報の開示又は漏えい
	(27)当観光協会、本サービスの他の利用者又はその他の第三者に不利益、損害、不快感を与える行為
	(28)反社会的勢力等への利益供与
	(29)面識のない異性との出会いを目的とした行為
	(30)前各号の行為を直接又は間接に惹起し、又は容易にする行為
	(31)上記の他、当観光協会が不適切と判断する行為
	
	2. 当観光協会は、本サービスにおける登録ユーザーによる行為が前項各号のいずれかに該当し、又は該当するおそれがあると当観光協会が判断した場合には、登録ユーザーに事前に通知することなく、以下の各号に基づく措置を講ずることができるものとします。当観光協会は、本項に基づき当観光協会が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
	(1)違反行為を停止するよう要求すること
	(2)違反行為の対象となっている内容の削除、違反行為の差止
	(3)本サービスの利用を停止すること
	(4)その他、当観光協会が必要とする処分を行うこと
	(5)前各号の措置を講じたことについて、当観光協会が適当と認める方法において公表すること
	
	(登録抹消等)
	第14条
	1. 当観光協会は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、投稿データを削除し若しくは当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーとしての登録を抹消することができます。
	(1). 本規約のいずれかの条項に違反した場合
	(2). キャンセルの回数が当観光協会規定の回数を上回る場合
	(3). 登録事項に虚偽の事実があることが判明した場合
	(4). 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合
	(5). 6ヶ月以上本サービスの利用がない場合
	(6). 当観光協会からの返信を求める問合せに対して何らの返信がされない場合
	(7). 第8条第4項各号に該当する場合
	(8). その他、当観光協会が本サービスの利用、登録ユーザーとしての登録の継続を適当でないと判断した場合
	2. 前項各号のいずれかの事由に該当した場合、登録ユーザーは、当観光協会に対して負っている債務について当然に期限の利益を失い、直ちに当観光協会に対して全ての債務の支払を行わなければなりません。
	3. 当観光協会は、本条に基づき当観光協会が行った行為により登録ユーザーに生じた損害について責任を負いません。
	
	（利用環境整備）
	第15条
	1. 登録ユーザーは、本プラットフォーム及び本サービスを利用するために必要な通信機器、ソフトウェアその他これらに付随して必要となるすべての機器を、自己の費用と責任において準備し、利用可能な状態に置くものとします。本プラットフォーム及び本サービスのご利用にあたって、登録ユーザーは、自己の費用と責任において選択し、電気通信サービス又は電気通信回線を経由してインターネットに接続するものとします。登録ユーザーは、自己の利用環境に応じ、コンピューター・ウィルスの感染、不正アクセス及び情報漏洩の防止等セキュリティを保持するものとします。
	2. 当観光協会は、登録ユーザーの利用環境について一切関与せず、また一切の責任を負いません。
	
	(本サービスの停止等)
	第16条
	1. 当観光協会は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断することかできるものとします。
	(1). 本サービスにかかるコンピューター・システムの点検又は保守作業を緊急に行う場合
	(2). コンピューター、通信回線等が事故により停止した場合
	(3). 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
	(4). その他、当観光協会が停止又は中断を必要と判断した場合
	2. 当観光協会は、前項に基づき当観光協会が行った措置に基づきユーザーに生じた損害について責任を負いません。
	
	（知的財産権）
	第17条
	1. 本サービスに含まれているコンテンツ、個々の情報に関する権利は当観光協会及び本サービスにコンテンツ等を提供している提携先企業に帰属しています。
	2. 本サービス又は広告の中に提供、掲載されているコンテンツは、著作権法、商標法、意匠法等の各種法令によって保護されています。
	3. 登録ユーザーは当観光協会、提携先企業、広告主企業等の事前の承諾を得た場合を除いて、本サービス若しくはソフトウェア又はそれらに含まれる内容を複製、公開、譲渡、貸与、翻訳、転売、転送、使用許諾、再利用等してはならないものとします。また、本利用者がこれらの行為によって受けた損害に関し、当観光協会は、一切の保証をしないと同時に、本利用者がこれらの行為を行った場合、損害賠償請求をすることがあります。
	4. 登録ユーザーが当観光協会に提供したすべての情報（以下「提供情報」といいます。）に関する著作権その他の権利（著作権法第27条及び第28条の権利を含みます。）は、本利用者が本サービスに対して当該情報を送信した時点で当観光協会にすべて譲渡されます。また、本利用者は、提供情報に関する著作者人格権（公表権、氏名表示権、同一性保持権）を行使しないこともあらかじめ承諾するものとします。
	5. 本利用者は提供情報の権利帰属に関して、当観光協会に対して、いかなる権利の主張及び行使も行わないものとします。
	
	（利用制限）
	第18条
	1. 当観光協会は、登録ユーザーまたは利用者が次の各号のいずれか一つに該当する場合、利用の一時停止など、本サービスの利用を制限することができるものとします。
	(1). 登録ユーザーまたは利用者の本サイト利用により、コンピュータウィルスや大量送信メールの拡散など、当観光協会または第三者に被害が及ぶおそれがあると当観光協会が判断した場合
	(2). 当観光協会が登録ユーザーと電子メールその他による連絡が不能となった場合
	(3). その他、当観光協会が利用制限をかける必要があると判断した場合
	2. 利用制限に関して、当観光協会は登録ユーザーまたは利用者その他に対して一切責任を負わないものとします。
	
	（削除及び変更権限）
	第19条
	1. 当観光協会は、登録ユーザーが次の各号のいずれか一つに該当する場合、事前通知なく投稿情報等を即時に削除し、IDの削除ならびに将来にわたる利用禁止の措置を取ることができるものとします。
	(1). 登録ユーザが本規約または別途定められた規定に違反したとき
	(2). 登録されたIDが反社会的勢力またはその構成員や関係者によって取得または使用されたとき、もしくは使用されるおそれがあると当観光協会が判断したとき
	(3). 掲載された投稿情報が登録ユーザーの保有するものでないと判明したとき
	2. 当観光協会は、削除または利用禁止に関し、該当者及び第三者に対して一切責任を負わないものとします。
	3. 当観光協会が必要と判断した場合には、登録ユーザーまたは利用者その他に通知することなく、いつでもサービス内容を変更、停止または終了することができるものとします。
	4. 当観光協会は、自らの判断により、登録ユーザーまたは利用者その他に事前通知を行うことなく、本規約の変更ならびに本サイトのコンテンツの更新、追加、変更、削除等を行うことができるものとします。
	5. 当観光協会は、サービスの内容の変更、停止、終了に関して、登録ユーザーまたは利用者その他に対して一切責任を負わないものとします。
	
	（通信の秘密）
	第20条
	1. 当観光協会は、電気通信事業法に基づき、利用者の通信の秘密を守ります。
	2. 当観光協会は、次の各号のいずれか一つに該当する場合、当該各号に定める範囲内において前項の守秘義務を負わないものとします。
	
	・刑事訴訟法または犯罪捜査のための通信傍受に関する法律の定めに基づく強制処分または裁判所の命令が行われた場合･･･当該処分または裁判所の命令の定める範囲内
	・法令に基づく行政処分が行われた場合･･･当該処分または命令の定める範囲内
	・特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律第４条に基づく開示請求の要件が満たされていると当観光協会が判断した場合･･･当該開示請求の範囲内
	・第三者の生命、身体または財産の保護のために必要があると当観光協会が判断した場合･･･第三者の生命、身体または財産の保護のために必要な範囲内
	
	3. 当観光協会は、特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律第４条第２項の定めに従い、開示するかどうかについて当該投稿情報の発信者の意見を聴くものとします。但し、当該開示の請求に係る侵害情報の発信者と連絡することができない場合その他特別の事情がある場合には、この限りではありません。
	
	（個人情報管理）
	第21条
	1. 当観光協会は、登録ユーザーが登録した個人情報、登録ユーザーが当観光協会のサービスを通じて当観光協会に提供した個人情報、その他の本利用者が当観光協会に提供したすべての情報（以下総称して「提供個人情報」といいます。）を本サービスの「プライバシーポリシー」に基づき、適切に取り扱うものとします。
	2. 当観光協会は登録ユーザーに対して、メールその他の方法により、広告を含む情報提供を行うことができるものとし、登録ユーザーは、これに同意するものとします。なお、登録ユーザーが情報提供を希望しない場合は、情報提供を行わないものとします。
	3. 当観光協会は、本サービスを通じて得た情報や本利用者から提供又は入力された情報を、情報の分析や評価をし、第三者への情報提供等のために利用することがあります。
	4. 登録ユーザーは、適切なマッチング実現のため、登録ユーザーの業務回数・時間、遅刻・早退回数、その他登録ユーザーに関する情報が当観光協会取引先に送信されることに同意するものとします。なお、原則として、氏名等、適切なマッチングのために不必要と判断する情報は除きます。
	5. 登録ユーザーは、投稿データのうち他のユーザーに公開されないメッセージ等について、トラブルの防止・解決、不正利用の防止、安全性の確保その他本サービスの運営上必要な場合に当観光協会が閲覧することに同意するものとします。
	6. 当観光協会は、ユーザーが当観光協会に提供した情報、データ、利用者情報等を、個人を特定できない形での統計的な情報として、当観光協会の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
	7 当観光協会は本サービス運営事業を他社に譲渡した場合、当該事業譲渡に伴い本規約上の地位、本規約に基づく権利及び義務並びに本利用者の登録情報、提供個人情報その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、本利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
	8. 当観光協会は、前三項、前四項の他、あらかじめ登録ユーザまたは利用者の同意を得ることなく、個人情報を第三者に提供しないものとします。但し、次の各号の場合を除きます。
	(1). 法令に基づく場合
	(2). 第三者の生命、身体または財産の保護のために必要があると当観光協会が判断した場合
	(3). 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合
	(4). 公的機関またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより、当該事務の遂行に支障を及ぼすおそれがあるとき
	
	（非保証）
	第22条
	当観光協会及び本サービスの提供に関与している企業は、次の各号について一切の保証を行いません。
	(1). コンテンツの正確性及び商品の完全性
	(2). コンテンツが第三者の権利を侵害していないこと
	(3). 本サービスが永続すること
	(4). 広告掲載企業及び商品に関する事項の信頼性または効能等を保証すること
	
	（免責）
	第23条
	1. 当観光協会は、登録ユーザーまたは利用者が本サービスを利用して投稿した情報の内容について、一切責任を負いません。
	2. 当観光協会は、登録ユーザ間の通信や活動に関与しません。争いが発生した場合、当該登録ユーザー間で解決するものとし、当観光協会はその責任を負いません。
	3. 当観光協会は、当観光協会による本サービスの提供の中断、停止、終了、利用不能又は変更、ユーザーが投稿したデータの削除又は消失、登録ユーザーの登録の抹消、本サービスの利用による登録データの消失又は機器の故障若しくは損傷、その他本サービスに関してユーザーが被った損害につき、賠償する責任を負わないものとします。ただし、当観光協会に故意又は重過失がある場合を除きます。
	4. 消費者契約法の適用その他何らかの理由により当観光協会が責任を負う場合（故意又は重過失による場合を除きます。）、当観光協会がユーザーに対して負うこととなる賠償責任は、ユーザーに現実かつ直接に生じた損害に限られるものとし、また、軽過失の場合のその上限は、金1万円とします。
	5. 当観光協会は、予期せぬ要因で本サイトの閲覧に関して障害が生じた場合、一切責任を負いません。
	6. 当観光協会は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。
	7. 当観光協会は、広告掲載企業及び決済代行会社に関する事項につき、一切の責任を負いません。
	
	（賠償額の制限）
	第24条
	1. 当観光協会は、本サービス利用により生じた一切の損害につき、その賠償義務を負いません。但し、当観光協会の故意または重大な過失により登録ユーザーまたは利用者その他の第三者に損害を与えた場合は、該当する者が直接被った損害額の実費を上限として損害賠償を行うことがあります。
	2. 当観光協会は、登録ユーザーまたは利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当観光協会が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。
	
	（他のサービスとの関係）
	第25条
	当観光協会が提供する本サービスその他のサービスに本規約以外の規定がある場合は、当該規定も適用されるものとします。本規約と相反する記述がある場合は、当該サービスの規定を優先適用します。
	
	（通知方法）
	第26条
	1. 登録ユーザーへの連絡が必要であると当観光協会が判断した場合には、当観光協会に届けているメールアドレスを用いて通知いたします。
	2. 利用者または登録ユーザーが、当観光協会に対し通知が必要であると判断した場合には、原則メールにて連絡を行うものとします。
	3. 広告商品に関するお問い合わせは、当該商品を提供する事業者様宛に直接お願いします。
	
	（不可抗力）
	第27条
	天災、洪水、津波、台風、地震、疫病その他の伝染病、戦争、テロ、火災または政府機関の行政指導による規制など、当観光協会の合理的支配の及ばない状況で、直接または間接的に生じた本規約上の義務の不履行や履行遅滞につき、当観光協会は、登録ユーザーまたは利用者その他の第三者に一切責任を負いません。
	
	（準拠法）
	第28条
	本規約の準拠法は日本法とします。
	
	（裁判管轄）
	第29条
	本規約に関連して当観光協会と登録ユーザまたは利用者との間で生じた紛争については、当観光協会の所在地を管轄する裁判所を第一審の合意管轄裁判所とします。
	
	
	
	一般社団法人熱海市観光協会 
	〒413-0014 静岡県熱海市渚町2018ｰ8
	電話：0557-85-2222`,
};
