import { SVGProps } from "react";

export const SVGIconPaginationLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M9.80894 1.4001C9.97341 1.4001 10.1379 1.46588 10.2694 1.59745C10.5326 1.8606 10.5326 2.27176 10.2694 2.5349L5.81247 6.99187L10.2694 11.4653C10.5326 11.7284 10.5326 12.1396 10.2694 12.4027C10.0063 12.6659 9.59514 12.6659 9.332 12.4027L4.39808 7.46882C4.26651 7.33725 4.20072 7.17278 4.20072 7.00832C4.20072 6.82741 4.26651 6.66295 4.39808 6.54782L9.332 1.6139C9.48001 1.46588 9.64448 1.4001 9.80894 1.4001Z"
        fill="black"
      />
    </svg>
  );
};
