export default {
  // メールアドレス変更
  PAGE_TITLE: "Email Address Change",
  COMPLETE_PAGE_TITLE: "Email Address Change Completed",
  SENT_MAIL_PAGE_TITLE: "Sent Verification Email",
  CHANGE_MAIL_ADDRESS_TITLE: "Email Address Change",
  CHANGE_MAIL_ADDRESS_DESCRIPTION:
    "We have sent you a verification email.\nPlease click the verification link in the email to complete the email address change.",
  CHANGE_MAIL_ADDRESS_SUBMIT_ERROR:
    "An unknown error occurred during the email address change process. Please wait for some time and try again.",
  CHANGE_MAIL_ADDRESS_COMPLETE_TITLE: "Email Address Change Completed",
  CHANGE_MAIL_ADDRESS_COMPLETE_DESCRIPTION:
    "Your email address has been successfully changed. \nPlease continue to enjoy GoATAMI.",
  CHANGE_MAIL_ADDRESS_ERROR_DESCRIPTION:
    "Please verify the email address you entered and try entering your email address again.",
  CHANGE_MAIL_ADDRESS_ERROR_FIREBASE_USER_NOT_FOUND:
    "No user was found. Please try again from the email address change process.",
  CHANGE_MAIL_ADDRESS_ERROR_AUTH_LINK_EXPIRED:
    "The validity period has expired. Please try again from the email address change process.",
};
