import { SVGProps } from "react";

export const SvgIconAccessLine = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1000" height="16" viewBox="0 0 1000 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <line y1="7.5" x2="1000" y2="7.5" stroke="#DDDDDD" />
    <line x1="116.5" y1="2.18557e-08" x2="116.5" y2="16" stroke="#DDDDDD" />
    <line x1="132.5" y1="2.18557e-08" x2="132.5" y2="16" stroke="#DDDDDD" />
    <line x1="148.5" y1="2.18557e-08" x2="148.5" y2="16" stroke="#DDDDDD" />
    <line x1="164.5" y1="2.18557e-08" x2="164.5" y2="16" stroke="#DDDDDD" />
    <line x1="180.5" y1="2.18557e-08" x2="180.5" y2="16" stroke="#DDDDDD" />
    <line x1="196.5" y1="2.18557e-08" x2="196.5" y2="16" stroke="#DDDDDD" />
    <line x1="212.5" y1="2.18557e-08" x2="212.5" y2="16" stroke="#DDDDDD" />
    <line x1="228.5" y1="2.18557e-08" x2="228.5" y2="16" stroke="#DDDDDD" />
    <line x1="244.5" y1="2.18557e-08" x2="244.5" y2="16" stroke="#DDDDDD" />
    <line x1="260.5" y1="2.18557e-08" x2="260.5" y2="16" stroke="#DDDDDD" />
    <line x1="276.5" y1="2.18557e-08" x2="276.5" y2="16" stroke="#DDDDDD" />
    <line x1="292.5" y1="2.18557e-08" x2="292.5" y2="16" stroke="#DDDDDD" />
    <line x1="308.5" y1="2.18557e-08" x2="308.5" y2="16" stroke="#DDDDDD" />
    <line x1="324.5" y1="2.18557e-08" x2="324.5" y2="16" stroke="#DDDDDD" />
    <line x1="340.5" y1="2.18557e-08" x2="340.5" y2="16" stroke="#DDDDDD" />
    <line x1="356.5" y1="2.18557e-08" x2="356.5" y2="16" stroke="#DDDDDD" />
    <line x1="372.5" y1="2.18557e-08" x2="372.5" y2="16" stroke="#DDDDDD" />
    <line x1="388.5" y1="2.18557e-08" x2="388.5" y2="16" stroke="#DDDDDD" />
    <line x1="404.5" y1="2.18557e-08" x2="404.5" y2="16" stroke="#DDDDDD" />
    <line x1="420.5" y1="2.18557e-08" x2="420.5" y2="16" stroke="#DDDDDD" />
    <line x1="436.5" y1="2.18557e-08" x2="436.5" y2="16" stroke="#DDDDDD" />
    <line x1="452.5" y1="2.18557e-08" x2="452.5" y2="16" stroke="#DDDDDD" />
    <line x1="468.5" y1="2.18557e-08" x2="468.5" y2="16" stroke="#DDDDDD" />
    <line x1="484.5" y1="2.18557e-08" x2="484.5" y2="16" stroke="#DDDDDD" />
    <line x1="500.5" y1="2.18557e-08" x2="500.5" y2="16" stroke="#DDDDDD" />
    <line x1="516.5" y1="2.18557e-08" x2="516.5" y2="16" stroke="#DDDDDD" />
    <line x1="532.5" y1="2.18557e-08" x2="532.5" y2="16" stroke="#DDDDDD" />
    <line x1="548.5" y1="2.18557e-08" x2="548.5" y2="16" stroke="#DDDDDD" />
    <line x1="564.5" y1="2.18557e-08" x2="564.5" y2="16" stroke="#DDDDDD" />
    <line x1="580.5" y1="2.18557e-08" x2="580.5" y2="16" stroke="#DDDDDD" />
    <line x1="596.5" y1="2.18557e-08" x2="596.5" y2="16" stroke="#DDDDDD" />
    <line x1="612.5" y1="2.18557e-08" x2="612.5" y2="16" stroke="#DDDDDD" />
    <line x1="628.5" y1="2.18557e-08" x2="628.5" y2="16" stroke="#DDDDDD" />
    <line x1="644.5" y1="2.18557e-08" x2="644.5" y2="16" stroke="#DDDDDD" />
    <line x1="660.5" y1="2.18557e-08" x2="660.5" y2="16" stroke="#DDDDDD" />
    <line x1="676.5" y1="2.18557e-08" x2="676.5" y2="16" stroke="#DDDDDD" />
    <line x1="692.5" y1="2.18557e-08" x2="692.5" y2="16" stroke="#DDDDDD" />
    <line x1="708.5" y1="2.18557e-08" x2="708.5" y2="16" stroke="#DDDDDD" />
    <line x1="724.5" y1="2.18557e-08" x2="724.5" y2="16" stroke="#DDDDDD" />
    <line x1="740.5" y1="2.18557e-08" x2="740.5" y2="16" stroke="#DDDDDD" />
    <line x1="756.5" y1="2.18557e-08" x2="756.5" y2="16" stroke="#DDDDDD" />
    <line x1="772.5" y1="2.18557e-08" x2="772.5" y2="16" stroke="#DDDDDD" />
    <line x1="788.5" y1="2.18557e-08" x2="788.5" y2="16" stroke="#DDDDDD" />
    <line x1="804.5" y1="2.18557e-08" x2="804.5" y2="16" stroke="#DDDDDD" />
    <line x1="820.5" y1="2.18557e-08" x2="820.5" y2="16" stroke="#DDDDDD" />
    <line x1="836.5" y1="2.18557e-08" x2="836.5" y2="16" stroke="#DDDDDD" />
    <line x1="852.5" y1="2.18557e-08" x2="852.5" y2="16" stroke="#DDDDDD" />
    <line x1="868.5" y1="2.18557e-08" x2="868.5" y2="16" stroke="#DDDDDD" />
    <line x1="884.5" y1="2.18557e-08" x2="884.5" y2="16" stroke="#DDDDDD" />
  </svg>
);
