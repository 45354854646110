export default {
  // 購入チケット一覧
  TICKET_MONTH: "MMMM",
  TICKET_DAY: "D",
  TICKET_DATE_TIME_START: "dddd HH:mm",
  TICKET_DATE_TIME_END: "HH:mm",
  TICKET_TOTAL: "Total : ",
  NO_TICKET_TITLE: "No tickets purchased.",
  TICKET_CANCEL_POLICY: "Cancellation up to 5 days prior is 50%, 4 days prior or later is 100%",
  TICKET_NUMBER: "Ticket Number",
  TICKET_CANCEL_HISTORY: "Cancellation History",
  TICKET_REFUND_AMOUNT: "Refund Amount",
  TICKET_CANCEL_COMPLETE: "Completed",
  TICKET_CANCEL_COMPLETED_NOTIFICATION: "Cancellation has been completed",
  TICKET_CANCEL_CONFIRM: "Confirm",
  TICKET_CANCEL_OK1: "May I ",
  TICKET_CANCEL_OK2: "cancel",
  TICKET_CANCEL_OK3: " this ticket?",
  TICKET_CANCEL_Fee50: "Current cancellation fee is 50%",
  TICKET_CANCEL_Fee100: "Current cancellation fee is 100%",
  TICKET_CANCEL_COMPLETE_CLOSE: "Close",
  TICKET: "Ticket",
  TICKET_CANCEL_WARNING: "Please note that this action cannot be undone.",
  TICKET_CANCEL_EXECUTE: "Execute Cancellation",
  TICKET_CANCEL_FAILED: "Cancellation Failed.",
};
