export default {
  PAGE_TITLE: `プライバシーポリシー`,
  PRIVACY_POLICY: `個人情報保護方針`,
  PRIVACY_POLICY_CONTENT: `一般社団法人熱海市観光協会（以下「当観光協会」）は、個人情報の適切な取扱いに規範を示すべき立場にあるとの理念の下、個人情報を適切に取り扱うことの重要性を認識し、個人情報保護方針を定め、役員及び従業員が一体となってこれを遵守し、もって個人情報の保護及び個人の権利利益の保護に万全を尽くしてまいります。

	1. 個人情報を取得するに当たっては、その利用目的をできる限り特定し、その目的の達成に必要な限度において個人情報を取得致します。
	2. 個人情報を、本人から直接、書面によって取得する場合には、当観光協会名、個人情報保護管理者名及び連絡先、利用目的等をお知らせした上で、必要な範囲で個人情報を取得致します。
	3. 個人情報の利用は、本人が同意を与えた利用目的の範囲内で行います。また、目的外利用を行わないため、必要な対策を講じる手順を確立し、実施致します。
	4. 保有する個人情報を適切な方法で管理し、本人の同意なしに第三者に開示・提供致しません。
	5. 保有する個人情報を利用目的に応じた必要な範囲内において、正確、かつ、最新の状態で管理し、個人情報の漏えい、滅失又は毀損などのおそれに対して、合理的な安全対策を講じ、予防並びに是正に努めます。
	6. 個人情報の処理を外部へ委託する場合は、漏えいや第三者への提供を行わない等を契約により義務づけ、委託先に対する適切な管理を実施致します。
	7. 保有する個人情報についての苦情・相談は、当観光協会の問合せ窓口に連絡頂くことにより、これに対応致します。
	8. 個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
	9. 個人情報保護マネジメントシステムを定め、これを定期的に見直し、継続的に改善致します。
	
	制定日：2023年 10月 23日
	一般社団法人熱海市観光協会
	代表理事 中島 幹雄
	
	
	<個人情報保護方針についてのお問合せ先>
	
	
	一般社団法人熱海市観光協会 
	〒413-0014 静岡県熱海市渚町2018ｰ8
	電話：0557-85-2222
	`,
  POLICY_FOR_HANDLING: `個人情報の取扱いについての方針`,
  POLICY_FOR_HANDLING_CONTENT: `一般社団法人熱海市観光協会（以下「当観光協会」）は、本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。

	第1条（個人情報）
	「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
	
	第2条（個人情報の収集方法）
	当観光協会は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、クレジットカード番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当観光協会の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
	
	第3条（個人情報を収集・利用する目的）
	当観光協会が個人情報を収集・利用する目的は、以下のとおりです。
	
	当観光協会サービスの提供・運営のため
	ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
	ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当観光協会が提供する他のサービスの案内メールを送付するため
	メンテナンスや重要なお知らせなど必要に応じたご連絡のため
	利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
	ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
	有料サービスにおいて、ユーザーに利用料金を請求するため
	上記の利用目的に付随する目的
	
	第4条（利用目的の変更）
	1. 当観光協会は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
	2. 利用目的の変更を行った場合には、変更後の目的について、当観光協会所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
	
	第5条（個人情報の第三者提供）
	1. 当観光協会は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
	
	人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
	公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
	国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
	予め次の事項を告知あるいは公表し、かつ当観光協会が個人情報保護委員会に届出をしたとき
	利用目的に第三者への提供を含むこと
	第三者に提供されるデータの項目
	第三者への提供の手段または方法
	本人の求めに応じて個人情報の第三者への提供を停止すること
	本人の求めを受け付ける方法
	2. 前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
	当観光協会が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
	合併その他の事由による事業の承継に伴って個人情報が提供される場合
	個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
	
	第6条（個人情報の開示）
	1. 当観光協会は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1,000円の手数料を申し受けます。
	本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
	当観光協会の業務の適正な実施に著しい支障を及ぼすおそれがある場合
	その他法令に違反することとなる場合
	2. 前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
	
	第7条（個人情報の訂正および削除）
	1. ユーザーは、当観光協会の保有する自己の個人情報が誤った情報である場合には、当観光協会が定める手続きにより、当観光協会に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
	2. 当観光協会は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。
	3. 当観光協会は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
	
	第8条（個人情報の利用停止等）
	1. 当観光協会は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
	2. 前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。
	3. 当観光協会は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
	4. 前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
	
	第9条（プライバシーポリシーの変更）
	1. 本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
	2. 当観光協会が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
	
	第10条（お問い合わせ窓口）
	本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
	
	一般社団法人熱海市観光協会 
	〒413-0014 静岡県熱海市渚町2018ｰ8
	電話：0557-85-2222
	`,
};
