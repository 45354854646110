import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class EventApi extends BaseApi {
    async getEventById(
        parameters: APIPaths["/app/event/{id}"]["get"]["parameters"]["path"],
    ): Promise<APIPaths["/app/event/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
        return (await this.get(`/app/event/${parameters.id}`)).data;
    }
    async getEventList(
        parameters: APIPaths["/app/event/list"]["get"]["parameters"]["query"],
    ): Promise<APIPaths["/app/event/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
        return (
            await this.get("/app/event/list", {
                params: parameters,
            })
        ).data;
    }
}
