export default {
  // お問い合わせ
  PAGE_TITLE: "查询",
  CONFIRM_PAGE_TITLE: "确认查询内容",
  COMPLETE_PAGE_TITLE: "感谢您的查询",
  CONTACT: "查询",
  THANKS_MESSAGE_FOR_CONTACT:
    "非常感谢您提供的意见或问题。虽然我们无法单独回复，但我们会将您的反馈传达给致力于改进服务的团队。",
  YAMADA_TAROU: "山田太郎",
  CONTENT_OF_CONTACT: "查询内容",
  THIS_IS_CONTENT_OF_CONTACT: "这是联系内容。",
  CONTACT_CONFIRM: "确认询价详情",
  THANKS_FOR_CONTACT: "感谢您的查询",
  RESPONSE_THREE_BUSINESS_DAYS: "感谢您的联系。我们会在3个工作日内回复，请稍候片刻",
  CONTACT_CANCEL_INFO1: "取消咨询需要申请号码和预约日期时间，请在",
  CONTACT_CANCEL_INFO2: "中的每张票的详细页面确认。",
};
