import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class AuthApi extends BaseApi {
  async postVerifyNewEmail(
    requestBody: APIPaths["/app/auth/verify-new-email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/verify-new-email"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/verify-new-email", requestBody)).data;
  }
  async postSignup(
    requestBody: APIPaths["/app/auth/signup"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/signup"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/signup", requestBody)).data;
  }
  async postLogin(
    requestBody: APIPaths["/app/auth/login"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/login"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/login", requestBody)).data;
  }
  async postRenewToken(): Promise<
    APIPaths["/app/auth/renew-token"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post("/app/auth/renew-token")).data;
  }
  async postResetPassword(
    requestBody: APIPaths["/app/auth/reset-password"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/reset-password"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/app/auth/reset-password", requestBody)).data;
  }
}
