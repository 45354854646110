import auths from "./auth/auth-zh-tw";
import changeMailAddress from "./change-mail-address/page-change-mail-address-zh-tw";
import changePasswords from "./change-password/change-password-zh-tw";
import commons from "./common/common-zh-tw";
import companyInfos from "./company-info/company-info-zh-tw";
import contacts from "./contact/contact-zh-tw";
import pageTops from "./event/event-zh-tw";
import footers from "./footer/footer-zh-tw";
import mailorderLaws from "./mailorderlaws/mailorderlaw-zh-tw";
import purchaseLists from "./parchase-list/parchase-list-zh-tw";
import privacyPolicies from "./privacy-policies/privacy-policy-zh-tw";
import purchaseTickets from "./purchase-ticket/purchase-ticket-zh-tw";
import questionnaireAnswer from "./questionnaire-answer/questionnaire-answer-zh-tw";
import questionnaire from "./questionnaire/questionnaire-zh-tw";
import resetPasswords from "./reset-password/reset-password-zh-tw";
import pageSpecialFeatures from "./special-feature/special-feature-zh-tw";
import termsOfServices from "./terms-of-services/terms-of-service-zh-tw";
import validations from "./validation/validation-zh-tw";

const zhHantaiji = {
  // 共通
  COMMON: commons,

  //バリデーション
  VALIDATION: validations,

  // サインアップ ログイン
  AUTH: auths,

  // パスワードリセット: 認証メール送信,新規パスワード入力
  RESET_PASSWORD: resetPasswords,

  // パスワード再設定
  CHANGE_PASSWORD: changePasswords,

  // メールアドレス変更
  CHANGE_MAIL_ADDRESS: changeMailAddress,

  // トップ: 開催情報,チケット販売開始通知,運営情報,おすすめポイント,マップ,留意事項,同じ時期にやっているその他イベント
  PAGE_TOP: pageTops,

  // 購入チケット一覧
  PURCHASE_LIST: purchaseLists,

  // トップ: 特集ページ
  PAGE_SPECIAL_FEATURE: pageSpecialFeatures,

  // チケット購入: 支払い,チケット,チケット選択,アンケート
  PURCHASE_TICKET: purchaseTickets,

  // アンケート
  QUESTIONNAIRE: questionnaire,

  // アンケート完了
  QUESTION_ANSWER: questionnaireAnswer,

  // お問い合わせ
  PAGE_CONTACT: contacts,

  // 企業情報
  COMPANY_INFO: companyInfos,

  // プライバシーポリシー
  PRIVACY_POLICIES: privacyPolicies,

  // 利用規約
  TERMS_OF_SERVICES: termsOfServices,

  // 特別商取引法
  MAILORDERLAW_DETAIL: mailorderLaws,

  // フッター
  FOOTER: footers,
};

export default zhHantaiji;
