export default {
  PAGE_TITLE: "熱海チケットサイト",
  SUB_TITLE1: "日本一早咲きの桜と",
  SUB_TITLE2: "昭和レトロな街なみを楽しむ",
  SUB_TITLE3: undefined,
  SPACIAL_FEATURE_TITLE1: "東京からのアクセス抜群！",
  SPACIAL_FEATURE_TITLE2: "人気の温泉地熱海で昭和レトロな世界を満喫。",
  SPACIAL_FEATURE_TITLE3: "日本で最も早咲きの桜を楽しもう！",
  SPACIAL_FEATURE_TEXT:
    "日本有数の温泉地として名高い熱海は、昭和レトロな町並みとともに日本で最も早く冬に桜が咲くことでも有名です。冬に咲く「あたみ桜」、「昭和レトロな街並み」、「起雲閣」、「熱海芸妓見番」といった観光地をめぐる「あたみ桜 夜会」イベントを開催します。\n\n特に糸川沿いに立ち並ぶ桜は見応え抜群。イベント期間中、糸川沿いの桜をライトアップするだけでなく、提灯モニュメントによる演出で冬の夜空に「日本で最も早咲きのあたみ桜」を幻想的に映し出します。日本で最も早い、この時期には熱海でしかできないお花見に是非お越し下さい。\nライトアップに合わせて、文豪が逗留したことで有名な「起雲閣」や、古き日本文化を体験できる「熱海芸妓見番」でも特別イベントも開催！",
  EVENT_LIST: "イベント一覧",
  TITLE_EVENT_LIST1: "熱海芸妓見番",
  TITLE_EVENT_LIST2: "糸川遊歩道",
  TITLE_EVENT_LIST3: "起雲閣",
  SUBTITLE_EVENT_LIST1:
    "見番では特別な2階桟敷席から芸妓の演舞を鑑賞。お寿司が提供されるプランもご用意いたしました。熱海市内で過ごせる最上の時間を是非お楽しみください。",
  SUBTITLE_EVENT_LIST2:
    "糸川での夜桜観賞では通常の桜のライトアップだけでなく、提灯モニュメントの設置、見番まで続く道のライトアップなど、このイベントだけの幻想的な風景が楽しめます。",
  SUBTITLE_EVENT_LIST3:
    "起雲閣では普段は提供していない「浴衣」または「昭和レトロ衣装」のレンタルを行い、和とモダンが融合した環境の中まるでタイムスリップしたような写真の撮影が行えます。",
  EVENT_ON_CLICK: "イベント詳細はこちら",
};
