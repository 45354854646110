export default {
  PAGE_TITLE: `Go ATAMI使用条款`,
  TERMS_OF_SERVICE: `Go ATAMI使用条款`,
  TERMS_OF_SERVICE_CONTENT: `「Go ATAMI（Web服务名称）」在提供服务（以下「本服务」）时，依据以下的使用条款（以下「本条款」）进行。本条款是适用于用户及注册用户在使用本服务时的规则。为了能够舒适地使用本服务，请仔细阅读本条款的内容。
  一旦您使用了本服务，即表示您已经理解并同意本条款的所有条款。
  另外，我们会根据需要更改本条款的内容，所以在使用本服务时，请确认最新的使用条款。
  
  （目的）
  第1条
  本服务旨在「搜索和预定热海的活动、观光选择之旅和体验计划」。一般社团法人热海市观光协会（以下「本观光协会」）将持续努力提供更好的服务。
  
  （定义）
  第2条
  在本条款中使用的术语定义如下：
  1. 本服务是指本观光协会经营的投稿网站及相关服务。
  2. 本网站是指发布本服务内容的网站。
  3. 内容是指本观光协会在本网站上提供的，或包括投稿信息的信息、图片等的总称。
  4. 用户是指浏览本网站的所有人。
  5. 注册用户是指完成本网站用户注册的人。
  6. 投稿信息是指注册用户通过投稿等发布的文章、文字、图片、评论等信息的总称。
  7. 注册信息是指注册用户在本网站上注册的除投稿信息外的信息的总称。
  
  （本条款的范围和更改）
  第3条
  1. 本条款旨在确定提供本服务的条件以及本服务的使用与本观光协会和用户之间的权利和义务，并适用于用户和本观光协会之间与本服务的使用有关的所有关系。
  2. 本观光协会可以在不获得用户同意的情况下，以本观光协会认为适当的方式通知用户，更改本条款。
      (1). 在更改本使用条款时，本观光协会将在更改后的使用条款生效日期之前，提前适当的时间，在本观光协会的网站上公告更改的内容及更改后的使用条款的内容和生效日期，或通过电子邮件通知客户。
      (2). 如果本观光协会已通知客户更改后的使用条款的内容，并且客户在更改后的使用条款生效日期之后使用了本服务，则客户将被视为同意更改的使用条款。
  3. 本观光协会在本服务网站上发布的与本服务使用有关的规则，不论其名称如何，都构成本条款的一部分。
  4. 如果本条款的内容与前项的规则或其他非本条款的本服务的说明等有矛盾或冲突，除非有特别规定优先适用该说明等的规定，否则应优先适用本条款的规定。
  
  （合同期间）
  第4条
  本服务在注册用户完成退会手续时终止合同。注册用户可以随时通过指定的注册取消手续退出本服务。但是，如果用户或注册用户进行了终止合同的行为，本观光协会可以立即终止合同。
  
  （服务的使用）
  第5条
  用户和注册用户在使用本服务时，表示有效同意使用条款，并同意与本观光协会之间建立以本服务使用条款为内容的使用合同。
  
  （使用费用）
  第6条
  1. 用户和注册用户在使用本服务时，不需要支付使用费用。
  2. 由于经济环境的变动等原因，本观光协会可以随时更改费用规定。
  
  （外部委托）
  第7条
  本观光协会可以根据需要，对业务的全部或部分进行外部委托，例如信用卡支付代理等。
  
  （注册）
  第8条
  1. 希望使用本服务中本观光协会指定的功能（以下称「指定功能」）的人（以下称「注册希望者」）应同意遵守本条款，并向本观光协会提供指定的信息（以下称「注册事项」）按照本观光协会的方法，并按照本观光协会的程序，向本观光协会申请使用指定功能。注册必须由注册希望者本人进行，且注册希望者必须向本观光协会提供真实且正确的信息。
  2. 如果申请加入，本观光协会将根据其标准判断注册希望者的注册是否可行，如果本观光协会批准注册，将通知注册希望者，此通知表示注册希望者已完成注册用户的注册。
  3. 注册用户同意，对于注册的电话号码等，本观光协会可以发送关于本服务或其他本观光协会服务的信息，或其他市场营销目的的SMS或其他电子邮件等。
  4. 如果注册希望者符合以下各项的任何一项，本观光协会可以决定不接受其加入申请。在这种情况下，本观光协会不承担任何解释义务或赔偿义务。
      (1). 注册希望者的申请有瑕疵（包括但不限于虚假、错误、遗漏）。
      (2). 注册希望者在过去由于违反本条款、与本观光协会的合同等原因，已经采取了取消用户注册、暂停注册用户资格等措施。
      (3). 本观光协会认为注册希望者使用不正当手段进行注册。
      (4). 注册希望者与暴力团伙等反社会势力有关，或自行进行违反公序良俗的业务。
      (5). 是未成年人、成年被监护人、被保护人或被辅助人，且未获得法定代理人、监护人、保护人或辅助人的同意。
      (6). 已经成为本服务的注册用户。
      (7). 试图以不适当的目的使用本服务，该目的可能符合第14条的各项。
      (8). 本观光协会认为不适当的其他情况。
  
  （登记信息的变更）
  第9条
  1. 若登记信息有所变更，应迅速在本服务上进行指定的变更手续。因未进行登记内容的变更而导致的损害，应由该注册用户承担，本观光协会不承担任何责任。
  2. 本观光协会对注册用户的通知，将视为在通知时向本服务上注册的注册用户的电子邮件地址发送电子邮件为已通知。
  3. 若因注册用户未能收到本观光协会的通知而导致的损害，本观光协会不承担任何责任。
  4. 本观光协会可以随时确认注册用户的身份以及登记信息的真实性。注册用户应根据本观光协会的要求，以书面等方式提供必要的确认信息。
  
  （退会）
  第10条
  1. 若注册用户希望退会，请按照本观光协会指定的方式进行退会手续。
  2. 若注册用户符合以下任何一项，本观光协会可决定强制其退会：
      (1) 申请中存在明显的不备（包括但不限于虚假、错误、遗漏）。
      (2) 违反本条款或本观光协会制定的其他使用条款。
      (3) 本观光协会认为不适当的其他情况。
  3. 退会时，若注册用户对本观光协会有未完成的债务或义务，注册用户应继续履行这些义务。
  
  （通过本服务缔结或管理合同等）
  第11条
  1. 注册用户可以在本服务上，按照本观光协会的程序，申请缔结销售合同等。
  2. 注册用户在进行前项的申请时，同意将其姓名、联系方式及其他相关信息发送给本观光协会的交易对象。
  3. 若因注册用户未进行指定操作或其他原因，导致销售合同等未能确定或与客观事实不符，即使因此导致注册用户受到损害或问题，本观光协会亦不承担责任。
  4. 注册用户希望并同意，关于销售合同的事项、投诉处理等的明确说明，可以通过电子邮件等电子方式进行。
  
  （遵守事项）
  第12条
  1. 注册用户不得将ID转让、质押或出借给第三方。
  2. 注册用户应对其在使用本服务时的所有行为承担全部责任。
  3. 注册用户发布的信息的来源等的责任由注册用户自己承担。本观光协会无义务保存发布的信息。注册用户应自行备份所需的发布信息。
  4. 注册用户不得虚假登记信息，也不得未经许可擅自登记他人的信息。
  
  （禁止行为）
  第13条
  1. 在使用本服务时，本观光协会禁止注册用户进行以下行为。若违反，本观光协会可以采取强制退会、使用停止、删除发布信息等必要措施：
      (1) 停止支付或无法支付，或有破产手续开始、民事再生手续开始或类似手续的申请。
      (2) 损害本观光协会或第三者的名誉、信用，或不当地差别或诽谤。
      (3) 侵犯本观光协会或第三者的知识产权。
      (4) 侵犯本观光协会或第三者的隐私权。
      (5) 未经许可公开本观光协会或第三者的个人信息。
      (6) 侵害或可能侵害本观光协会或第三者的财产。
      (7) 对本观光协会或第三者造成经济损害。
      (8) 对本观光协会或第三者进行威胁。
      (9) 代替被停止使用ID的人取得ID。
      (10) 干扰本服务的运营和系统。
      (11) 发布淫秽、儿童色情、缺乏品性的内容或本观光协会认为不适当的内容。
      (12) 发布虚假信息。
      (13) 发布与本网站目的无关的图片、文章等。
      (14) 进行相同或类似的发布。
      (15) 违反法律的发布。
      (16) 进行可能导致种族或民族差别的发布。
      (17) 进行使用户感到不悦的发布。
      (18) 对本服务的网络或系统施加过大的负担。
      (19) 可能干扰本服务运营的行为。
      (20) 不正确地访问或尝试访问本观光协会的网络或系统。
      (21) 冒充第三者。
      (22) 使用其他用户的登录信息。
      (23) 未经本观光协会许可在本服务上进行广告、宣传、劝诱或商业行为。
      (24) 收集本服务其他用户的信息。
      (25) 不正确地设置、使用、操作本服务提供的功能。
      (26) 泄露基于雇佣合同获得的秘密信息。
      (27) 对本观光协会、本服务的其他用户或其他第三者造成不利、损害或不快。
      (28) 为反社会势力等提供利益。
      (29) 为了与不熟悉的异性见面的目的。
      (30) 直接或间接引起前述行为。
      (31) 除上述之外，本观光协会认为不适当的行为。
  2. 若本观光协会认为注册用户的行为违反或可能违反上述任何一项，本观光协会可以在不事先通知注册用户的情况下采取以下措施，且不对因此对注册用户造成的损害承担任何责任：
      (1) 要求停止违反行为。
      (2) 删除违反行为的内容或停止违反行为。
      (3) 停止使用本服务。
      (4) 采取本观光协会认为必要的其他措施。
      (5) 公开宣布采取上述措施的事实。
  
  （登记注销等）
  第14条
  1. 若注册用户符合以下任何一项，本观光协会可以在不事先通知或催告的情况下，删除投稿数据，或暂时停止该注册用户使用本服务，或取消其作为注册用户的登记：
      (1) 违反本条款的任何条款。
      (2) 取消次数超过本观光协会规定的次数。
      (3) 发现登记事项中有虚假事实。
      (4) 停止支付或无法支付，或有破产手续开始、民事再生手续开始或类似手续的申请。
      (5) 超过6个月未使用本服务。
      (6) 对本观光协会的回复要求没有任何回应。
      (7) 符合第8条第4项的任何一项。
      (8) 本观光协会认为不适当继续使用本服务或作为注册用户。
  2. 若符合前项的任何一项，注册用户对本观光协会的债务将立即到期，并必须立即支付所有债务。
  3. 本观光协会不对因本条所采取的行动对注册用户造成的损害承担任何责任。
  
  （使用环境整备）
  第15条
  1. 注册用户应自行承担费用和责任，为使用本平台和本服务准备必要的通信设备、软件和其他必要设备。注册用户应自行承担费用和责任，选择通过电信服务或电信线路连接到互联网。注册用户应根据自己的使用环境，确保计算机病毒的防护、防止不正当访问和信息泄露等安全措施。
  2. 本观光协会不参与也不对注册用户的使用环境承担任何责任。
  
  （本服务的停止等）
  第16条
  1. 当观光协会在以下情况之一时，有权在不事先通知用户的情况下，停止或中断本服务的全部或部分提供：
      (1) 紧急进行本服务相关的电脑系统的检查或维护。
      (2) 由于事故导致电脑、通讯线路等停止。
      (3) 由于地震、雷击、火灾、风水灾害、停电、天灾等不可抗力原因导致无法运营本服务。
      (4) 其他当观光协会认为需要停止或中断的情况。
  2. 当观光协会基于前项所采取的措施导致用户受到损害时，不承担任何责任。
  
  （知识产权）
  第17条
  1. 本服务中的内容、各项信息的权利属于当观光协会及提供内容等的合作伙伴企业。
  2. 本服务或广告中的内容受到著作权法、商标法、设计法等各种法律的保护。
  3. 除非得到当观光协会、合作伙伴企业、广告主企业等的事先同意，注册用户不得复制、公开、转让、出借、翻译、转售、转移、授权、再利用等本服务或软件及其内容。若用户因此受到损害，当观光协会不提供任何保证，且可能要求损害赔偿。
  4. 注册用户提供给当观光协会的所有信息（以下称为「提供信息」）的著作权及其他权利（包括著作权法第27条和第28条的权利）在发送该信息至本服务时，均转移给当观光协会。此外，用户同意不行使提供信息的著作者人格权（公开权、姓名表示权、同一性保持权）。
  5. 用户不得对提供信息的权利归属向当观光协会提出或行使任何权利主张。
  
  （使用限制）
  第18条
  1. 若注册用户或使用者符合以下任何一项，当观光协会有权限制其使用本服务：
      (1) 若注册用户或使用者使用本网站可能导致电脑病毒或大量邮件的扩散，或可能对当观光协会或第三方造成损害。
      (2) 若当观光协会无法通过电子邮件或其他方式与注册用户联系。
      (3) 其他当观光协会认为需要限制使用的情况。
  2. 关于使用限制，当观光协会不对注册用户或使用者或其他人承担任何责任。
  
  （删除及变更权限）
  第19条
  1. 若注册用户符合以下任何一项，当观光协会有权在不事先通知的情况下，立即删除投稿信息等，并删除ID，并采取未来的使用禁止措施：
      (1) 注册用户违反本条款或其他规定。
      (2) 注册的ID被反社会势力或其成员或关系人取得或使用，或当观光协会认为有可能被使用。
      (3) 发现所发布的投稿信息不属于注册用户。
  2. 关于删除或使用禁止，当观光协会不对相关人士或第三方承担任何责任。
  3. 当观光协会认为有必要时，可以在不通知注册用户或使用者的情况下，随时更改、停止或终止服务。
  4. 当观光协会可以根据自己的判断，在不事先通知注册用户或使用者的情况下，更改本条款或更新、添加、更改、删除本网站的内容。
  5. 关于服务内容的更改、停止、终止，当观光协会不对注册用户或使用者或其他人承担任何责任。
  
  （通信的秘密）
  第20条
  1. 当观光协会根据电信业务法，保护用户的通信秘密。
  2. 当观光协会在以下情况之一时，不承担前项的保密义务：
      ・根据刑事诉讼法或犯罪调查中的通信监听法的规定进行强制措施或法院命令的情况...在该措施或法院命令的范围内。
      ・根据法律进行行政措施的情况...在该措施或命令的范围内。
      ・当观光协会认为已满足特定电信服务提供者的损害赔偿责任限制和发信者信息披露法第4条的要求...在该披露要求的范围内。
      ・当观光协会认为为保护第三方的生命、身体或财产是必要的...在为保护第三方的生命、身体或财产所必需的范围内。
  3. 当观光协会根据特定电信服务提供者的损害赔偿责任限制和发信者信息披露法第4条第2项的规定，决定是否披露，并应征询该投稿信息的发信者的意见。但是，如果无法与该披露要求相关的侵权信息的发信者联系，或存在其他特殊情况，则不适用此限制。
  
  （个人信息管理）
  第21条
  1. 当观光协会将注册用户注册的个人信息、通过当观光协会的服务提供的个人信息，以及其他用户提供给当观光协会的所有信息（以下统称为「提供的个人信息」）根据本服务的「隐私政策」进行适当处理。
  2. 当观光协会可以通过电子邮件或其他方式向注册用户提供包括广告在内的信息，且注册用户同意此事。但是，如果注册用户不希望提供信息，则不提供信息。
  3. 当观光协会可以使用通过本服务获得的信息或由用户提供或输入的信息进行信息分析和评估，并可能向第三方提供信息。
  4. 为了实现适当的匹配，注册用户同意其业务次数、时间、迟到和早退次数以及其他关于注册用户的信息将被发送给当观光协会的交易对象。但是，原则上，不包括姓氏等被认为不是为了适当匹配而必要的信息。
  5. 注册用户同意，对于投稿数据中不向其他用户公开的消息等，为了预防和解决问题、防止不正当使用、确保安全性以及其他运营本服务所必需的情况，当观光协会可以查看。
  6. 当观光协会可以将用户提供给当观光协会的信息、数据、用户信息等作为统计信息在不能识别个人的形式下使用和公开，且用户不得对此提出异议。
  7. 如果当观光协会将本服务的运营业务转让给其他公司，则可以随着该业务转让将本条款的地位、基于本条款的权利和义务以及用户的注册信息、提供的个人信息和其他信息转让给该业务转让的接收者，且用户事先同意此事。此外，本条所指的业务转让包括但不限于公司分割和其他所有业务转移情况。
  8. 除了前三项和前四项外，当观光协会不得在未获得注册用户或使用者的事先同意的情况下向第三方提供个人信息。但以下情况除外：
      (1) 根据法律的规定。
      (2) 当观光协会认为为了保护第三方的生命、身体或财产是必要的。
      (3) 特别需要为了提高公共卫生或促进儿童的健康成长。
      (4) 当需要协助公共机构或其受托人执行法律规定的业务，且获得本人的同意可能妨碍该业务的执行时。
  
  （非保证）
  第22条
  当观光协会及其参与本服务提供的企业，不对以下各项进行任何保证：
  (1) 内容的正确性和商品的完整性
  (2) 内容不侵犯第三方的权利
  (3) 本服务将持续提供
  (4) 保证广告发布企业和商品相关事项的可靠性或效能
  
  （免责）
  第23条
  1. 当观光协会不对注册用户或使用者使用本服务所投稿的信息内容承担任何责任。
  2. 当观光协会不参与注册用户间的通信或活动。如有争议，由相关注册用户间解决，当观光协会不承担该责任。
  3. 当观光协会不对本服务的中断、停止、终止、无法使用或更改，用户投稿的数据的删除或丢失，注册用户的注册取消，由于使用本服务而导致的数据丢失或设备故障或损坏，以及其他与本服务相关的用户损害承担赔偿责任。但是，如果当观光协会存在故意或重大过失，则除外。
  4. 如果根据消费者契约法或其他原因，当观光协会需要承担责任（除故意或重大过失外），其赔偿责任仅限于用户实际且直接遭受的损害，且在轻微过失的情况下，其上限为10,000日元。
  5. 如果由于不可预见的因素导致本网站的浏览出现障碍，当观光协会不承担任何责任。
  6. 当观光协会不保证本服务适合用户的特定目的，具有期望的功能、商品价值、正确性、有用性，用户使用本服务是否符合适用的法律或行业组织的内部规则，以及不会出现故障。
  7. 当观光协会不对广告发布企业和支付代理公司相关事项承担任何责任。
  
  （赔偿额的限制）
  第24条
  1. 当观光协会不对由于使用本服务而产生的任何损害承担赔偿义务。但是，如果当观光协会由于故意或重大过失对注册用户、使用者或其他第三方造成损害，则可能需要赔偿该损害的实际费用。
  2. 当观光协会不对注册用户、使用者或其他第三方因机会损失、业务中断或其他任何损害（包括间接损害和损失利益）承担责任，即使当观光协会事先被通知了这些损害的可能性。
  
  （其他服务的关系）
  第25条
  如果当观光协会提供的本服务或其他服务有与本条款不同的规定，则该规定也适用。如果有与本条款相矛盾的描述，则优先适用该服务的规定。
  
  （通知方法）
  第26条
  1. 如果当观光协会认为需要联系注册用户，将使用已提交给当观光协会的电子邮件地址进行通知。
  2. 如果使用者或注册用户认为需要通知当观光协会，则原则上通过电子邮件进行联系。
  3. 关于广告商品的查询，请直接联系该商品的提供商。
  
  （不可抗力）
  第27条
  对于由于天灾、洪水、海啸、台风、地震、疾病或其他传染病、战争、恐怖袭击、火灾或政府机关的行政指导等不可抗力因素，直接或间接导致的本条款义务的不履行或延迟履行，当观光协会不对注册用户、使用者或其他第三方承担任何责任。
  
  （适用法律）
  第28条
  本条款的适用法律为日本法。
  
  （裁判管辖）
  第29条
  关于本条款，如果当观光协会和注册用户或使用者之间发生争议，则当观光协会的所在地的地方法院为专属的管辖法院。
  
  
  
  一般社团法人热海市观光协会
  地址：〒413-0014 静冈县热海市渚町2018-8
  电话：0557-85-2222
  `,
};
