export default {
  PAGE_TITLE: "Atami Ticket Site",
  SUB_TITLE1: "Enjoy Japan's Earliest-Blooming",
  SUB_TITLE2: " Cherry Blossoms and the Showa-Era",
  SUB_TITLE3: " Retro Townscape",
  SPACIAL_FEATURE_TITLE1: "Excellent access from Tokyo!",
  SPACIAL_FEATURE_TITLE2: " Indulge in the Showa-Era Retro World at the Popular Hot Spring Destination, Atami.",
  SPACIAL_FEATURE_TITLE3: "Let's enjoy Japan's earliest blooming cherry blossoms!",
  SPACIAL_FEATURE_TEXT:
    "Atami, famous as one of Japan's leading hot spring destinations, is also well-known for having the earliest blooming cherry blossoms in winter, along with its Showa-era retro streetscapes. We will host the Atami Sakura Night Festival, touring attractions such as the winter-blooming Atami Sakura, Showa Retro Streetscapes, Kiunkaku, and Atami Geigi Kenban. \n\nEspecially noteworthy are the cherry blossoms along the Itokawa River, which offer a spectacular view. During the event, not only will the cherry blossoms along the river be illuminated, but the installation of lantern monuments will also fantastically project Japan's earliest blooming Atami Sakura into the winter night sky. This is Japan's earliest cherry blossom season, and a unique viewing experience available only in Atami at this time. Please come and enjoy. \nIn line with the light-up, special events will also be held at the famous Kiunkaku, where many literary giants have stayed, and Atami Geigi Kenban, where you can experience traditional Japanese culture.",
  EVENT_LIST: "Event List",
  TITLE_EVENT_LIST1: "Atami Geigi Kenban",
  TITLE_EVENT_LIST2: "Itokawa Promenade",
  TITLE_EVENT_LIST3: "Kiunkaku",
  SUBTITLE_EVENT_LIST1:
    "At Kenban, you can watch the dance performances of geishas from special second-floor box seats. We have also prepared a plan with sushi provided. Please enjoy the best time you can have in Atami city.",
  SUBTITLE_EVENT_LIST2:
    "During the night cherry blossom viewing at Itokawa, you can enjoy not only the usual light-up of cherry blossoms but also the installation of lantern monuments and the illumination of the path leading to Kenban, creating a unique, fantastical landscape exclusive to this event.",
  SUBTITLE_EVENT_LIST3:
    "At Kiunkaku, we offer rentals of 'Yukata' or 'Showa Retro Outfits', which are not usually available. This allows guests to take photos as if they have time-traveled, in an environment where Japanese tradition and modernity blend together.",
  EVENT_ON_CLICK: "SHOW DETAILS",
};
