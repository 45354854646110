const zhCn = {
  // トップ: 開催情報
  LABEL_HOLD_SCHEDULE: "活动日程",
  FORMAT_UPDATED_AT: "YYYY年M月D日更新",
  LABEL_SCHEDULE_START: "开始日期",
  FORMAT_SCHEDULE_START: "YYYY年M月D日 [[]ddd]",
  LABEL_SCHEDULE_END: "结束日期",
  FORMAT_SCHEDULE_END: "YYYY年M月D日 [[]ddd]",
  LABEL_OPENING_HOURS: "活动时间",
  OPENING_HOURS: `糸川灯光照明期间 16:30～23:00\n起云阁：14:00-17:00※预定\n熱海艺妓见番：13:00-17:00※预定`,
  LABEL_LOCATION_NAME: "场地名称",
  LOCATION_NAME: "糸川步行道、起云阁、熱海艺妓见番",
  LOCATION_ADDRESS: `糸川步行道：\n静冈县熱海市银座町・中央町之间
  \n起云阁：\n静冈县熱海市昭和町４−２
  \n熱海艺妓见番：\n静冈县熱海市中央町１７−１３`,
  SHIZUOKA_ATAMI_CITY: "静冈县热海市银座町与中央町交界处",
  ATAMI_TOURISM_ASSOCIATOIN: "热海市旅游协会",
  ATAMI_MAIL_ADDRESS: "info@goatami.jp",
  LABEL_PARKING_AT: "停车场",
  PARKING_AT: "無（周邊有料P利用）",
  LOCATION_ACCESS: `糸川步行道：\n从熱海站步行20分钟
  \n起云阁：\n从熱海站步行30分钟
  \n熱海艺妓见番：\n从熱海站步行25分钟`,

  // トップ: 運営情報
  LABEL_COMPANY: "运营公司",
  LABEL_COMPANY_NAME: "公司名称",

  // トップ: マップ
  MAP: "地图",
  ADDRESS_MAP: `糸川游步道：\n静岡县热海市银座町・中央町的交界
  \n起云阁：\n静岡县熱海市昭和町４−２
  \n熱海艺妓见番：\n静岡县熱海市中央町１７−１`,
  MAP_ACCESS: "交通指南",
  JR_TOKAIDO_SHINKANSEN_HIKARI_KODAMA: "JR东海道新干线“HIKARI”/“KODAMA“",
  TRAVEL_TIME1: "JR东海道新干线“HIKARI”列车35分钟，“KODAMA“列车50分钟",
  TRAVEL_TIME2: "JR东海道新干线“HIKARI”列车2小时，“KODAMA“列车2小时50分钟",
  TRAVEL_TIME3: "JR东海道新干线“HIKARI”列车1小时30分钟，“KODAMA“列车1小时55分钟",
  LABEL_BY_BUS: "巴士",
  POSTAL_ADDRESS_ATAMI_MOMOYAMA: "413-8511",
  ADDRESS_ATAMI_MOMOYAMA: "静冈县热海市桃山町26-2",
  LABEL_MAIN_PHONE_NUMBER: "主要电话号码",
  ACCESS_BY_BUS: `糸川游步道：\n从熱海站搭乘巴士\n●熱海站出發／湯～遊～巴士「大湯間歇泉」，\n●伊豆箱根巴士・伊豆东海巴士「銀座」、「本町商店街」
  \n起云阁：\n从熱海站搭乘巴士\n●汤～游～巴士「起云阁西口」下車 步行約2分鐘\n●伊豆箱根巴士 「起云阁前」下車後即到，伊豆東海巴士 「天神町」步行2分鐘
  \n熱海艺妓见番：\n从熱海站搭乘巴士\n●汤～游～巴士「起云阁西口」下車 步行約5分鐘\n●伊豆箱根巴士・伊豆东海巴士「清水町」下車 步行1分鐘`,
  LABEL_BY_TAXI: "出租车",
  ACCESS_BY_TAXI: `糸川游步道：\n請告訴司機「糸川游步道」作為降車地點
  \n起云阁：\n請告訴司機「起云阁」作為降車地點
  \n熱海艺妓见番：\n請告訴司機「芸妓見番」作為降車地點`,

  ACCESS_BY_TRAIN: "乘火车前来的客人",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_SINOOSAKA: "东海道/山阳新干线儿玉号 开往新大阪",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_TOKYO: "东海道/山阳新干线儿玉号 前往东京",
  POINTER_TOOL_WHEN_TROUBLE: "遇到困难时的“指点工具”",
  RECOMMENDED_SHINKANSEN_TO_ATAMI: "从这里到热海应该乘坐哪条新干线？",
  CURRENT_LOCATION_ON_MAP: "请告诉我我在地图上的位置。",
  MAP_ORIENTATION: "地图应该面向哪个方向？",
  BUS_TO_DESTINATION: "我应该乘坐哪辆巴士到达目的地？",
  DESTINATION_REQUEST: "请带我去上面的目的地。",
  ATAMI_MAP: "热海地图",
  TRAFFIC_TIME1: "约48分钟",
  TRAFFIC_TIME2: "约2小时26分钟",
  TRAFFIC_TIME3: "约1小时59分钟",

  // トップ: 留意事項
  THINGS_TO_KEEP_IN_MIND: "注意事項",
  TRAVEL_METHOD: "运输/交通・移動方式",
  EVENT_POSTPONED: "活動的延期・取消",
  CANCEL_POLICY: "取消政策",

  // トップ: 同じ時期にやっているその他イベント
  OTHER_EVENT: "同一时期举办的其他活动",
};

export default zhCn;
