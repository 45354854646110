export default {
  // 共通
  SITE_NOTE1: "热海市观光协会",
  SITE_NOTE2: "门票销售网站",
  SIGN_UP: "注册",
  SIGN_UP_ACTION: "进行注册",
  SIGN_IN: "登录",
  SIGN_IN_ACTION: "进行登录",
  PASSWORD: "密码",
  NEW_PASSWORD: "新密码",
  NEW_PASSWORD_CONFIRM: "确认新密码",
  SHARE: "分享",
  ADDRESS: "地址",
  PHONE_NUMBER: "电话号码",
  LANGUAGE: "语言",
  JAPANESE: "日本人",
  ENGLISH: "英语",
  TRADITIONAL_CHINESE: "繁体中文",
  SIMPLIFIED_CHINESE: "简体中文",
  YOUR_MAIL_ADDRESS: "您的电子邮件地址",
  MAIL_ADDRESS: "电子邮件地址",
  ACCESS: "交通方式",
  TOKYO: "东京",
  KYOTO: "京都",
  NAGOYA: "名古屋",
  ATAMI: "热海",
  NAME: "名字",
  BACK: "返回",
  NEXT: "下一页",
  DELETE: "删除",
  SEND: "发送",
  BACK_TO_TOP: "返回顶部",
  BACK_TO_SIGN_IN: "返回登录",
  FAX: "传真",
  FAX_NUMBER: "传真号码",
  OPERATION_COMPANY_NAME: "一般社团法人热海市观光协会",
  OPERATION_COMPANY_ADDRESS: "静冈县热海市渚町2018-8 亲水公园彩虹台内（初川侧）",
  PURCHASED_TICKETS: "购票列表",
  RESERVED_TICKETS: "预订列表",
  ADDRESS_CHANGE: "更改电子邮件地址",
  PASSWORD_CHANGE: "更改密码",
  LOGOUT: "登出",
  ERROR_USER_NOT_FOUND: "未找到用户。请从注册处完成新用户注册。",
  CANCEL: "取消",
  FORGOT_PASSWORD: "如果您还没有账户",
  RESET_PASSWORD: "忘记密码了吗？",
  SIGN_IN_PATH: "登录",
  ALREADY_ACCOUNT: "如果您已经有账户",
  CLICK_HERE: "点击这里",
  ERROR_RESISTED_ALREADY: "这个电子邮箱地址已经被注册了。",
  ERROR_INVALID_LINK: "此链接已经被使用过了。",
  ERROR_UNKNOWN: "发生了未知错误。请稍后再试一次。",
  TICKET_COUNT: "张",
  LINK_COPY: "链接已复制",
  SEND_MAIL_ERROR: "发送失败，请稍后重试。",
  PASSWORD_CONFIRM: "密码确认",
  LANGUAGE_IN_USE: "使用的语言",
  RESERVATION_NUMBER: "预约号码",
  NOT_SELECTED: "未选择",
  PLAN: "计划",

  // 404ページ
  DESIGNATION_PAGE_NOT: "指定的页面不存在。",

  // 500ページ
  UNEXPECTED_ERROR: "发生了意外错误。",

  // meta description
  DESCRIPTION:
    "热海市旅游协会的门票网站。正在举办冬季活动。提供云阁、艺妓见番、糸川夜樱的信息。我们介绍热海的旅游信息并销售门票。请务必光临。",
  DETAIL_DESCRIPTION: "我们提供热海旅游信息的介绍和门票销售。请务必光临。",
};
