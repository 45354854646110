import { SVGProps } from "react";

export const SvgIconLangSetting = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="#000"
        d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 15.111c-.643 0-1.457-.976-2.015-2.667h4.03C9.457 14.135 8.643 15.111 8 15.111zm-2.27-3.555a14.685 14.685 0 01-.372-2.667h5.284a14.686 14.686 0 01-.371 2.667H5.729zM.95 8.889h3.518c.042.897.159 1.789.35 2.667H1.851a7.053 7.053 0 01-.9-2.667zm4.602-3.556h4.896c.147.882.22 1.774.219 2.667H5.333c-.002-.893.071-1.785.219-2.667zM8 .89c.75 0 1.732 1.316 2.27 3.555H5.73C6.268 2.205 7.25.89 7.999.89zm3.532 8h3.517a7.052 7.052 0 01-.9 2.667h-2.967c.19-.878.308-1.77.35-2.667zM11.556 8c0-.893-.07-1.785-.207-2.667h3.239c.345.847.523 1.753.523 2.667h-3.556zm2.593-3.556h-2.967a8.841 8.841 0 00-1.365-3.31 7.13 7.13 0 014.332 3.31zm-7.967-3.31a8.843 8.843 0 00-1.364 3.31H1.851a7.13 7.13 0 014.332-3.31zm-4.77 4.2h3.24A17.187 17.187 0 004.443 8H.89c0-.914.178-1.82.523-2.667zm1.046 7.11h2.586a7.824 7.824 0 001.139 2.423 7.12 7.12 0 01-3.725-2.423zm7.36 2.423a7.823 7.823 0 001.138-2.423h2.586a7.118 7.118 0 01-3.725 2.423z"
      ></path>
    </svg>
  );
};
