import { SVGProps } from "react";

export const SVGIconLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="122" height="33" fill="none" viewBox="0 0 122 33" {...props}>
    <g clipPath="url(#clip0_348_1305)">
      <path fill="#DB3737" d="M27 0c-9.168 0-16.6 7.432-16.6 16.6h33.2C43.6 7.432 36.168 0 27 0z"></path>
      <path fill="#fff" d="M121.905 15.492H0v2.032h121.905v-2.032z"></path>
      <path
        fill="#000"
        d="M121.905 16.254H0v.508h121.905v-.508zM42.934 18.794H10.821v.507h32.113v-.507zM42.321 21.333H11.433v.508H42.32v-.508zM41.238 23.873H12.516v.508h28.722v-.508zM39.565 26.413H14.188v.508h25.377v-.508zM37.023 28.952H16.73v.508h20.293v-.508zM32.545 31.492H21.21V32h11.336v-.508z"
      ></path>
      <path
        fill="#fff"
        d="M33.225 10.392a.368.368 0 01-.367-.367 2.895 2.895 0 00-2.892-2.892.368.368 0 01-.366-.366c0-.202.165-.367.366-.367a3.63 3.63 0 013.626 3.625.368.368 0 01-.367.367z"
      ></path>
      <path
        fill="#fff"
        d="M33.221 10.392a.368.368 0 01-.366-.367A3.63 3.63 0 0136.48 6.4a.368.368 0 010 .733 2.895 2.895 0 00-2.892 2.892.368.368 0 01-.367.367zM38.968 13.33a.275.275 0 01-.275-.276c0-.746-.607-1.355-1.355-1.355a.275.275 0 110-.55c1.05 0 1.905.854 1.905 1.905a.275.275 0 01-.275.275z"
      ></path>
      <path
        fill="#fff"
        d="M38.967 13.33a.275.275 0 01-.275-.276c0-1.05.855-1.905 1.905-1.905a.275.275 0 110 .55c-.746 0-1.355.607-1.355 1.355a.275.275 0 01-.275.275z"
      ></path>
    </g>
    <path
      fill="#000"
      d="M66.119 32.282c-2.992 0-3.93-2.04-3.93-4.434s.815-4.434 3.998-4.434c2.774 0 3.862 1.456 3.862 3.06h-1.074c-.068-1.17-.775-2.176-2.788-2.176-2.367 0-2.924 1.578-2.924 3.55 0 1.972.666 3.55 2.924 3.55 2.053 0 2.856-1.252 2.856-3.06H65.86v-.87h4.216V32.2h-.625l-.231-1.768c-.463 1.102-1.333 1.85-3.101 1.85zm9.72 0c-2.638 0-3.318-1.728-3.318-3.564 0-1.822.694-3.563 3.332-3.563 2.639 0 3.332 1.741 3.332 3.563 0 1.85-.694 3.564-3.346 3.564zm-2.284-3.564c0 1.47.448 2.72 2.284 2.72 1.85 0 2.313-1.224 2.313-2.72 0-1.455-.45-2.692-2.299-2.692-1.836 0-2.298 1.224-2.298 2.692zM80.83 32.2l3.577-8.704h1.4l3.51 8.704h-1.102l-.734-1.85h-4.774l-.748 1.85h-1.129zm2.176-2.666h4.175l-2.08-5.236-2.095 5.236zm9.279 2.666v-7.834h-2.72v-.87h6.5v.87h-2.72V32.2h-1.06zm3.949 0l3.577-8.704h1.4l3.509 8.704h-1.101l-.735-1.85h-4.773l-.748 1.85h-1.13zm2.176-2.666h4.175l-2.081-5.236-2.094 5.236zm8.462 2.666v-8.704h1.591l3.4 7.562 3.359-7.562h1.619V32.2h-1.075v-7.398l-3.386 7.398h-1.047l-3.4-7.398V32.2h-1.061zm13.021 0v-8.704h1.061V32.2h-1.061z"
    ></path>
    <defs>
      <clipPath id="clip0_348_1305">
        <path fill="#fff" d="M0 0H121.905V32H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
