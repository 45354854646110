export default {
  PAGE_TITLE: `Go ATAMI Terms of Service`,
  TERMS_OF_SERVICE: `Go ATAMI Terms of Service`,
  TERMS_OF_SERVICE_CONTENT: `The "Go ATAMI" (hereinafter referred to as the "Web Service") establishes the following terms of service (hereinafter referred to as the "Terms") in providing its service (hereinafter referred to as the "Service"). These Terms serve as the rules applicable to users and registered users when using the Service. Please read these Terms carefully to ensure a pleasant use of the Service. By using the Service, you acknowledge that you understand and accept all provisions of these Terms. Please note that the content of these Terms may be updated as necessary, so always check the latest version when using the Service.

  (Purpose)
  Article 1
  The Service is designed for the "search and reservation of events, optional tours, and experience programs in Atami." The Atami City Tourism Association (hereinafter referred to as the "Association") will continuously strive to provide better services.
  
  (Definitions)
  Article 2
  The definitions of terms used in these Terms are as follows:
  
  1. The Service refers to the posting site and related services operated by the Association.
  2. The Site refers to the website where the content of the Service is posted.
  3. Content refers to the information, images, etc., provided by the Association on the Site, including posted information.
  4. User refers to anyone who browses the Site.
  5. Registered User refers to individuals who have completed user registration on the Site.
  6. Posted Information refers to the collective term for text, strings, images, comments, etc., posted by Registered Users.
  7. Registration Information refers to the collective term for information other than Posted Information registered by Registered Users on the Site.
  
  (Scope and Modification of the Terms)
  Article 3
  1. These Terms aim to define the terms of providing the Service and the rights and obligations between the Association and the users concerning the use of the Service, and apply to all relations concerning the use of the Service between the Association and the users.
  2. The Association may modify these Terms without obtaining the consent of the users by notifying the users in a manner deemed appropriate by the Association.
      (1) When modifying these Terms, the Association will post the content of the modified Terms and the effective date of the modification on the Association's website for a reasonable period before the effective date or notify users by email.
      (2) If users use the Service after the effective date of the modified Terms, they are deemed to have agreed to the modifications.
  3. Rules concerning the use of the Service posted on the Service's website, regardless of their name, constitute a part of these Terms.
  4. In the event of any contradiction or conflict between the content of these Terms and the rules or other descriptions outside these Terms concerning the Service, unless expressly provided otherwise, the provisions of these Terms shall prevail.
  
  (Contract Period)
  Article 4
  The Service contract terminates when a Registered User completes the withdrawal procedure. Registered Users can withdraw from the Service at any time by following the prescribed deregistration procedure. However, if a user or Registered User engages in actions that constitute grounds for contract termination, the Association may immediately terminate the contract.
  
  (Use of the Service)
  Article 5
  When users and Registered Users use the Service, they effectively agree to the Terms, and it is acknowledged that a service usage contract, based on these Terms, is established between them and the Association.
  
  (Usage Fees)
  Article 6
  1. Users and Registered Users are not required to pay any fees for using the Service.
  2. The Association may, from time to time, change the fee structure due to economic fluctuations and other reasons.
  
  (Outsourcing)
  Article 7
  The Association may outsource all or part of its operations, such as credit card payment processing, as necessary.
  
  (Registration)
  Article 8
  1. Those who wish to use specific features of the Service as determined by the Association (hereinafter referred to as "Specified Features") (hereinafter referred to as "Applicants for Registration") must agree to comply with these Terms, provide certain information as defined by the Association (hereinafter referred to as "Registration Information") in the manner prescribed by the Association, and apply to the Association for the use of the Specified Features following the procedures set by the Association. The Applicant for Registration must personally complete the registration and provide accurate and truthful information about themselves to the Association, including after registration.
  2. When an application for membership is made, the Association will determine whether to accept the registration based on its criteria. If the Association approves the registration, it will notify the Applicant for Registration, and upon this notification, the registration as a Registered User is considered complete.
  3. Registered Users agree in advance that they may receive SMS or other electronic mails from the Association regarding the Service or other services of the Association for marketing purposes or other related matters to the phone numbers and other contact details they have registered.
  4. The Association may, at its discretion, refuse an application for membership if the Applicant for Registration falls under any of the following:
      (1) If there are inaccuracies (including falsehoods, errors, and omissions) in the application.
      (2) If the Applicant for Registration has previously violated these Terms, breached contracts with the Association, or faced measures such as user registration cancellation or suspension of Registered User privileges.
      (3) If the Association determines that the Applicant for Registration has used fraudulent means for registration.
      (4) If the Applicant for Registration is associated with anti-social forces, such as organized crime groups, or conducts business contrary to public order and morals.
      (5) If the Applicant for Registration is a minor, adult ward, person under curatorship, or person under assistance and has not obtained the necessary consent from their legal representative, guardian, curator, or assistant.
      (6) If the Applicant for Registration is already a Registered User of the Service.
      (7) If the Applicant for Registration intends to use the Service for inappropriate purposes as stipulated in Article 14.
      (8) If the Association otherwise deems the application inappropriate.
  
  (Change of Registration Information)
  Article 9
  1. If there are changes to the Registration Information, the Registered User must promptly report the changes on the Service using the prescribed procedure. The Registered User shall bear all damages arising from not making such changes, and the Association assumes no responsibility.
  2. Notifications from the Association to Registered Users will be considered delivered when an email is sent to the email address registered on the Service at the time of the notification.
  3. Even if a Registered User cannot receive notifications from the Association and suffers damages as a result, the Association assumes no responsibility.
  4. The Association may at any time verify the identity of the Registered User and the truthfulness of the Registration Information. Registered Users shall provide the necessary information in writing or other means to the Association upon request for such verification.
  
  (Withdrawal)
  Article 10
  1. If a Registered User wishes to withdraw, they must follow the procedure specified by the Association.
  2. The Association may forcibly withdraw a Registered User if they fall under any of the following:
      (1) It becomes clear that there were inaccuracies (including falsehoods, errors, and omissions) in the application.
      (2) If they violate these Terms or any other terms of use set by the Association.
      (3) If the Association otherwise deems the behavior inappropriate.
  3. If a Registered User has any liabilities or obligations to the Association at the time of withdrawal, they will continue to bear these obligations even after withdrawal.
  
  (Contract Conclusion and Management through the Service)
  Article 11
  1. Registered Users can apply for the conclusion of sales contracts and other agreements on the Service in accordance with the procedures set by the Association.
  2. When applying as mentioned in the preceding paragraph, Registered Users agree that their name, contact information, and other information related to the Registered User will be sent to the Association's business partners.
  3. Even if a sales contract or other agreement is not finalized due to the Registered User not taking specific actions or other reasons, or if there is a discrepancy with objective facts, the Association will not be held responsible for any damages or issues that arise based on the facts or other circumstances that the Association has confirmed.
  4. Registered Users wish and agree that matters related to the sales contract, handling of complaints, and other related matters will be communicated through electromagnetic methods such as email.
  
  (Compliance Matters)
  Article 12
  1. Registered Users cannot transfer, pledge, or lend their ID to a third party.
  2. Registered Users are entirely responsible for their actions conducted through the Service.
  3. The responsibility for the source and other details of the information posted by Registered Users lies with the Registered Users themselves. The Association is not obligated to store posted information. Registered Users should back up necessary posted information themselves.
  4. Registered Users must not falsify registration information. Unauthorized registration of another person's information without their permission is also prohibited.
  
  (Prohibited Acts)
  Article 13
  1. When using the Service, the Association prohibits Registered Users from engaging in the following acts. If violated, the Association can take necessary measures, including forced withdrawal, suspension of use, and deletion of posted information:
      (1) Becoming insolvent or undergoing bankruptcy or civil rehabilitation proceedings.
      (2) Defaming, unjustly discriminating, or slandering the reputation or credibility of the Association or a third party.
      (3) Infringing on the intellectual property rights of the Association or a third party.
      (4) Violating the privacy rights of the Association or a third party.
      (5) Disclosing the personal information of the Association or a third party without prior consent.
      (6) Damaging or potentially damaging the property of the Association or a third party.
      (7) Causing economic harm to the Association or a third party.
      (8) Threatening the Association or a third party.
      (9) Acquiring an ID on behalf of someone whose ID usage has been suspended.
      (10) Acting in a way that disrupts the operation or system of the Service.
      (11) Posting content deemed obscene, child pornography, or lacking in decency as determined by the Association.
      (12) Posting false information.
      (13) Posting content unrelated to the purpose of the site.
      (14) Making identical or similar posts.
      (15) Posting content that violates laws.
      (16) Posting content that leads to racial or ethnic discrimination.
      (17) Posting content that causes discomfort to users.
      (18) Overloading the network or system of the Service.
      (19) Acting in a way that could disrupt the operation of the Service.
      (20) Unauthorized access or attempted unauthorized access to the network or system of the Association.
      (21) Impersonating someone else.
      (22) Using the login information of another user of the Service.
      (23) Advertising, soliciting, or conducting business on the Service without the Association's prior consent.
      (24) Collecting information on other users of the Service.
      (25) Illegally setting, using, or manipulating features provided by the Service.
      (26) Disclosing or leaking confidential information obtained through employment contracts.
      (27) Acting in a way that harms the Association, other users of the Service, or other third parties.
      (28) Providing benefits to antisocial forces.
      (29) Using the Service to meet strangers of the opposite sex.
      (30) Directly or indirectly causing or facilitating any of the aforementioned acts.
      (31) Any other act deemed inappropriate by the Association.
  2. If the Association determines that a Registered User's actions on the Service violate or are likely to violate any of the items in the preceding paragraph, the Association can take the following measures without prior notice to the Registered User. The Association is not responsible for any damages incurred by the Registered User based on these measures:
      (1) Request the cessation of the violation.
      (2) Delete content related to the violation or stop the violation.
      (3) Suspend the use of the Service.
      (4) Take any other measures deemed necessary by the Association.
      (5) Publicly announce the measures taken based on the aforementioned items in a manner deemed appropriate by the Association.
  
  (Deregistration and Similar Measures)
  Article 14
  1. If a Registered User falls under any of the following reasons, the Association may, without prior notice or demand, delete posted data, temporarily suspend the use of the Service for the said Registered User, or cancel their registration:
      (1) Violation of any provision of these Terms.
      (2) Number of cancellations exceeds the number set by the Association.
      (3) It is found that there are false facts in the registration details.
      (4) Becoming insolvent, undergoing bankruptcy or civil rehabilitation proceedings, or similar procedures.
      (5) Not using the Service for more than 6 months.
      (6) Not responding to inquiries from the Association seeking a reply.
      (7) Falling under any of the items in Article 8, Paragraph 4.
      (8) Other cases where the Association deems it inappropriate to continue using the Service or maintain registration.
  2. If any of the reasons in the preceding paragraph apply, the Registered User must immediately pay all debts owed to the Association.
  3. The Association is not responsible for any damages incurred by the Registered User based on actions taken under this article.
  
  (Preparation of the Usage Environment)
  Article 15
  1. Registered Users shall, at their own expense and responsibility, prepare all equipment necessary for using this platform and Service, including communication equipment, software, and other related equipment. Registered Users shall connect to the internet via telecommunication services or lines at their own expense and responsibility. Users shall maintain security measures, such as preventing computer virus infections, unauthorized access, and information leaks, according to their usage environment.
  2. The Association is not involved in and assumes no responsibility for the user's usage environment.
  
  (Suspension of the Service, etc.)
  Article 16
  1. The Association may, without prior notice to users, suspend or interrupt the provision of all or part of the Service in the following cases:
      (1) When urgent inspection or maintenance work is required for the computer system related to the Service.
      (2) When computers, communication lines, etc., stop due to an accident.
      (3) When the Service cannot be operated due to natural disasters such as earthquakes, lightning strikes, fires, storms, power outages, etc.
      (4) Other cases where the Association deems it necessary to suspend or interrupt the Service.
  2. The Association is not responsible for any damages incurred by users due to actions taken based on the preceding paragraph.
  
  (Intellectual Property Rights)
  Article 17
  1. Rights related to content and individual information included in the Service belong to the Association and partner companies providing content to the Service.
  2. Content provided or displayed in the Service or advertisements is protected by various laws, including copyright law, trademark law, and design law.
  3. Except with prior consent from the Association, partner companies, or advertising companies, Registered Users shall not reproduce, publish, transfer, lend, translate, resell, transmit, license, or reuse the Service, software, or their content. The Association does not guarantee any damages incurred by these actions, and if these actions are taken, the Association may claim damages.
  4. All rights related to the information provided by the Registered User (hereinafter referred to as "Provided Information") are transferred to the Association at the time the user sends the information to the Service. Users also agree in advance not to exercise their moral rights (rights of publication, rights to claim authorship, and rights to maintain the integrity of the work) related to the Provided Information.
  5. Users shall not make any claims or exercise any rights regarding the ownership of the Provided Information against the Association.
  
  (Usage Restrictions)
  Article 18
  1. The Association may restrict the use of the Service if the Registered User or user falls under any of the following:
      (1) When the Association determines that the use of the site by the Registered User or user may cause harm to the Association or a third party, such as the spread of computer viruses or mass emails.
      (2) When the Association cannot contact the Registered User.
      (3) Other cases where the Association deems it necessary to impose usage restrictions.
  2. The Association is not responsible for any damages incurred by the Registered User or user due to usage restrictions.
  
  (Deletion and Modification Authority)
  Article 19
  1. The Association may immediately delete posted information, delete the ID, and prohibit future use without prior notice if the Registered User falls under any of the following:
      (1) When the Registered User violates these Terms or other specified rules.
      (2) When the registered ID is acquired or used by antisocial forces or their members or associates, or when the Association determines there is a risk of such use.
      (3) When it is found that the posted information was not owned by the Registered User.
  2. The Association is not responsible for any damages incurred by the relevant party or third parties due to deletion or usage prohibition.
  3. The Association can change, suspend, or terminate the content of the Service at any time without notifying the Registered User or user.
  4. The Association can change, update, add, modify, or delete the content of this site or these Terms at its discretion without prior notice to the Registered User or user.
  5. The Association is not responsible for any damages incurred by the Registered User or user due to changes in the Service content.
  
  (Communication Privacy)
  Article 20
  1. The Association will protect the secrecy of the user's communication based on the Telecommunications Business Act.
  2. The Association will not be obligated to maintain the confidentiality of communications in the following cases:
      - When there are compulsory measures or court orders based on the Criminal Procedure Code or the Law concerning wiretapping for criminal investigations... within the scope specified by the measure or court order.
      - When administrative measures are taken based on laws... within the scope specified by the measure or order.
      - When the Association determines that the requirements for disclosure requests based on Article 4 of the Law on the Limitation of Liability for Damages of Specified Telecommunications Service Providers and the Disclosure of Sender Information are met... within the scope of the disclosure request.
      - When the Association determines it is necessary to protect the life, body, or property of a third party... within the necessary scope.
  3. Based on Article 4, Paragraph 2 of the Law on the Limitation of Liability for Damages of Specified Telecommunications Service Providers and the Disclosure of Sender Information, the Association will seek the opinion of the sender of the infringing information regarding disclosure. However, this does not apply if it is impossible to contact the sender of the infringing information or if there are other special circumstances.
  
  (Personal Information Management)
  Article 21
  1. The Tourism Association shall appropriately handle personal information registered by the Registered User, personal information provided to the Tourism Association through its services, and all other information provided by the user (hereinafter collectively referred to as "Provided Personal Information") in accordance with the "Privacy Policy" of this Service.
  2. The Tourism Association may provide information, including advertisements, to Registered Users via email or other methods, and Registered Users agree to this. However, if the Registered User does not wish to receive such information, it will not be provided.
  3. The Tourism Association may use information obtained through this Service or information provided or entered by users for analysis, evaluation, and provision to third parties.
  4. For appropriate matching, Registered Users agree that information about their work frequency, hours, tardiness, early departures, and other related information will be sent to the Tourism Association's business partners. However, in principle, information deemed unnecessary for appropriate matching, such as names, will be excluded.
  5. Registered Users agree that the Tourism Association may view messages and other data posted that are not disclosed to other users in cases necessary for preventing and resolving disputes, preventing misuse, ensuring safety, and other operational needs of the Service.
  6. The Tourism Association may use and disclose information, data, and user information provided by users as statistical information in a form that does not identify individuals at its discretion, and users shall not object to this.
  7. If the Tourism Association transfers its service operation business to another company, it may transfer its position under these Terms, rights and obligations based on these Terms, registration information, Provided Personal Information, and other information to the transferee in connection with the business transfer. Users are deemed to have agreed in advance to such a transfer. This includes not only regular business transfers but also company splits and all other cases where the business is transferred.
  8. Apart from the previous three and four clauses, the Tourism Association shall not provide personal information to third parties without obtaining prior consent from the Registered User or user. However, this does not apply in the following cases:
      (1) When required by law.
      (2) When the Tourism Association determines it is necessary to protect the life, body, or property of a third party.
      (3) When it is particularly necessary for improving public health or promoting the sound upbringing of children.
      (4) When it is necessary to cooperate with a public institution or its entrusted entity in performing the duties prescribed by law, and obtaining the person's consent would likely hinder the performance of the duties.
  
  (No Guarantee)
  Article 22
  The Tourism Association and companies involved in providing this Service do not guarantee the following:
      (1) Accuracy of content and completeness of products.
      (2) That the content does not infringe on the rights of third parties.
      (3) That the Service will continue indefinitely.
      (4) The reliability or efficacy of advertised companies and products.
  
  (Disclaimer)
  Article 23
  1. The Tourism Association is not responsible for the content of information posted by Registered Users or users using the Service.
  2. The Tourism Association does not get involved in communications or activities between Registered Users. If a dispute arises, it shall be resolved between the concerned Registered Users, and the Tourism Association is not responsible.
  3. The Tourism Association is not liable for any damages suffered by users related to the interruption, suspension, termination, unavailability, or change of the Service, deletion or loss of posted data, deletion of user registration, loss of registration data due to Service use, equipment failure or damage, or any other damages related to the Service. However, this does not apply in cases of intentional or gross negligence by the Tourism Association.
  4. If, for any reason, including the application of the Consumer Contract Act, the Tourism Association is held liable (excluding cases of intentional or gross negligence), its liability to users shall be limited to actual and direct damages suffered by the user, and in the case of slight negligence, the upper limit shall be 10,000 yen.
  5. The Tourism Association is not responsible for any issues arising from unexpected factors affecting the browsing of this site.
  6. The Tourism Association does not guarantee that the Service meets a user's specific purpose, has the expected functionality, commercial value, accuracy, or usefulness, that the Service's use complies with applicable laws or internal rules of industry groups for the user, or that defects will not occur.
  7. The Tourism Association is not responsible for matters related to advertising companies and payment agencies.
  
  (Limitation of Compensation Amount)
  Article 24
  1. The Tourism Association is not liable for any damages arising from the use of the Service. However, if the Tourism Association causes damage to Registered Users, users, or other third parties due to its intentional or gross negligence, it may compensate for the actual damages suffered by the relevant party.
  2. The Tourism Association is not liable for any opportunity loss, business interruption, or any other damages (including indirect damages and lost profits) incurred by Registered Users, users, or other third parties, even if the Tourism Association had been previously notified of the possibility of such damages.
  
  (Relationship with Other Services)
  Article 25
  If there are provisions other than these Terms for the Service or other services provided by the Tourism Association, such provisions shall also apply. If there are conflicting descriptions, the provisions of the specific service will take precedence.
  
  (Notification Method)
  Article 26
  1. When the Tourism Association deems it necessary to contact a Registered User, it will do so using the email address provided to the Association.
  2. When users or Registered Users deem it necessary to notify the Tourism Association, they shall do so primarily via email.
  3. For inquiries about advertised products, please contact the business provider directly.
  
  (Force Majeure)
  Article 27
  The Tourism Association is not liable to Registered Users, users, or other third parties for non-performance or delay in the performance of obligations under these Terms due to circumstances beyond the reasonable control of the Tourism Association, such as natural disasters, floods, tsunamis, typhoons, earthquakes, epidemics or other contagious diseases, wars, terrorism, fires, or regulatory actions by government agencies.
  
  (Governing Law)
  Article 28
  The governing law of these Terms is Japanese law.
  
  (Jurisdiction)
  Article 29
  In the event of a dispute arising in connection with these Terms between the Tourism Association and a Registered User or user, the court with jurisdiction over the location of the Tourism Association shall be the agreed exclusive court of first instance.
  
  
  
  Atami City Tourism Association (General Incorporated Association)
  Address: 2018-8 Nagisacho, Atami City, Shizuoka Prefecture 413-0014
  Phone: 0557-85-2222
  `,
};
