
import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class SpotApi extends BaseApi {
    async getSpotById(
        parameters: APIPaths["/app/spot/{id}"]["get"]["parameters"]["path"],
    ): Promise<APIPaths["/app/spot/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
        return (await this.get(`/app/spot/${parameters.id}`)).data;
    }
    async getSpotList(
        parameters: APIPaths["/app/spot/list"]["get"]["parameters"]["query"],
    ): Promise<APIPaths["/app/spot/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
        return (
            await this.get("/app/spot/list", {
                params: parameters,
            })
        ).data;
    }
}
