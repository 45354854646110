import { SVGProps } from "react";

export const SVGIconInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
    <rect width="12" height="12" fill="#DB3737" rx="2"></rect>
    <path
      fill="#fff"
      d="M5.321 10.5V3.9H6.69v6.6H5.321zm.684-7.836c-.492 0-.876-.336-.876-.816 0-.48.384-.816.876-.816.504 0 .888.336.888.816 0 .48-.384.816-.888.816z"
    ></path>
  </svg>
);
