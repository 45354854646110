export default {
  // サインアップ
  SIGN_UP_PAGE_TITLE: "Sign Up",
  SIGN_UP_COMPLETE_PAGE_TITLE: "Sign Up Complete",
  SIGN_UP_WITH_PASSWORD_PAGE_TITLE: "Set Password",
  SIGN_UP_TITLE: "Nice to meet you.",
  SIGN_UP_DESCRIPTION: "Please enter your email address and password to complete the sign-up process.",
  SIGN_UP_SUBMIT_ERROR: "An unknown error occurred during the sign-up process. Please try again after some time.",
  SIGN_UP_COMPLETE_TITLE: "Sign Up",
  SIGN_UP_COMPLETE_DESCRIPTION:
    "We have sent a verification email.\nPlease click the verification link in the email to complete the sign-up process.",
  SIGN_UP_TOPPAGE: "Sign Up",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND: "The user was not found. Please try signing up again.",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED: "The expiration date has passed. Please try signing up again.",
  SIGNUP_ERROR_USER_ALREADY_EXIST: "This user is already registered.",
  SIGNUP_WITH_PASSWORD_TITLE: "Setting a login password.",
  SIGNUP_WITH_PASSWORD_DESCRIPTION: "Set the password to be used at login.",
  SIGNUP_WITH_PASSWORD_ACTION: "Set the password.",
  SIGNUP_WITH_PASSWORD_SUBMIT_ERROR:
    "An unknown error occurred while setting the login password. Please try again after some time.",
  SIGNUP_COMPLETE: "Signup has been completed.",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED_TITLE: "The authentication has expired.",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND_TITLE: "The user was not found.",
  SIGNUP_ERROR_DESCRIPTION: "Please double-check the email address you entered and try 'New Registration' again.",
  SIGNUP_FAIL_DESCRIPTION: "Signup failed. Please try again later.",
  SIGNUP_SENT_MAIL_DESCRIPTION: "An authentication email has been sent.",
  SIGNUP_VERIFIED_MAIL_DESCRIPTION:
    "Email address verification is complete. Please proceed to enter your membership information.",
  SIGNUP_NEW_MEMBER_REGISTRATION: "New Member Registration",
  SIGNUP_DISPLAYED_PROMOTE:
    "You can not only purchase tickets but also take advantage of various tourist information and special discounts. \nRegistration is free and can be easily canceled anytime.",

  // ログイン
  SIGN_IN_PAGE_TITLE: "Login",
  SIGN_IN_TITLE: "Welcome back.",
  SIGN_IN_DESCRIPTION: "Please enter your email address and password to complete the login.",
  SIGN_IN_SUBMIT_ERROR: "An unknown error occurred during the login process. Please try again after some time.",
  SIGN_IN_INPUT_ERROR: "The email address and password do not match. Please try again.",
  SIGN_IN_SUBMIT: "You have logged in.",

  // ログアウト
  SIGN_OUT_SUBMIT_ERROR: "An unknown error occurred during the logout process. Please try again after some time.",
  SIGN_OUT_SUBMIT: "You have logged out.",
};
