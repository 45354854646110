export default {
  PAGE_TITLE: `Display of Information Based on the Specified Commercial Transactions Act`,
  MAILORDERLAW: `Display of Information Based on the Specified Commercial Transactions Act`,
  MAILORDERLAW_SALES_COMPANY: `Seller`,
  MAILORDERLAW_COMPANY: `General Incorporated Association Atami City Tourist Association`,
  MAILORDERLAW_SALES_COMPANY_REPRESENTATIVE: `Seller Representative/Sales Manager`,
  MAILORDERLAW_NAME: `Mikio Nakao`,
  MAILORDERLAW_LOCATION: `Location`,
  MAILORDERLAW_LOCATION_NAME: `2018-8 Nagisa-cho, Atami City, Shizuoka, inside the Waterside Park`,
  MAILORDERLAW_CONTACT_INFORMATION: `Contact Information`,
  MAILORDERLAW_INFORMATION: `0557-85-2222`,
  MAILORDERLAW_SELING_PRICE: `Selling Price`,
  MAILORDERLAW_PRICE: `Please refer to the price section on the product page. The selling price varies for each product.`,
  MAILORDERLAW_SHIPING: `Shipping Fees `,
  MAILORDERLAW_SHIPING_NONE: `None.`,
  MAILORDERLAW_SALES_PRICE: `Additional Costs Beyond Selling Price and Shipping`,
  MAILORDERLAW_CONNECTION: `Costs related to internet connectivity and communication lines, etc.`,
  MAILORDERLAW_PAYMENT: `Payment Method and Timing`,
  MAILORDERLAW_PRICE_PAYMENT: `Credit cards only.\nPayment will be processed at the time of purchase. Billing dates may vary by credit card company.`,
  MAILORDERLAW_PRODUCT: `Delivery Timing of Products`,
  MAILORDERLAW_PAYMENT_COMPLETE: `Unless otherwise specified on the purchase page, delivery occurs upon completion of credit card payment.`,
  MAILORDERLAW_SALES_QUANTITIES: `Sales Period and Quantity Limitations`,
  MAILORDERLAW_MAILORDERLAW_SALES_QUANTITIES: `Please refer to the product page for information on sales periods, stock availability, and ticket quantities.`,
  MAILORDERLAW_SALES_REGARDING: `Special Agreement Regarding Returns `,
  MAILORDERLAW_REGARDING: `We adhere to the cancellation policy of each event.\nHowever, in the event of cancellation due to circumstances on the organizer's part or other reasons, a full refund will be issued.`,
};
