export default {
  // お問い合わせ
  PAGE_TITLE: "查詢",
  CONFIRM_PAGE_TITLE: "確認查詢內容",
  COMPLETE_PAGE_TITLE: "感謝您的查詢",
  CONTACT: "查詢",
  THANKS_MESSAGE_FOR_CONTACT:
    "非常感謝您提供的意見或困惑之處。雖然我們無法逐一回覆，但會將您的意見傳達給正在努力改善服務的團隊。",
  YAMADA_TAROU: "山田太郎",
  CONTENT_OF_CONTACT: "查詢內容",
  THIS_IS_CONTENT_OF_CONTACT: "這是諮詢內容。",
  CONTACT_CONFIRM: "確認查詢內容",
  THANKS_FOR_CONTACT: "感謝您的查詢",
  RESPONSE_THREE_BUSINESS_DAYS: "感謝您的查詢。我們會在3個營業日內回覆，請稍候片刻。",
  CONTACT_CANCEL_INFO1: "取消詢問需要申請號碼和預約日期時間，請在",
  CONTACT_CANCEL_INFO2: "中的每張票的詳細頁面確認。",
};
