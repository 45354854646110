export default {
  // 共通
  SITE_NOTE1: "熱海市觀光協會",
  SITE_NOTE2: "門票銷售網站",
  SIGN_UP: "註冊",
  SIGN_UP_ACTION: "進行註冊",
  SIGN_IN: "登入",
  SIGN_IN_ACTION: "進行登入",
  PASSWORD: "密碼",
  NEW_PASSWORD: "新密碼",
  NEW_PASSWORD_CONFIRM: "確認新密碼",
  SHARE: "分享",
  ADDRESS: "地址",
  PHONE_NUMBER: "電話號碼",
  LANGUAGE: "語言",
  JAPANESE: "日本人",
  ENGLISH: "英語",
  TRADITIONAL_CHINESE: "繁體中文",
  SIMPLIFIED_CHINESE: "簡體中文",
  YOUR_MAIL_ADDRESS: "您的電子郵件地址",
  MAIL_ADDRESS: "電子郵件地址",
  ACCESS: "交通方式",
  TOKYO: "東京",
  KYOTO: "京都",
  NAGOYA: "名古屋",
  ATAMI: "熱海",
  NAME: "姓名",
  BACK: "返回",
  NEXT: "下一頁",
  DELETE: "刪除",
  SEND: "發送",
  BACK_TO_TOP: "返回頂部",
  BACK_TO_SIGN_IN: "返回登錄",
  FAX: "傳真",
  FAX_NUMBER: "傳真號碼",
  OPERATION_COMPANY_NAME: "一般社團法人熱海市觀光協會",
  OPERATION_COMPANY_ADDRESS: "靜岡縣熱海市渚町2018-8 水濱公園彩虹天橋內（初川一側）",
  PURCHASED_TICKETS: "購票列表",
  RESERVED_TICKETS: "預訂列表",
  ADDRESS_CHANGE: "更改電子郵件地址",
  PASSWORD_CHANGE: "更改密碼",
  LOGOUT: "登出",
  ERROR_USER_NOT_FOUND: "未找到用戶。請從註冊處完成新用戶註冊。",
  CANCEL: "取消",
  FORGOT_PASSWORD: "如果您還沒有帳號",
  RESET_PASSWORD: "忘記密碼了嗎？",
  SIGN_IN_PATH: "要登入",
  ALREADY_ACCOUNT: "如果您已經有帳號",
  CLICK_HERE: "請點擊這裡",
  ERROR_RESISTED_ALREADY: "這個電子郵件地址已被註冊。",
  ERROR_INVALID_LINK: "此連結已經被使用過了。",
  ERROR_UNKNOWN: "發生了未知錯誤。請稍後再試一次。",
  TICKET_COUNT: "張",
  LINK_COPY: "鏈接已複製",
  SEND_MAIL_ERROR: "傳送失敗，請稍後再試。",
  PASSWORD_CONFIRM: "密碼確認",
  LANGUAGE_IN_USE: "使用的語言",
  RESERVATION_NUMBER: "預約號碼",
  NOT_SELECTED: "未選擇",
  PLAN: "計劃",

  // 404ページ
  DESIGNATION_PAGE_NOT: "指定的頁面不存在。",

  // 500ページ
  UNEXPECTED_ERROR: "發生了意外錯誤。",

  // meta description
  DESCRIPTION:
    "熱海市觀光協會的門票網站。正在舉辦冬季活動。提供雲閣、藝妓見番、糸川夜櫻的資訊。我們介紹熱海的觀光資訊並銷售門票。請務必光臨。",
  DETAIL_DESCRIPTION: "我們提供熱海旅遊資訊的介紹和門票銷售。請務必光臨。",
};
