export default {
  // 共通
  SITE_NOTE1: "熱海市観光協会",
  SITE_NOTE2: "チケット販売サイト",
  SIGN_UP: "サインアップ",
  SIGN_UP_ACTION: "認証メールを送信する",
  SIGN_IN: "ログイン",
  SIGN_IN_ACTION: "ログインする",
  PASSWORD: "パスワード",
  NEW_PASSWORD: "新しいパスワード",
  NEW_PASSWORD_CONFIRM: "新しいパスワードの確認",
  SHARE: "シェア",
  ADDRESS: "住所",
  PHONE_NUMBER: "電話番号",
  LANGUAGE: "言語",
  JAPANESE: "日本語",
  ENGLISH: "英語",
  TRADITIONAL_CHINESE: "中国語(繁体字)",
  SIMPLIFIED_CHINESE: "中国語(簡体字)",
  YOUR_MAIL_ADDRESS: "あなたのメールアドレス",
  MAIL_ADDRESS: "メールアドレス",
  ACCESS: "アクセス",
  TOKYO: "東京",
  KYOTO: "京都",
  NAGOYA: "名古屋",
  ATAMI: "熱海",
  NAME: "お名前",
  BACK: "戻る",
  NEXT: "次へ",
  DELETE: "削除",
  SEND: "送信する",
  BACK_TO_TOP: "トップへ戻る",
  BACK_TO_SIGN_IN: "ログインへ戻る",
  FAX: "FAX",
  FAX_NUMBER: "FAX番号",
  OPERATION_COMPANY_NAME: "一般社団法人熱海市観光協会",
  OPERATION_COMPANY_ADDRESS: "静岡県熱海市渚町2018ｰ8 親水公園レインボーデッキ内（初川寄り）",
  PURCHASED_TICKETS: "購入チケット一覧",
  RESERVED_TICKETS: "予約一覧",
  ADDRESS_CHANGE: "メールアドレス変更",
  PASSWORD_CHANGE: "パスワード変更",
  LOGOUT: "ログアウト",
  ERROR_USER_NOT_FOUND: "ユーザーが見つかりませんでした。サインアップから新規登録を完了させてください。",
  CANCEL: "キャンセル",
  FORGOT_PASSWORD: "アカウントをお持ちでない方は",
  RESET_PASSWORD: "パスワードをお忘れですか？",
  SIGN_IN_PATH: "ログインは",
  ALREADY_ACCOUNT: "既にアカウントをお持ちの方は",
  CLICK_HERE: "こちら",
  ERROR_RESISTED_ALREADY: "既に登録されたメールアドレスです。",
  ERROR_INVALID_LINK: "既に使用済みのリンクです。",
  ERROR_UNKNOWN: "不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。",
  TICKET_COUNT: "枚",
  LINK_COPY: "リンクをコピーしました",
  SEND_MAIL_ERROR: "送信に失敗しました。時間をおいて再度お試しください。",
  PASSWORD_CONFIRM: "パスワードの確認",
  LANGUAGE_IN_USE: "使用言語",
  RESERVATION_NUMBER: "予約番号",
  NOT_SELECTED: "未選択",
  PLAN: "プラン",

  // 404ページ
  DESIGNATION_PAGE_NOT: "指定したページは存在しません",

  // 500ページ
  UNEXPECTED_ERROR: "予期せぬエラーが発生しました。",

  // meta description
  DESCRIPTION:
    "熱海市観光協会のチケットサイト。冬のイベントを開催中。起雲閣、芸妓見番、糸川の夜桜情報を配信しています。熱海の観光情報の紹介とチケットの販売をしています。是非お越し下さい。",
  DETAIL_DESCRIPTION: "熱海の観光情報の紹介とチケットの販売をしています。是非お越し下さい。",
};
