import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class QuestionnaireApi extends BaseApi {
  async getQuestionnaireList(
    parameters: APIPaths["/app/questionnaire/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/questionnaire/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/app/questionnaire/list", {
        params: parameters,
      })
    ).data;
  }

  async putQuestionnaireAnswerById(
    id: string,
    requestBody: APIPaths["/app/questionnaire/{id}/answer"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/questionnaire/{id}/answer"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/questionnaire/${id}/answer`, requestBody)).data;
  }
}
