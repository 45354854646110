export default {
  // サインアップ
  SIGN_UP_PAGE_TITLE: "註冊",
  SIGN_UP_COMPLETE_PAGE_TITLE: "註冊完成",
  SIGN_UP_WITH_PASSWORD_PAGE_TITLE: "設定密碼",
  SIGN_UP_TITLE: "初次見面",
  SIGN_UP_DESCRIPTION: "請輸入電子郵件地址和密碼以完成註冊。",
  SIGN_UP_SUBMIT_ERROR: "在註冊過程中發生了未知錯誤。請稍後再試。",
  SIGN_UP_COMPLETE_TITLE: "註冊",
  SIGN_UP_COMPLETE_DESCRIPTION: "我們已發送驗證郵件。\n請點擊郵件內的驗證連結以完成註冊。",
  SIGN_UP_TOPPAGE: "新用戶註冊",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND: "未找到用戶。請重新從註冊開始嘗試。",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED: "有效期限已過。請重新從註冊開始嘗試。",
  SIGNUP_ERROR_USER_ALREADY_EXIST: "該用戶已被註冊。",
  SIGNUP_WITH_PASSWORD_TITLE: "設定登錄密碼。",
  SIGNUP_WITH_PASSWORD_DESCRIPTION: "設定登錄時使用的密碼。",
  SIGNUP_WITH_PASSWORD_ACTION: "設定密碼。",
  SIGNUP_WITH_PASSWORD_SUBMIT_ERROR: "設定登錄密碼時發生未知錯誤。請稍後再試一次。",
  SIGNUP_COMPLETE: "註冊已完成。",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED_TITLE: "認證的有效期限已過。",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND_TITLE: "未找到用戶。",
  SIGNUP_ERROR_DESCRIPTION: "請確認您輸入的電子郵件地址，然後再次嘗試「新用戶註冊」。",
  SIGNUP_FAIL_DESCRIPTION: "註冊失敗。請稍後再試。",
  SIGNUP_SENT_MAIL_DESCRIPTION: "已發送認證郵件。",
  SIGNUP_VERIFIED_MAIL_DESCRIPTION: "電子郵件地址認證已完成。請輸入會員信息。",
  SIGNUP_NEW_MEMBER_REGISTRATION: "新會員註冊",
  SIGNUP_DISPLAYED_PROMOTE: "不僅可以購買門票，還可以享受各種旅遊信息和特別優惠等福利。\n註冊免費，隨時可以輕鬆取消。",

  // ログイン
  SIGN_IN_PAGE_TITLE: "登錄",
  SIGN_IN_TITLE: "歡迎回來",
  SIGN_IN_DESCRIPTION: "請輸入電子郵件地址和密碼以完成登錄。",
  SIGN_IN_SUBMIT_ERROR: "在登錄過程中發生了未知錯誤。請稍後再試。",
  SIGN_IN_INPUT_ERROR: "電子郵件地址與密碼不匹配。請再試一次。",
  SIGN_IN_SUBMIT: "已登入。",

  // ログアウト
  SIGN_OUT_SUBMIT_ERROR: "登出過程中發生未知錯誤。請稍後再試。",
  SIGN_OUT_SUBMIT: "已登出。",
};
