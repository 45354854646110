import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class QuestionnaireAnswerApi extends BaseApi {
  async getQuestionnaireListAnswer(
    parameters: APIPaths["/app/questionnaire-answer/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/questionnaire-answer/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/questionnaire-answer/list", { params: parameters })).data;
  }
  async getQuestionnaireAnswerById(
    parameters: APIPaths["/app/questionnaire-answer/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/app/questionnaire-answer/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/app/questionnaire-answer/${parameters.id}`, { params: parameters })).data;
  }
}
