export default {
  // メールアドレス変更
  PAGE_TITLE: "更改電子郵件地址",
  COMPLETE_PAGE_TITLE: "更改電子郵件地址完成",
  SENT_MAIL_PAGE_TITLE: "認證郵件發送完成",
  CHANGE_MAIL_ADDRESS_TITLE: "更改電子郵件地址",
  CHANGE_MAIL_ADDRESS_DESCRIPTION: "我們已發送驗證郵件。\n請點擊郵件內的驗證連結以完成電子郵件地址更改。",
  CHANGE_MAIL_ADDRESS_SUBMIT_ERROR: "在更改電子郵件地址過程中發生了未知錯誤。請稍後再試。",
  CHANGE_MAIL_ADDRESS_COMPLETE_TITLE: "電子郵件地址更改完成",
  CHANGE_MAIL_ADDRESS_COMPLETE_DESCRIPTION: "電子郵件地址已成功更改。\n請繼續享受GoATAMI的服務。",
  CHANGE_MAIL_ADDRESS_ERROR_DESCRIPTION: "請確認您輸入的電子郵件地址，然後再次嘗試輸入您的電子郵件地址。",
  CHANGE_MAIL_ADDRESS_ERROR_FIREBASE_USER_NOT_FOUND: "未找到用戶。請再次從更改電子郵件地址處嘗試。",
  CHANGE_MAIL_ADDRESS_ERROR_AUTH_LINK_EXPIRED: "有效期已過，請再次從更改電子郵件地址處嘗試。",
};
