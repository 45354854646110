export default {
  // メールアドレス変更
  PAGE_TITLE: "更改电子邮件地址",
  COMPLETE_PAGE_TITLE: "更改电子邮件地址完成",
  SENT_MAIL_PAGE_TITLE: "认证邮件发送完成",
  CHANGE_MAIL_ADDRESS_TITLE: "更改电子邮件地址",
  CHANGE_MAIL_ADDRESS_DESCRIPTION: "我们已发送认证邮件。\n请点击邮件中的认证链接以完成电子邮件地址更改。",
  CHANGE_MAIL_ADDRESS_SUBMIT_ERROR: "在更改电子邮件地址过程中发生了未知错误。请稍后再试。",
  CHANGE_MAIL_ADDRESS_COMPLETE_TITLE: "电子邮件地址已更改",
  CHANGE_MAIL_ADDRESS_COMPLETE_DESCRIPTION: "电子邮件地址已更改。\n请继续享受GoATAMI。",
  CHANGE_MAIL_ADDRESS_ERROR_DESCRIPTION: "请确认您输入的电子邮件地址，然后再次尝试输入您的电子邮件地址。",
  CHANGE_MAIL_ADDRESS_ERROR_FIREBASE_USER_NOT_FOUND: "未找到用户。请再次从更改电子邮件地址处尝试。",
  CHANGE_MAIL_ADDRESS_ERROR_AUTH_LINK_EXPIRED: "有效期已过，请再次从更改电子邮件地址处尝试。",
};
