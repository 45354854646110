export default {
  DETAIL_PAGE_TITLE: "Input Details",
  PAYMENT_PAGE_TITLE: "Payment Information Input",
  CONFIRMATION_PAGE_TITLE: "Purchase Confirmation",
  COMPLETE_PAGE_TITLE: "Purchase Complete",
  PURCHASE_LIST_PAGE_TITLE: "Purchased Ticket List",
  RESERVED_DETAIL_PAGE_TITLE: "Reservation Details",
  EVENT: "Event",
  PLAN: "Plan",
  PLAN_RESERVE: "Reserve a Plan",
  TICKET_NUMBER: "Number of Tickets",
  PLAN_SELECTON: "Plan Selection",
  RESERVATION_LIST: "Reservation List",
  RESERVATION_DETAILS: "Reservation Details",
  THANK_YOU_RESERVATION: "Thank you for your reservation.",
  PLAN_INCLUDE_COMPLETE: "Include completed plans",
  PLAN_VIEW_MORE: "View more plans",
  BACK_TO_RESERVATION_LIST: "Return to the reservation list",

  // チケット購入
  SOLD_OUT_BUTTON: "Sold Out",
  TO_DETAIL: "View details",
  PURCHASE_TICKET_DETAIL_TITLE: "Input Details",
  PURCHASE_TICKET_QUESTIONNAIRE_TITLE: "Survey",
  PURCHASE_TICKET_QUESTIONNAIRE_DESCRIPTION:
    "This is a survey from the operating company.\nPlease answer and tap Next.",
  PURCHASE_TICKET_PAYMENT_TITLE: "Payment Information Input",
  PURCHASE_TICKET_CONFIRMATION_TITLE: "Confirmation",
  PURCHASE_TICKET_ITEM: "subject",
  PURCHASE_TICKET_PAYMENT_SUBTITLE:
    "Please enter your payment information and tap Next.\nIf the total amount is ¥0, you can skip this step.",
  CHECK_EMAIL_FOR_DETAILS:
    "The details of your ticket have been sent to the email address you registered. Please check it.",
  SURVEY_FROM_OPERATING_COMPANY: "This is a survey from the operating company. Please respond and tap Next.",
  CONFIRM_CONTENT: "Please confirm the details for any errors and complete your purchase.",
  NEW_CONTENT_MESSAGE_1: "By completing your application, you are deemed to have agreed to the 'GO ATAMI ",
  NEW_CONTENT_MESSAGE_2: "' and the 'GO ATAMI ",
  NEW_CONTENT_MESSAGE_3: "'. Please check ",
  NEW_CONTENT_MESSAGE_4: " for details regarding cancellations and refunds.",

  // チケット購入: 支払い
  PURCHASE_TICKET_CARD_NAME: "Cardholder Name",
  PURCHASE_TICKET_CARD_NUMBER: "Card Number",
  PURCHASE_TICKET_EXPIRY_DATE: "Expiration Date",
  PURCHASE_TICKET_CVC: "CVC",
  PLACEHOLDER_NAME: "TARO TANAKA",
  PLACEHOLDER_NUMBER: "4242 4242 4242 4242",
  PLACEHOLDER_DATE: "MM / YY",
  PLACEHOLDER_CVC: "3 or 4 digits",
  PURCHASE_TICKET_TICKETTOTAL: "Total (including tax)",
  PURCHASE_TICKET_INCLUSIVE_TAX: "Including Consumption Tax",
  CREDIT_CARD_SWITCH: "Credit Card Switch",
  CREDIT_CARD_SWITCH_EXECUTE: "Switch Credit Card",
  CREDIT_CARD_SELECT: "Please select a payment method.",
  CREDIT_CARD_REGISTERER: "Register",
  CREDIT_CARD_CHANGE_FAILED: "Could Not Change Credit Card",
  CREDIT_CARD_RECOMMEND_OTHER_PAYMENT_METHOD:
    "If you wish to use another payment method, please register a credit card from above.",
  CREDIT_CARD_CURRENT_PAYMENT_METHOD: "Current Payment Method",
  CREDIT_CARD_DELETE_SUCCESS: "Card information has been removed.",

  // チケット購入: チケット
  PURCHASE_TICKETADDITIONAL: "Other Tickets",

  // チケット購入: チケット選択
  PURCHASE_TICKET_TIME: "Time",
  PURCHASE_TICKET_NOT_SELECTED: "Not Selected",
  PURCHASE_TICKET_FEE: "Fee",
  PURCHASE_TICKET_AVAILABLE_PEOPLE: "Available Number of People",
  PURCHASE_TICKET_AVAILABLE_TIME: "Available Reservation Times",
  PURCHASE_TICKET_STAY_TIME: "Stay Duration",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS: "H [hours]",
  PURCHASE_TICKET_STAY_TIME_FORMAT_HOURS_AND_MINUTS: "H [hours] m [minutes]",
  PURCHASE_TICKET_DETAILS: "Content",
  PURCHASE_TICKET_TAX_INCLUDED: "(Tax Included)",
  PURCHASE_TICKET_TO: "-",
  PURCHASE_TICKET_TO_PEOPLE: "People",
  PURCHASE_SCHEDULE: "Schedule",
  PURCHASE_PAYMENT_INFO: "Payment Information",
  CHOOSE_DATE: "Please select a date",
  SELECT_NUMBER: "Please select the number of tickets",
  SELECT_TIME: "Please select a time",

  // チケット購入: チケット詳細
  PURCHASE_INFO: "Purchase Information",
  PURCHASE_TOTAL_AMOUNT: "Total Purchase Amount",
  PURCHASE_DATE: "Purchase Date and Time",
  PURCHASE_RETURN_TO_TICKETS: "Back to Ticket List",
  CONFIRM_TICKET: "Check the Ticket",
  CANCELED: "Cancelled",
  PURCHASED: "Purchased",
  RESERVED: "Reserved",
  COMPLETED: "Completed",
  TICKET_DATE_FORMAT: "MM/DD/YYYY",
  TICKET_DATETIME_FORMAT: "MM/DD/YYYY hh:mm A",
  TICKET_YEAR_MONTH_FORMAT: "M/YYYY",
  ENTRY_HISTORIES: "Entry History",
  ENTRY_NOT_EXISTS: "There is no entry history yet.",

  // チケット購入: 確認
  PURCHASE_ERROR:
    "I'm sorry, but the purchase process failed. Please check your payment information and network connection and try again.",
  PURCHASE_CARD_REJECTED_ERROR:
    "We couldn't register your credit card. It's possible that the card was declined. Please try another card.",
  PURCHASE_CARD_NETWORK_ERROR:
    "We couldn't register your credit card. There might be a network error. Please check your internet connection and try again.",
  PURCHASE_NO_SELECT_RESERVATIONFRAME_ERROR: "Please select a reservation slot.",
  PURCHASE_SEND_MAIL_ERROR: "Ticket purchase is complete, but email could not be sent.",
  TICKET_CANCEL_INFO1: "For cancellation inquiries, ",
  TICKET_CANCEL_INFO2: "please use the inquiry form.",
  TICKET_CANCEL_INFO3:
    "\nAt that time, the reservation number and reservation date and time will be required, so please check on each ticket's detail page in the purchased ticket list.",

  // チケット購入: アンケート
  QUESTIONNAIRE_REQUEST_TITLE: "Survey Request",
  QUESTIONNAIRE_REQUEST_DESCRIPTION:
    "We have decided to conduct a survey to improve the quality of our services. We would appreciate your cooperation for the improvement of our future services.",
  QUESTIONNAIRE_SUBTITLE1_LIST6: "Others",
  QUESTIONNAIRE_TEXT_AREA_PLACEHOLDER: "Please fill in the details here if you have selected 'Other'.",
  SEND_QUESTIONNAIRE: "Submit the survey",

  // 予約処理中の画面
  RESERVATION_FAIL: "Reservation processing failed.",
  PAYMENT_FAIL: "Payment failed.",
  RESERVATION_COMPLETE: "Reservation complete",
  AUTH_UNKNOWN_ERROR: "An unknown error occurred during authentication. Please try the purchase process again.",
  RETURN_INPUT_DETAIL: "Return to detail input",
  RESERVATION_ALREADY_PAID: "This reservation has already been paid for.",
  RETURN_EVENT_DETAIL: "Return to event details",
  RESERVATION_PROCESSING: "Reservation is being processed",
};
