const ja = {
  // トップ: 開催情報
  LABEL_HOLD_SCHEDULE: "開催予定",
  FORMAT_UPDATED_AT: "YYYY年M月D日更新",
  LABEL_SCHEDULE_START: "開始日",
  FORMAT_SCHEDULE_START: "YYYY年M月D日 [[]ddd]",
  LABEL_SCHEDULE_END: "終了日",
  FORMAT_SCHEDULE_END: "YYYY年M月D日 [[]ddd]",
  LABEL_OPENING_HOURS: "開催時間",
  OPENING_HOURS: `糸川ライトアップ期間中 16:30～23:00\n起雲閣：14:00-17:00※予定\n熱海芸妓見番：13:00-17:00※予定`,
  LABEL_LOCATION_NAME: "会場名",
  LOCATION_NAME: "糸川遊歩道、起雲閣、熱海芸妓見番",
  LOCATION_ADDRESS: `糸川遊歩道：\n静岡県熱海市銀座町・中央町の境
\n起雲閣：\n静岡県熱海市昭和町４−２
\n熱海芸妓見番：\n静岡県熱海市中央町１７−１３`,
  SHIZUOKA_ATAMI_CITY: "静岡県熱海市銀座町・中央町の境",
  ATAMI_TOURISM_ASSOCIATOIN: "熱海市観光協会",
  ATAMI_MAIL_ADDRESS: "info@goatami.jp",
  LABEL_PARKING_AT: "駐車場",
  PARKING_AT: "なし（周辺有料P利用）",
  LOCATION_ACCESS: `糸川遊歩道：\n熱海駅から徒歩約20分
\n起雲閣：\n熱海駅から徒歩約30分
\n熱海芸妓見番：\n熱海駅から徒歩約25分`,

  // トップ: 運営情報
  LABEL_COMPANY: "運営会社",
  LABEL_COMPANY_NAME: "会社名",

  // トップ: マップ
  MAP: "マップ",
  ADDRESS_MAP: `糸川遊歩道：\n静岡県熱海市銀座町・中央町の境
\n起雲閣：\n静岡県熱海市昭和町４−２
\n熱海芸妓見番：\n静岡県熱海市中央町１７−１３`,
  MAP_ACCESS: "アクセス",
  JR_TOKAIDO_SHINKANSEN_HIKARI_KODAMA: "JR東海道新幹線ひかり/こだま",
  TRAVEL_TIME1: "JR東海道新幹線ひかり35分、こだま50分",
  TRAVEL_TIME2: "JR東海道新幹線ひかり2時間、こだま2時間50分",
  TRAVEL_TIME3: "JR東海道新幹線ひかり1時間30分、こだま1時間55分",
  LABEL_BY_BUS: "バスでお越しの場合",
  LABEL_MAIN_PHONE_NUMBER: "代表電話",
  ACCESS_BY_BUS: `糸川遊歩道：\nバス【熱海駅から】\n●「お宮の松」経由のバスにて約10分「銀座」下車\n●「咲見町」経由のバスにて約10分「本町商店街」下車\n●湯～遊～バスにて約40分「大湯間歇泉」下車
\n起雲閣：\nバス【熱海駅から】\n●「相の原団地」「西山」方面行きバスにて約10分「起雲閣」下車\n●「紅葉ガ丘」方面行きバスにて約15分「天神町」下車→徒歩約2分\n●湯～遊～バスにて約35分「起雲閣西口」下車→徒歩約2分
\n熱海芸妓見番：\nバス【熱海駅から】\n●「紅葉ガ丘」方面行きバスにて約10分「清水町」下車\n●湯～遊～バスにて約35分「起雲閣西口」下車→徒歩約5分`,
  LABEL_BY_TAXI: "タクシーでお越しの場合",
  ACCESS_BY_TAXI: `糸川遊歩道：\n熱海駅より約5分
\n起雲閣：\n熱海駅より約10分
\n熱海芸妓見番：\n熱海駅より約5分`,
  POSTAL_ADDRESS_ATAMI_MOMOYAMA: "〒413-8511",
  ADDRESS_ATAMI_MOMOYAMA: "静岡県熱海市桃山町26-2",

  ACCESS_BY_TRAIN: "電車でお越しの方",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_SINOOSAKA: "東海道・山陽新幹線こだま新大阪行",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_TOKYO: "東海道・山陽新幹線こだま東京行",
  ATAMI_MAP: "熱海のマップ",
  TRAFFIC_TIME1: "約48分",
  TRAFFIC_TIME2: "約2時間26分",
  TRAFFIC_TIME3: "約1時間59分",

  // トップ: 留意事項
  THINGS_TO_KEEP_IN_MIND: "留意事項",
  TRAVEL_METHOD: "交通・移動手段",
  EVENT_POSTPONED: "イベントの延期・中止",
  CANCEL_POLICY: "キャンセルポリシー",

  // トップ: 同じ時期にやっているその他イベント
  OTHER_EVENT: "同じ時期にやっているその他のイベント",
};

export default ja;
