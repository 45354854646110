export default {
  PAGE_TITLE: `Privacy Policy`,
  PRIVACY_POLICY: `Privacy Policy`,
  PRIVACY_POLICY_CONTENT: `The Atami City Tourism Association (hereinafter referred to as "the Association") recognizes the importance of proper handling of personal information under the principle that it should provide guidelines for the appropriate treatment of such information. We have established this privacy policy and our officers and employees are committed to adhering to it, ensuring the utmost protection of personal information and the rights and interests of individuals.

  1. When acquiring personal information, we will specify its intended use as much as possible and obtain the information only to the extent necessary to achieve that purpose.
  2. When obtaining personal information directly from the individual in writing, we will inform them of the Association's name, the name of the personal information protection manager, contact details, and the purpose of use, and then obtain the personal information within the necessary scope.
  3. The use of personal information will be within the scope of the purpose for which the individual has given consent. We will also establish and implement procedures to take necessary measures to prevent use beyond that purpose.
  4. We will manage the personal information we hold in an appropriate manner and will not disclose or provide it to third parties without the individual's consent.
  5. We will manage the personal information we hold in an accurate and up-to-date manner, taking reasonable security measures against risks such as leaks, loss, or damage, and strive for prevention and correction.
  6. When outsourcing the processing of personal information, we will contractually obligate the contractor not to leak or provide the information to third parties and will ensure appropriate management of the outsourced data.
  7. For complaints or consultations regarding the personal information we hold, please contact the Association's inquiry desk, and we will address them.
  8. We will comply with laws, guidelines set by the government, and other norms regarding the handling of personal information.
  9. We have established a personal information protection management system, which we will review periodically and continuously improve.
  
  Date of enactment: October 23, 2023
  Atami City Tourism Association
  Representative Director: Mikio Nakajima
  
  For inquiries about the Privacy Policy:
  
  Atami City Tourism Association
  Address: 2018-8 Nagisacho, Atami City, Shizuoka Prefecture 413-0014
  Phone: 0557-85-2222
  `,
  POLICY_FOR_HANDLING: `Policy on Handling Personal Information`,
  POLICY_FOR_HANDLING_CONTENT: `The Atami City Tourism Association (hereinafter referred to as "the Association") sets forth the following privacy policy (hereinafter referred to as "this Policy") regarding the handling of users' personal information in the services provided on this website (hereinafter referred to as "the Service").

  Article 1 (Personal Information)
  "Personal Information" refers to "personal information" as defined in the Personal Information Protection Law. It pertains to information about a living individual, which includes name, date of birth, address, phone number, and other descriptions that can identify a specific individual. This also includes data related to appearance, fingerprints, voice prints, and information such as health insurance card numbers that can identify a specific individual from that information alone (personal identification information).
  
  Article 2 (Method of Collecting Personal Information)
  The Association may ask users for personal information such as name, date of birth, address, phone number, email address, and credit card number when they register for the Service. Additionally, the Association may collect transaction records containing users' personal information and payment-related information from transactions between users and our partners, including our affiliates (including information providers, advertisers, and ad delivery destinations, hereinafter referred to as "partners").
  
  Article 3 (Purpose of Collecting and Using Personal Information)
  The purposes for which the Association collects and uses personal information are as follows:
  
      - To provide and operate the Association's services.
      - To respond to inquiries from users (including verifying identity).
      - To send emails about new features, updates, campaigns, etc., of the service users are using, and other services provided by the Association.
      - To contact users as necessary for maintenance or important notifications.
      - To identify users who violate the terms of use or attempt to use the service for fraudulent or improper purposes and to refuse their use.
      - To allow users to view, modify, or delete their registration information and view their usage status.
      - To bill users for paid services.
      - For purposes related to the above usage purposes.
  
  Article 4 (Change of Purpose of Use)
  1. The Association may change the purpose of using personal information only when it is reasonably deemed that the new purpose is related to the original purpose.
  2. If the purpose of use is changed, the Association will notify users of the new purpose by a method specified by the Association or announce it on this website.
  
  Article 5 (Provision of Personal Information to Third Parties)
  1. Except in the following cases, the Association will not provide personal information to third parties without obtaining the user's prior consent. However, this does not apply when permitted by the Personal Information Protection Law or other laws.
      - When necessary to protect the life, body, or property of a person, and it is difficult to obtain the person's consent.
      - When it is particularly necessary for improving public health or promoting the sound growth of children, and it is difficult to obtain the person's consent.
      - When there is a need to cooperate with a national agency, local government, or an individual entrusted by them in executing the affairs prescribed by laws and regulations, and obtaining the person's consent is likely to hinder the execution of the affairs.
      - When the following matters have been announced or made public in advance, and the Association has notified the Personal Information Protection Commission:
      - Including the provision to third parties in the purpose of use.
      - Items of data provided to third parties.
      - Means or method of provision to third parties.
      - Stopping the provision of personal information to third parties upon request.
      - How to accept the request.
  2. Notwithstanding the provisions of the preceding paragraph, the following cases shall not be considered as third parties:
      - When the Association entrusts all or part of the handling of personal information to the extent necessary to achieve the purpose of use.
      - When personal information is provided due to the succession of business due to mergers or other reasons.
      - When personal information is used jointly between specific parties, and this fact, the items of personal information used jointly, the range of joint users, the purpose of use by the user, and the name or name of the person responsible for the management of the personal information are notified to the person in advance or placed in a state where the person can easily know.
  
  Article 6 (Disclosure of Personal Information)
  1. When the Association is requested by a person to disclose their personal information, it shall disclose it to the person without delay. However, if any of the following applies due to the disclosure, all or part of the information may not be disclosed, and if a decision not to disclose is made, the person will be notified without delay. A fee of 1,000 yen per case will be charged for the disclosure of personal information.
      - When there is a risk of harming the life, body, property, or other rights and interests of the person or a third party.
      - When there is a risk of significantly hindering the proper implementation of the Association's business.
      - When it violates other laws and regulations.
  2. Notwithstanding the provisions of the preceding paragraph, in principle, information other than personal information, such as history information and characteristic information, will not be disclosed.
  
  Article 7 (Correction and Deletion of Personal Information)
  1. If a user believes that their personal information held by the Association is incorrect, they may request the Association to correct, add, or delete the personal information (hereinafter referred to as "correction, etc.") according to the procedures specified by the Association.
  2. If the Association determines that it is necessary to respond to the request from the user in the preceding paragraph, it shall make the correction, etc., of the personal information without delay.
  3. If the Association has made the correction, etc., based on the provisions of the preceding paragraph, or has decided not to make the correction, etc., it shall notify the user without delay.
  
  Article 8 (Suspension of Use of Personal Information, etc.)
  1. If the Association is requested by a person to suspend or delete their personal information on the grounds that it is being handled beyond the scope of the purpose of use or that it was obtained by wrongful means, the Association shall conduct the necessary investigation without delay.
  2. Based on the results of the investigation in the preceding paragraph, if the Association determines that it is necessary to respond to the request, it shall suspend the use of the personal information without delay.
  3. If the Association has suspended the use of personal information based on the provisions of the preceding paragraph or has decided not to suspend its use, it shall notify the user without delay.
  4. Notwithstanding the provisions of the preceding two paragraphs, if the suspension of use, etc., requires a large amount of expenses or if it is difficult to suspend the use, etc., and if it is possible to take alternative measures necessary to protect the rights and interests of the user, the Association shall take such alternative measures.
  
  Article 9 (Changes to the Privacy Policy)
  1. The contents of this policy can be changed without notifying the user, unless otherwise provided for in laws or other provisions of this policy.
  2. Unless otherwise specified by the Association, the changed privacy policy shall take effect from the time it is posted on this website.
  
  Article 10 (Inquiry Desk)
  For inquiries regarding this policy, please contact the following:
  
  Atami City Tourism Association
  Address: 2018-8 Nagisacho, Atami City, Shizuoka Prefecture 413-0014
  Phone: 0557-85-2222
  `,
};
