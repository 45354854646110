import { SVGProps } from "react";

export const SVGIconCloseButton = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <rect
        x="0.34375"
        y="10.2441"
        width="14"
        height="2"
        rx="1"
        transform="rotate(-45 0.34375 10.2441)"
        fill="#222222"
      />
      <rect
        x="1.75781"
        y="0.34375"
        width="14"
        height="2"
        rx="1"
        transform="rotate(45 1.75781 0.34375)"
        fill="#222222"
      />
    </svg>
  );
};
