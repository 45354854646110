export default {
  PAGE_TITLE: `隱私政策`,
  PRIVACY_POLICY: `個人資料保護方針`,
  PRIVACY_POLICY_CONTENT: `一般社團法人熱海市觀光協會（以下「本觀光協會」）基於認識到應當明確個人資料適當處理的原則，確認個人資料的適切處理的重要性，並制定個人資料保護方針，由役員及員工共同遵守，致力於全面保護個人資料及個人的權益。

  1. 在取得個人資料時，我們將最大程度地明確使用目的，並僅在達到該目的所需的範疇內收集資料。
  2. 直接從本人以書面形式取得個人資料時，會先通知本觀光協會名稱、個人資料保護管理員姓名及聯絡方式、使用目的等，然後在必要的範疇內收集資料。
  3. 使用個人資料只限於獲得本人同意的使用目的範疇。並確立必要手續以確保不會超出此範疇進行使用，且將進行此類手續。
  4. 我們將適當地管理所持有的個人資料，不會在未經本人同意的情況下向第三方披露或提供。
  5. 我們將依據使用目的，在必要的範疇內，管理所持有的個人資料，確保其準確且為最新狀態，並對可能出現的資料洩露、遺失或毀損等情況採取合理的安全措施，致力於預防及糾正。
  6. 若將個人資料處理外包給外部，我們將透過契約義務化不洩露、不向第三方提供等事項，並對委託方進行適當的管理。
  7. 關於所持有的個人資料的投訴或諮詢，可通過聯絡本觀光協會的詢問窗口，我們將給予回應。
  8. 我們將遵守與個人資料處理相關的法律、國家規定的指導方針和其他規範。
  9. 我們已制定個人資料保護管理系統，並將定期進行審查，持續進行改善。
  
  制定日：2023年10月23日
  一般社團法人熱海市觀光協會
  代表理事 中島 幹雄
  
  <關於個人資料保護方針的詢問處>
  
  一般社團法人熱海市觀光協會  
  〒413-0014 靜岡縣熱海市渚町2018-8  
  電話：0557-85-2222
  `,
  POLICY_FOR_HANDLING: `個人資訊的取扱關於的方針`,
  POLICY_FOR_HANDLING_CONTENT: `一般社團法人熱海市觀光協會（以下「當觀光協會」）是、本網站上面提供的服務（以下，「本服務」這樣說。）於其中，用戶的個人資訊的取扱關於，以下的如此隱私政策（以下，「本政策」這樣說。）規定。

  第1條（個人資訊）
  「個人資訊」是，個人資訊保護法所說的「個人資訊」指的是那個，關於還活著的個人的資訊，當該資訊包含的姓氏、生日、地址、電話號碼、聯絡方式和其他的描述等可以識別特定的個人的資訊及外貌、指紋、聲紋的資料，和健康保險的保險者號碼等的當該資訊單獨從中可以識別特定的個人的資訊（個人識別資訊）指的是。
  
  第2條（個人資訊的收集方法）
  當觀光協會是，用戶註冊使用時姓氏、生日、地址、電話號碼、電子郵件地址、信用卡號碼等的個人資訊問你。還有，用戶和合作夥伴等之間做了包含用戶的個人資訊的交易紀錄和付款的資訊，當觀光協會的合作夥伴（包括資訊提供源、廣告主、廣告發布處等。以下，「合作夥伴」這樣說。）從中收集。
  
  第3條（收集及使用個人資訊的目的）
  當觀光協會收集及使用個人資訊的目的是，以下的如此：
  
      - 為了提供和經營當觀光協會的服務
      - 為了回答從用戶的問題（包括驗證身分）
      - 為了寄送用戶使用中的服務的新功能、更新消息、宣傳活動等和當觀光協會提供的其他服務的資訊
      - 依照需要聯絡維護或重要的消息
      - 為了識別違反使用條款的用戶或試圖以不正當的目的使用服務的用戶，拒絕他們使用
      - 為了讓用戶查看或更改自己的註冊資訊和使用狀態
      - 為了在付費服務中向用戶收取使用費用
      - 關於上述使用目的的其他目的
  
  第4條（使用目的的變更）
  1. 當觀光協會，只有在使用目的與之前相關和可以合理地被接受的情況下，才會更改個人資訊的使用目的。
  2. 如果更改了使用目的，將通過當觀光協會指定的方法通知用戶，或在本網站上公開。
  
  第5條（個人資訊的第三方提供）
  1. 當觀光協會除了下列情況外，不會未經用戶的事先同意，向第三方提供個人資訊。但是，除非個人資訊保護法或其他法令允許的情況。
      - 當需要保護人的生命、身體或財產，且難以獲得本人的同意時。
      - 為了提高公眾衛生或促進兒童健全成長，特別需要且難以獲得本人的同意時。
      - 國家機關或地方公共團體或其委託人執行法令所規定的事務，需要合作且因獲得本人同意可能妨礙事務的進行時。
      - 提前通知或公告以下事項，且當觀光協會已向個人資訊保護委員會提交：
      - 利用目的包括向第三方提供
      - 提供給第三方的資料項目
      - 向第三方提供的方法或方式
      - 應本人要求停止向第三方提供個人資訊
      - 接受本人請求的方法
  2. 儘管有前項的規定，以下情況不視為提供給第三方：
      - 當觀光協會為達到利用目的，在必要範圍內將個人資訊的處理全部或部分委託時。
      - 由於合併或其他原因導致業務接續時提供個人資訊。
      - 與特定人共同使用個人資訊，且已事先通知或使本人容易得知資訊共用、共用的個人資訊項目、共用者的範圍、使用者的使用目的，以及負責該個人資訊管理的人的姓名或名稱。
  
  第6條（個人資訊的公開）
  1. 當觀光協會收到本人要求公開其個人資訊時，應立即予以公開。但如果公開可能導致以下情況，可以不全部或部分公開，並應立即通知該決定。此外，每次公開個人資訊將收取1,000円的手續費。
      - 可能損害本人或第三者的生命、身體、財產或其他權益。
      - 可能嚴重妨礙當觀光協會業務的正常執行。
      - 違反其他法律的情況。
  2. 儘管有前項規定，原則上不公開個人資訊以外的履歷資料和特性資料等資訊。
  
  第7條（個人資訊的修正及刪除）
  1. 如果用戶認為當觀光協會持有的其個人資訊是錯誤的，可以按照當觀光協會的規定程序要求修正、增加或刪除（以下稱為“修正等”）。
  2. 當觀光協會接受用戶的前項請求並認為需要回應該請求時，應立即進行相應的修正等。
  3. 根據前項規定，當觀光協會進行了修正等或決定不進行修正等時，應立即通知用戶。
  
  第8條（個人資訊的利用停止等）
  1. 如果本人認為其個人資訊超出利用目的的範圍或因不正手段獲得，並要求停止使用或刪除（以下稱為“利用停止等”），當觀光協會應立即進行必要調查。
  2. 基於前項的調查結果，如果認為需要回應該請求，則應立即進行相應的利用停止等。
  3. 根據前項規定，當觀光協會進行了利用停止等或決定不進行利用停止等時，應立即通知用戶。
  4. 儘管有前兩項的規定，如果利用停止等需要大量費用或其他原因導致難以執行，且可以採取其他替代措施保護用戶的權益，則應採取該替代措施。
  
  第9條（隱私政策的變更）
  1. 除非法律或本政策有其他規定，否則可以在不通知用戶的情況下更改本政策的內容。
  2. 除非當觀光協會有其他規定，否則更改後的隱私政策從本政策公告的日期生效。
  
  第10條（聯繫方式）
  有關本政策的任何問題或意見，請通過以下方式聯繫我們：
  
  一般社團法人熱海市觀光協會
  〒413-0014 靜岡縣熱海市渚町2018ｰ8
  電話：0557-85-2222
  `,
};
