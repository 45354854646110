export default {
  // サインアップ
  SIGN_UP_PAGE_TITLE: "注册",
  SIGN_UP_COMPLETE_PAGE_TITLE: "注册完成",
  SIGN_UP_WITH_PASSWORD_PAGE_TITLE: "设置密码",
  SIGN_UP_TITLE: "初次见面",
  SIGN_UP_DESCRIPTION: "请填写电子邮件地址和密码以完成注册。",
  SIGN_UP_SUBMIT_ERROR: "在注册过程中发生了未知错误。请稍后再试。",
  SIGN_UP_COMPLETE_TITLE: "注册",
  SIGN_UP_COMPLETE_DESCRIPTION: "我们已发送认证邮件。\n请点击邮件中的认证链接以完成注册。",
  SIGN_UP_TOPPAGE: "新用户注册",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND: "未找到用户。请重新从注册开始尝试。",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED: "有效期限已过。请重新从注册开始尝试。",
  SIGNUP_ERROR_USER_ALREADY_EXIST: "该用户已被注册。",
  SIGNUP_WITH_PASSWORD_TITLE: "设置登录密码。",
  SIGNUP_WITH_PASSWORD_DESCRIPTION: "设置登录时使用的密码。",
  SIGNUP_WITH_PASSWORD_ACTION: "设置密码。",
  SIGNUP_WITH_PASSWORD_SUBMIT_ERROR: "设置登录密码时发生未知错误。请稍后再试一次。",
  SIGNUP_COMPLETE: "注册已完成。",
  SIGNUP_ERROR_AUTH_LINK_EXPIRED_TITLE: "认证的有效期限已过。",
  SIGNUP_ERROR_FIREBASE_USER_NOT_FOUND_TITLE: "未找到用户。",
  SIGNUP_ERROR_DESCRIPTION: "请确认您输入的电子邮件地址，然后再次尝试「新用户注册」。",
  SIGNUP_FAIL_DESCRIPTION: "注册失败。请稍后再试。",
  SIGNUP_SENT_MAIL_DESCRIPTION: "已发送认证邮件。",
  SIGNUP_VERIFIED_MAIL_DESCRIPTION: "电子邮件地址验证已完成。请输入会员信息。",
  SIGNUP_NEW_MEMBER_REGISTRATION: "新会员注册",
  SIGNUP_DISPLAYED_PROMOTE: "不仅可以购买门票，还可以享受各种旅游信息和特别折扣等优惠。\n注册免费，随时可以轻松取消。",

  // ログイン
  SIGN_IN_PAGE_TITLE: "登录",
  SIGN_IN_TITLE: "欢迎回来",
  SIGN_IN_DESCRIPTION: "请填写电子邮件地址和密码以完成登录。",
  SIGN_IN_SUBMIT_ERROR: "在登录过程中发生了未知错误。请稍后再试。",
  SIGN_IN_INPUT_ERROR: "电子邮件地址和密码不匹配，请再试一次。",
  SIGN_IN_SUBMIT: "已登录。",

  // ログアウト
  SIGN_OUT_SUBMIT_ERROR: "在注销过程中发生未知错误，请稍后再试。",
  SIGN_OUT_SUBMIT: "已注销。",
};
