import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class UserApi extends BaseApi {
  async getUserMe(): Promise<APIPaths["/app/user/me"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/user/me")).data;
  }
  async patchUserMe(
    requestBody: APIPaths["/app/user/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/app/user/me`, requestBody)).data;
  }
  async putUserMePassword(
    requestBody: APIPaths["/app/user/me/password"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me/password"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/user/me/put`, requestBody)).data;
  }
  async postUserMeCreditCard(
    requestBody: APIPaths["/app/user/me/credit-card/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/user/me/credit-card/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/app/user/me/credit-card/new", requestBody)).data;
  }
  async deleteUserMeCreditCardById(
    parameters: APIPaths["/app/user/me/credit-card/{id}"]["delete"]["parameters"]["path"],
  ): Promise<APIPaths["/app/user/me/credit-card/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/app/user/me/credit-card/${parameters.id}`)).data;
  }
  async putUserMeCreditCardById(
    parameters: APIPaths["/app/user/me/credit-card/{id}/default"]["parameters"]["path"]
  ): Promise<APIPaths["/app/user/me/credit-card/{id}/default"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/app/user/me/credit-card/${parameters.id}/default`)).data;
  }

}
