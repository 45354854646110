export default {
  // 購入チケット一覧
  TICKET_MONTH: "MMMM",
  TICKET_DAY: "D",
  TICKET_DATE_TIME_START: "dddd HH:mm",
  TICKET_DATE_TIME_END: "HH:mm",
  TICKET_TOTAL: "總計 : ",
  NO_TICKET_TITLE: "沒有已購買的票。",
  TICKET_CANCEL_POLICY: "提前5天以上取消，收取50%費用；4天以內取消，收取100%費用",
  TICKET_NUMBER: "車票號碼",
  TICKET_CANCEL_HISTORY: "取消紀錄",
  TICKET_REFUND_AMOUNT: "退款金額",
  TICKET_CANCEL_COMPLETE: "完成",
  TICKET_CANCEL_COMPLETED_NOTIFICATION: "已完成取消",
  TICKET_CANCEL_CONFIRM: "確認",
  TICKET_CANCEL_OK1: "可以",
  TICKET_CANCEL_OK2: "取消",
  TICKET_CANCEL_OK3: "這張車票嗎？",
  TICKET_CANCEL_Fee50: "目前取消費用為50%。",
  TICKET_CANCEL_Fee100: "目前取消費用為100%",
  TICKET_CANCEL_COMPLETE_CLOSE: "關閉",
  TICKET: "車票",
  TICKET_CANCEL_WARNING: "請注意，此操作無法取消。",
  TICKET_CANCEL_EXECUTE: "執行取消",
  TICKET_CANCEL_FAILED: "取消失敗。",
};
