const en = {
  // トップ: 開催情報
  LABEL_HOLD_SCHEDULE: "Event Schedule",
  FORMAT_UPDATED_AT: "[Updated on] MMM D, YYYY",
  LABEL_SCHEDULE_START: "Start Date",
  FORMAT_SCHEDULE_START: "MMMM D, YYYY [[]ddd]",
  LABEL_SCHEDULE_END: "End Date",
  FORMAT_SCHEDULE_END: "MMMM D, YYYY [[]ddd]",
  LABEL_OPENING_HOURS: "Event Time",
  OPENING_HOURS: `Itokawa Light-Up: 16:3023:00 during event period\nKiunkaku: 14:00-17:00※Planned\nAtami Geisha Kenban: 13:00-17:00※Planned`,
  LABEL_LOCATION_NAME: "Venue Name",
  LOCATION_NAME: `Itokawa Promenade, Kiunkaku, Atami Geisha Kenban`,
  LOCATION_ADDRESS: `Itokawa Promenade:\nBoundary of Ginza-cho & Chuo-cho, Atami City, Shizuoka Prefecture
  \nKiunkaku:\n4-2 Showa-cho, Atami City, Shizuoka Prefecture
  \nAtami Geisha Kenban:\n17-13 Chuo-cho, Atami City, Shizuoka Prefecture`,
  SHIZUOKA_ATAMI_CITY: "Border between Ginza-cho and Chuo-cho, Atami City, Shizuoka Prefecture",
  ATAMI_TOURISM_ASSOCIATOIN: "Atami City Tourism Association",
  ATAMI_MAIL_ADDRESS: "info@goatami.jp",
  LABEL_PARKING_AT: "Parking",
  PARKING_AT: "None (Paid parking nearby)",
  LOCATION_ACCESS: `Itokawa Promenade:\n20 minutes walk from Atami Station
  \nKiunkaku:\n30 minutes walk from Atami Station
  \nAtami Geisha Kenban:\n25 minutes walk from Atami Station`,

  // トップ: 運営情報
  LABEL_COMPANY: "Operating Company",
  LABEL_COMPANY_NAME: "Company Name",

  // トップ: マップ
  MAP: "Map",
  ADDRESS_MAP: `Itokawa Promenade:\nBoundary of Ginza-cho & Chuo-cho, Atami City, Shizuoka Prefecture  
  \nKiunkaku:\n4-2 Showa-cho, Atami City, Shizuoka Prefecture
  \nAtami Geisha Kenban:\n17-13 Chuo-cho, Atami City, Shizuoka Prefecture`,
  MAP_ACCESS: "Access:",
  JR_TOKAIDO_SHINKANSEN_HIKARI_KODAMA: "JR Tokaido Shinkansen Hikari / Kodama",
  TRAVEL_TIME1: "JR Tokaido Shinkansen Hikari 35 minutes, Kodama 50 minutes",
  TRAVEL_TIME2: "JR Tokaido Shinkansen Hikari 2 hours, Kodama 2 hours 50 minutes",
  TRAVEL_TIME3: "JR Tokaido Shinkansen Hikari 1 hour 30 minutes, Kodama 1 hour 55 minutes",
  LABEL_BY_BUS: "By Bus",
  LABEL_MAIN_PHONE_NUMBER: "Main phone number",
  ACCESS_BY_BUS: `Itokawa Promenade:\nBus [From Atami Station]\n●Yuu-Yuu-Bus ‘Oyu Intermittent Spring’,\n●Izu Hakone Bus/Izu Tokai Bus ‘Ginza’, ‘Hon-cho Shopping Street’
\nKiunkaku:\nBus [From Atami Station]\n●Yuu-Yuu-Bus ‘Kiunkaku West Exit’ - about 2 minutes walk\n●Izu Hakone Bus ‘Kiunkaku Front’ - immediate, Izu Tokai Bus ‘Tenjin-cho’ - 2 minutes walk
\nAtami Geisha Kenban:\nBus [From Atami Station]\n●Yuu-Yuu-Bus ‘Kiunkaku West Exit’ - about 5 minutes walk\n●Izu Hakone Bus/Izu Tokai Bus ‘Shimizu-cho’ - 1 minute walk`,
  LABEL_BY_TAXI: "By Taxi",
  ACCESS_BY_TAXI: `Itokawa Promenade:\nPlease tell the driver ‘Itokawa Promenade’ as the drop-off location
  \nKiunkaku:\nPlease tell the driver ‘Kiunkaku’ as the drop-off location
  \nAtami Geisha Kenban:\nPlease tell the driver ‘Geisha Kenban’ as the drop-off location`,

  ACCESS_BY_TRAIN: "For Those Coming by Train",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_SINOOSAKA: "Tokaido/Sanyo Shinkansen Kodama bound for Shin-Osaka",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_TOKYO: "Tokaido/Sanyo Shinkansen Kodama bound for Tokyo",
  POINTER_TOOL_WHEN_TROUBLE: "“Pointing tool” when you are in trouble",
  RECOMMENDED_SHINKANSEN_TO_ATAMI: "Which Shinkansen should I take to go from here to Atami?",
  CURRENT_LOCATION_ON_MAP: "Please show me where I am on the map.",
  MAP_ORIENTATION: "Which direction should the map be facing?",
  BUS_TO_DESTINATION: "Which bus should I take to get to my destination?",
  DESTINATION_REQUEST: "Please take me to the destination above.",
  ATAMI_MAP: "Atami Map",
  TRAFFIC_TIME1: "Approximately 48 minutes",
  TRAFFIC_TIME2: "Approximately 2 hours 26 minutes",
  TRAFFIC_TIME3: "Approximately 1 hour 59 minutes",

  // トップ: 留意事項
  THINGS_TO_KEEP_IN_MIND: "Points to Note",
  TRAVEL_METHOD: "Transportation and Movement",
  EVENT_POSTPONED: "Event Postponement/Cancellation",
  CANCEL_POLICY: "Cancellation Policy",

  // トップ: 同じ時期にやっているその他イベント
  OTHER_EVENT: "Other Events Around the Same Time",
};

export default en;
