export default {
  // 購入チケット一覧
  TICKET_MONTH: "MMMM",
  TICKET_DAY: "D",
  TICKET_DATE_TIME_START: "dddd HH:mm",
  TICKET_DATE_TIME_END: "HH:mm",
  TICKET_TOTAL: "合計 : ",
  NO_TICKET_TITLE: "購入済みのチケットはありません。",
  TICKET_CANCEL_POLICY: "5日前までのキャンセルは50%、4日前以降は100%",
  TICKET_NUMBER: "チケット番号",
  TICKET_CANCEL_HISTORY: "キャンセル履歴",
  TICKET_REFUND_AMOUNT: "返金金額",
  TICKET_CANCEL_COMPLETE: "完了",
  TICKET_CANCEL_COMPLETED_NOTIFICATION: "キャンセルが完了しました",
  TICKET_CANCEL_CONFIRM: "確認",
  TICKET_CANCEL_OK1: "こちらのチケットを",
  TICKET_CANCEL_OK2: "キャンセル",
  TICKET_CANCEL_OK3: "してもよろしいですか？",
  TICKET_CANCEL_Fee50: "現在キャンセル料は 50% です。",
  TICKET_CANCEL_Fee100: "現在キャンセル料は 100% です。",
  TICKET_CANCEL_COMPLETE_CLOSE: "閉じる",
  TICKET: "チケット",
  TICKET_CANCEL_WARNING: "こちらの操作は取り消すことができませんので、ご注意ください。",
  TICKET_CANCEL_EXECUTE: "キャンセルを実行する",
  TICKET_CANCEL_FAILED: "キャンセルに失敗しました。",
};
