export default {
  PAGE_TITLE: "热海门票网站",
  SUB_TITLE1: "欣赏日本最早盛开的樱花",
  SUB_TITLE2: "与昭和复古街景",
  SUB_TITLE3: undefined,
  SPACIAL_FEATURE_TITLE1: "从东京出发交通便利！",
  SPACIAL_FEATURE_TITLE2: "在受欢迎的温泉地热海尽享昭和复古世界。",
  SPACIAL_FEATURE_TITLE3: "让我们一起欣赏日本最早盛开的樱花吧！",
  SPACIAL_FEATURE_TEXT:
    "作为日本数一数二的温泉地，著名的热海不仅以昭和复古的街景闻名，还因为是日本最早在冬季盛开樱花的地方而闻名。我们将举办‘热海樱花夜会’活动，游览冬季盛开的‘热海樱花’、‘昭和复古街景’、‘起云阁’以及‘热海艺妓见番’等旅游景点。\n\n特别是糸川沿岸排列的樱花景致非常壮观。在活动期间，不仅会照亮糸川沿岸的樱花，还会透过提灯装置在冬季夜空中幻想般地呈现‘日本最早盛开的热海樱花’。请务必来访热海，欣赏‘只有在热海冬季才能看到的樱花景色’和‘林立的摊贩’，体验日本最早的赏花时节。\n配合灯光秀，著名的文豪曾逗留的‘起云阁’和可以体验古老日本文化的‘热海艺妓见番’也将举办特别活动！",
  EVENT_LIST: "活动一览",
  TITLE_EVENT_LIST1: "热海艺妓见番",
  TITLE_EVENT_LIST2: "糸川游步道",
  TITLE_EVENT_LIST3: "起云阁",
  SUBTITLE_EVENT_LIST1:
    "在见番，您可以从特别的二楼桟敷席欣赏艺妓的表演。我们还准备了由工匠制作的寿司供应计划。请务必享受在热海市内度过的最佳时光。",
  SUBTITLE_EVENT_LIST2:
    "在糸川的夜樱观赏中，不仅有普通樱花的照明，还有提灯装置的设置、以及从见番一直延伸的路灯，您可以享受这个活动特有的幻想般的风景。",
  SUBTITLE_EVENT_LIST3:
    "在起云阁，我们提供平时不提供的‘浴衣’或‘昭和复古服装’的租赁服务，在融合了和风与现代风格的环境中，您可以拍摄仿佛时光倒流的照片。",
  EVENT_ON_CLICK: "活动详情请点击这里",
};
