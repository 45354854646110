import { SVGProps } from "react";

export const SVGIconLogoColor = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="122" height="33" viewBox="0 0 122 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3290_24201)">
        <path
          d="M26.9984 0C17.8305 0 10.3984 7.43207 10.3984 16.6H43.5984C43.5984 7.43207 36.1664 0 26.9984 0Z"
          fill="black"
        />
        <path d="M121.905 15.4922H0V17.5239H121.905V15.4922Z" fill="white" />
        <path d="M121.905 16.2539H0V16.7618H121.905V16.2539Z" fill="black" />
        <path d="M42.9331 18.793H10.8203V19.3009H42.9331V18.793Z" fill="black" />
        <path d="M42.3222 21.334H11.4336V21.8419H42.3222V21.334Z" fill="black" />
        <path d="M41.2384 23.873H12.5156V24.381H41.2384V23.873Z" fill="black" />
        <path d="M39.565 26.4121H14.1875V26.92H39.565V26.4121Z" fill="black" />
        <path d="M37.0235 28.9531H16.7305V29.4611H37.0235V28.9531Z" fill="black" />
        <path d="M32.5471 31.4922H21.2109V32.0001H32.5471V31.4922Z" fill="black" />
        <path
          d="M33.2267 10.3922C33.025 10.3922 32.86 10.2272 32.86 10.0255C32.86 8.43024 31.5617 7.13385 29.9683 7.13385C29.7666 7.13385 29.6016 6.96882 29.6016 6.76712C29.6016 6.56542 29.7666 6.40039 29.9683 6.40039C31.967 6.40039 33.5934 8.02683 33.5934 10.0255C33.5934 10.2272 33.4284 10.3922 33.2267 10.3922Z"
          fill="white"
        />
        <path
          d="M33.2222 10.3922C33.0205 10.3922 32.8555 10.2272 32.8555 10.0255C32.8555 8.02683 34.4819 6.40039 36.4806 6.40039C36.6823 6.40039 36.8473 6.56542 36.8473 6.76712C36.8473 6.96882 36.6823 7.13385 36.4806 7.13385C34.8853 7.13385 33.5889 8.43207 33.5889 10.0255C33.5889 10.2272 33.4239 10.3922 33.2222 10.3922Z"
          fill="white"
        />
        <path
          d="M38.9677 13.3286C38.8155 13.3286 38.6926 13.2058 38.6926 13.0536C38.6926 12.3073 38.0857 11.6985 37.3375 11.6985C37.1854 11.6985 37.0625 11.5757 37.0625 11.4235C37.0625 11.2713 37.1854 11.1484 37.3375 11.1484C38.3882 11.1484 39.2427 12.0029 39.2427 13.0536C39.2427 13.2058 39.1198 13.3286 38.9677 13.3286Z"
          fill="white"
        />
        <path
          d="M38.9704 13.3286C38.8182 13.3286 38.6953 13.2058 38.6953 13.0536C38.6953 12.0029 39.5498 11.1484 40.6005 11.1484C40.7527 11.1484 40.8755 11.2713 40.8755 11.4235C40.8755 11.5757 40.7527 11.6985 40.6005 11.6985C39.8542 11.6985 39.2454 12.3055 39.2454 13.0536C39.2454 13.2058 39.1226 13.3286 38.9704 13.3286Z"
          fill="white"
        />
      </g>
      <path
        d="M66.1197 32.2808C63.1277 32.2808 62.1893 30.2408 62.1893 27.8472C62.1893 25.4536 63.0053 23.4136 66.1877 23.4136C68.9621 23.4136 70.0501 24.8688 70.0501 26.4736H68.9757C68.9077 25.304 68.2005 24.2976 66.1877 24.2976C63.8213 24.2976 63.2637 25.8752 63.2637 27.8472C63.2637 29.8192 63.9301 31.3968 66.1877 31.3968C68.2413 31.3968 69.0437 30.1456 69.0437 28.3368H65.8613V27.4664H70.0773V32.1992H69.4517L69.2205 30.4312C68.7581 31.5328 67.8877 32.2808 66.1197 32.2808ZM75.8404 32.2808C73.202 32.2808 72.522 30.5536 72.522 28.7176C72.522 26.8952 73.2156 25.1544 75.854 25.1544C78.4924 25.1544 79.186 26.8952 79.186 28.7176C79.186 30.5672 78.4924 32.2808 75.8404 32.2808ZM73.5556 28.7176C73.5556 30.1864 74.0044 31.4376 75.8404 31.4376C77.69 31.4376 78.1524 30.2136 78.1524 28.7176C78.1524 27.2624 77.7036 26.0248 75.854 26.0248C74.018 26.0248 73.5556 27.2488 73.5556 28.7176ZM80.8322 32.1992L84.409 23.4952H85.8098L89.3186 32.1992H88.217L87.4826 30.3496H82.709L81.961 32.1992H80.8322ZM83.0082 29.5336H87.1834L85.1026 24.2976L83.0082 29.5336ZM92.2868 32.1992V24.3656H89.5668V23.4952H96.0676V24.3656H93.3476V32.1992H92.2868ZM96.2358 32.1992L99.8126 23.4952H101.213L104.722 32.1992H103.621L102.886 30.3496H98.1126L97.3646 32.1992H96.2358ZM98.4118 29.5336H102.587L100.506 24.2976L98.4118 29.5336ZM106.874 32.1992V23.4952H108.465L111.865 31.0568L115.224 23.4952H116.843V32.1992H115.768V24.8008L112.382 32.1992H111.335L107.935 24.8008V32.1992H106.874ZM119.895 32.1992V23.4952H120.956V32.1992H119.895Z"
        fill="black"
      />
      <defs>
        <clipPath id="clip0_3290_24201">
          <rect width="121.905" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
