const zhTw = {
  // トップ: 開催情報
  LABEL_HOLD_SCHEDULE: "举行日期",
  FORMAT_UPDATED_AT: "YYYY年M月D日更新",
  LABEL_SCHEDULE_START: "開始日期",
  FORMAT_SCHEDULE_START: "YYYY年M月D日 [[]ddd]",
  LABEL_SCHEDULE_END: "結束日期",
  FORMAT_SCHEDULE_END: "YYYY年M月D日 [[]ddd]",
  LABEL_OPENING_HOURS: "舉辦時間",
  OPENING_HOURS: `糸川燈光照明期間 16:30～23:00\n起雲閣：14:00-17:00※預定\n熱海藝妓見番：13:00-17:00※預定`,
  LABEL_LOCATION_NAME: "場地名稱",
  LOCATION_NAME: "糸川遊步道、起雲閣、熱海藝妓見番",
  LOCATION_ADDRESS: `糸川遊步道：\n靜岡縣熱海市銀座町・中央町的交界
  \n起雲閣：\n靜岡縣熱海市昭和町４−２
  \n熱海藝妓見番：\n靜岡縣熱海市中央町１７−１３`,
  SHIZUOKA_ATAMI_CITY: "靜岡縣熱海市銀座町與中央町交界處",
  ATAMI_TOURISM_ASSOCIATOIN: "熱海市觀光協會",
  ATAMI_MAIL_ADDRESS: "info@goatami.jp",
  LABEL_PARKING_AT: "停車場",
  PARKING_AT: "無（周邊有料P利用）",
  LOCATION_ACCESS: `糸川遊歩道：\n從熱海站步行20分鐘
  \n起雲閣：\n從熱海站步行30分鐘
  \n熱海藝妓見番：\n從熱海站步行25分鐘`,

  // トップ: 運営情報
  LABEL_COMPANY: "營運公司",
  LABEL_COMPANY_NAME: "公司名稱",

  // トップ: マップ
  MAP: "地圖",
  ADDRESS_MAP: `糸川遊步道：\n靜岡縣熱海市銀座町・中央町的交界
  \n起雲閣：\n靜岡縣熱海市昭和町４−２
  \n熱海藝妓見番：\n靜岡縣熱海市中央町１７−１３`,
  MAP_ACCESS: "交通指南",
  JR_TOKAIDO_SHINKANSEN_HIKARI_KODAMA: "JR东海道新干线“HIKARI”/“KODAMA“",
  TRAVEL_TIME1: "JR东海道新干线“HIKARI”列车35分钟，“KODAMA“列车50分钟",
  TRAVEL_TIME2: "JR東海道新幹線“HIKARI”列車2小時，“KODAMA“列車2小時50分鍾",
  TRAVEL_TIME3: "JR東海道新幹線“HIKARI”列車1小時30分鍾，“KODAMA“列車1小時55分鍾",
  LABEL_BY_BUS: "巴士",
  POSTAL_ADDRESS_ATAMI_MOMOYAMA: "413-8511",
  ADDRESS_ATAMI_MOMOYAMA: "静冈县热海市桃山町26-2",
  LABEL_MAIN_PHONE_NUMBER: "主要电话号码",
  ACCESS_BY_BUS: `糸川遊步道：\n從熱海站搭乘巴士\n●熱海站出發／湯～遊～巴士「大湯間歇泉」，\n●伊豆箱根巴士・伊豆東海巴士「銀座」、「本町商店街」
  \n起雲閣：\n從熱海站搭乘巴士\n●湯〜遊〜巴士「起雲閣西口」下車 步行約2分鐘\n●伊豆箱根巴士 「起雲閣前」下車後即到，伊豆東海巴士 「天神町」步行2分鐘
  \n熱海藝妓見番：\n從熱海站搭乘巴士\n●湯〜遊〜巴士「起雲閣西口」下車 步行約5分鐘\n●伊豆箱根巴士・伊豆東海巴士「清水町」下車 步行1分鐘`,
  LABEL_BY_TAXI: "出租車",
  ACCESS_BY_TAXI: `糸川遊步道：\n請告訴司機「糸川遊步道」作為降車地點
  \n起雲閣：\n請告訴司機「起雲閣」作為降車地點
  \n熱海藝妓見番：\n請告訴司機「芸妓見番」作為降車地點`,

  ACCESS_BY_TRAIN: "乘火車前來的客人",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_SINOOSAKA: "東海道/山陽新幹線儿玉號 開往新大阪",
  TOKAIDO_SANYO_SHINKANSEN_BOUND_FOR_TOKYO: "東海道/山陽新幹線儿玉號 前往東京",
  POINTER_TOOL_WHEN_TROUBLE: "遇到困難時的“指點工具",
  RECOMMENDED_SHINKANSEN_TO_ATAMI: "從這裡到熱海應該乘坐哪條新幹線？",
  CURRENT_LOCATION_ON_MAP: "請告訴我我在地圖上的位置。",
  MAP_ORIENTATION: "地圖應該面向哪個方向？",
  BUS_TO_DESTINATION: "我應該乘坐哪輛巴士到達目的地？",
  DESTINATION_REQUEST: "請帶我去上面的目的地。",
  ATAMI_MAP: "熱海地圖",
  TRAFFIC_TIME1: "約48分鐘",
  TRAFFIC_TIME2: "約2小時26分鐘",
  TRAFFIC_TIME3: "約1小時59分鐘",

  // トップ: 留意事項
  THINGS_TO_KEEP_IN_MIND: "注意事項",
  TRAVEL_METHOD: "运输/交通・移動方式",
  EVENT_POSTPONED: "活動的延期・取消",
  CANCEL_POLICY: "取消政策",

  // トップ: 同じ時期にやっているその他イベント
  OTHER_EVENT: "同一時期舉辦的其他活動",
};

export default zhTw;
