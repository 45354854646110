import { EmotionCache, Global } from "@emotion/react";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { ReactNode, useEffect, useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { _appStyled } from "@/styles/_app";
import { kintoSans } from "@/styles/app-typography";
import { muiTheme } from "@/styles/mui-theme";

import SnackbarCloseButton from "@/components/common/layout/SnackBarCloseButton";
import AuthProvider from "@/components/provider/AuthProvider";
import { Clarity } from "@/components/utils/Clarity";
import { GoogleAnalytics } from "@/components/utils/GoogleAnalytics";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Provider as JotaiProvider } from "jotai";
import { SnackbarProvider } from "notistack";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      keepPreviousData: true,
    },
  },
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps } = props;
  const { locale } = useLocale();
  const [devToolsNode, setDevToolsNode] = useState<ReactNode>(null);

  useEffect(() => {
    if (process.env.APP_ENV !== "local") {
      return;
    }
    setTimeout(async () => {
      const { ReactQueryDevtools } = await import("@tanstack/react-query-devtools");
      const node = <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />;
      setDevToolsNode(node);
    }, 1000);
  }, []);

  // TODO lang setting
  return (
    <QueryClientProvider client={queryClient}>
      {devToolsNode}
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <Clarity />
          <CssBaseline />
          <Global styles={_appStyled} />
          <JotaiProvider>
            <AuthProvider>
              <SnackbarProvider
                autoHideDuration={5000}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                maxSnack={3}
                preventDuplicate
                action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
              >
                <main className={kintoSans.className}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </main>
              </SnackbarProvider>
            </AuthProvider>
          </JotaiProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

// export default appWithTranslation(MyApp);
