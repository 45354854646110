import { css } from "@emotion/react";

export const _appStyled = css`
  html {
    font-family: "kinto-sans", "Avenir", "Helvetica Neue", "Helvetica", "Arial", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro", "メイリオ", sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    font-style: normal;
    font-weight: normal;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /*スクロールバー非表示（Chrome・Safari）*/
  body::-webkit-scrollbar {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  *:focus {
    outline: none;
  }
`;
