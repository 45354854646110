import { SVGProps } from "react";

export const SVGIconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_348_2124)">
      <path
        fill="#222"
        d="M7.993 21.208a1.6 1.6 0 01-1.132-.469L.46 14.336a1.6 1.6 0 012.263-2.263l5.21 5.21L21.21 2.53a1.6 1.6 0 012.379 2.141L9.183 20.678a1.605 1.605 0 01-1.148.53h-.042z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_348_2124">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
