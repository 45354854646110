export default {
  // パスワードリセット: 認証メール送信
  SEND_VERIFICATION_MAIL_PAGE_TITLE: "重置密码",
  SENT_VERIFICATION_MAIL_PAGE_TITLE: "发送认证邮件完成",
  RESET_PASSWORD_TITLE: "重置密码",
  RESET_PASSWORD_DESCRIPTION: "请填写电子邮件地址以完成邮件验证。",
  SEND_VERIFICATION_MAIL: "发送认证邮件",
  RESET_PASSWORD_SUBMIT_ERROR: "在重置密码过程中发生了未知错误。请稍后再试。",
  RESET_PASSWORD_SENT_MAIL_DESCRIPTION: "我们已发送认证邮件。\n请点击邮件中的认证链接以完成密码重置。",

  // パスワードリセット: 新規パスワード入力
  EDIT_PASSWORD_PAGE_TITLE: "设置密码",
  COMPLETE_RESET_PASSWORD_PAGE_TITLE: "重置密码完成",
  NEW_PASSWORD_DESCRIPTION: "输入新密码以完成密码重置。",
  RESET_PASSWORD_ACTION: "重置密码",
  COMPLETE_RESET_PASSWORD_TITLE: "密码重置已完成",
  COMPLETE_RESET_PASSWORD_DESCRIPTION: "密码已重置。\n请重新登录。",
  RESET_PASSWORD_ERROR_FIREBASE_USER_NOT_FOUND: "未找到用户。请重新从输入您的电子邮件地址开始尝试。",
  RESET_PASSWORD_ERROR_DESCRIPTION: "请确认您输入的电子邮件地址，然后再次尝试输入您的电子邮件地址。",
  RESET_PASSWORD_ERROR_AUTH_LINK_EXPIRED: "有效期限已过期。请重新从输入您的电子邮件地址开始尝试。",
};
